const _DEV_HOST = "http://localhost:8000";
const _SIT_HOST = "http://nicheapi.gzit.com.my";
const _PROD_HOST = "https://api.niche123.net";

const _DEV_NECS_HOST = "http://localhost:8002";
const _SIT_NECS_HOST = "http://necs.gzit.com.my";
const _PROD_NECS_HOST = "http://192.168.0.105:8002";

/// <summary>
/// Author : Robin
/// Host for different Environment
/// </summary>
export class HostKey {
  static getApiHost = () => {
    switch (process.env.REACT_APP_ENV) {
      case "dev":
        return _DEV_HOST;

      case "sit":
        return _SIT_HOST;

      case "prod":
        return _PROD_HOST;

      default:
        return !process.env.NODE_ENV || process.env.NODE_ENV === "development"
          ? _DEV_HOST
          : _SIT_HOST;
    }
  };

  static getNecsHost = () => {
    switch (process.env.REACT_APP_ENV) {
      case "dev":
        return _DEV_NECS_HOST;

      case "sit":
        return _SIT_NECS_HOST;

      case "prod":
        return _PROD_NECS_HOST;

      default:
        return !process.env.NODE_ENV || process.env.NODE_ENV === "development"
          ? _DEV_NECS_HOST
          : _SIT_NECS_HOST;
    }
  };
}
export const SuperAdminRole = "super admin";
export const _IMAGE_URL = `${HostKey.getApiHost()}/storage`;

/// <summary>
/// Author : -
/// API related key or field when making ajax or api call
/// </summary>
export class ApiKey {
  static _API_SUCCESS_KEY = "success";
  static _API_MESSAGE_KEY = "message";
  static _API_DATA_KEY = "data";
  static _API_TOTAL_KEY = "totalCount";
  static _API_CONTENT_TYPE = "Content-Type";
  static _API_ACCEPT = "Accept";
  static _API_APPLICATION_JSON = "application/json";
  static _API_XML_HTTP_REQUEST_KEY = "XMLHttpRequest";
  static _API_FORM_URLENCODED = "application/x-www-form-urlencoded";
  static _API_FORM_DATA = "multipart/form-data";
  static _API_POST = "POST";
  static _API_GET = "GET";
  static _API_DELETE = "DELETE";
}

/// <summary>
/// Author : -
/// Url to communicate to controller
/// </summary>
export class ApiUrl {
  static _API_LOGIN = "/auth/login";
  static _API_CHECK_USERNAME = "/auth/checkUsername";
  static _API_VALIDATE_LOGIN = "/auth/user";
  static _API_LOGOUT = "/auth/logout";
  static _API_GET_ROLE = "/policy/role/listfull";

  static _API_GET_DEPARTMENT = "/department/list";
  static _API_GET_DEPARTMENT_JSON = "/department/listJson";
  static _API_GET_DEPARTMENT_JSON_AGENT = "/department/listJsonAgent";
  static _API_DEPARTMENT_CREATE = "/department/post";
  static _API_DEPARTMENT_CREATE_JSON = "/department/postJson";
  static _API_DEPARTMENT_CREATE_JSON_AGENT = "/department/postJsonAgent";
  static _API_DEPARTMENT_UPDATE = "/department/update";
  static _API_DEPARTMENT_DELETE = "/department/delete";

  static _API_POSITION_CREATE = "/position/post";
  static _API_POSITION_CREATE_JSON = "/position/postJson";
  static _API_POSITION_CREATE_JSON_AGENT = "/position/postAgentJson";
  static _API_POSITION_UPDATE = "/position/update";
  static _API_POSITION_DELETE = "/position/delete";
  static _API_GET_POSITION = "/position/list";
  static _API_GET_POSITION_JSON = "/position/listJson";
  static _API_GET_POSITION_JSON_AGENT = "/position/listAgentJson";

  static _API_GET_WAREHOUSE = "/warehouse/list";
  static _API_GET_WAREHOUSE_JSON = "/warehouse/listJson";
  static _API_GET_WAREHOUSE_JSON_AGENT = "/warehouse/listJsonAgent";
  static _API_WAREHOUSE_CREATE = "/warehouse/post";
  static _API_WAREHOUSE_CREATE_JSON = "/warehouse/postJson";
  static _API_WAREHOUSE_CREATE_JSON_AGENT = "/warehouse/postJsonAgent";
  static _API_WAREHOUSE_UPDATE = "/warehouse/update";
  static _API_WAREHOUSE_DELETE = "/warehouse/delete";

  static _API_REGISTER = "/user/register";
  static _API_USER_LIST = "/user/list";
  static _API_USER_LIST_ALL = "/user/listall";
  static _API_USER_BY_DEPARTMENT_LIST = "/user/list/byDepartment";
  static _API_UPDATE_USER = "/user/update";
  static _API_CHANGE_PASSWORD = "/user/changePassword";
  static _API_DELETE_USER = "/user/delete";
  static _API_ORGANIZATION = "/user/list/byOrganizationHierarchy";
  static _API_UPDATE_USER_POLICY = "/user/updateUserPolicy";

  static _API_SERVICE_AGENT_LIST = "/service-agent/list";
  static _API_SERVICE_AGENT_CREATE = "/service-agent/create";
  static _API_SERVICE_AGENT_UPDATE = "/service-agent/update";
  static _API_SERVICE_AGENT_DELETE = "/service-agent/delete";

  static _API_ELEVATOR_MODELS = "/elevator-models";
  static _API_NUMBER_GENERATIONS = "/settings/number-generation";

  static _API_TASKS = "/management/tasks";

  static _API_ACTIVITY_LOG = "/activityLogs/list";
  static _API_USER_ACTIVITY_LOG = "/activityLogs/userLogList";
  static _API_EVENT_LOG = "/activityLogs/eventlist";

  static _API_GET_FEATURES = "/policy/feature/list";
  static _API_GET_ROLE_LIST = "/policy/role/list";
  static _API_GET_ROLE_LIST_PAGED = "/policy/role/listfullpages";

  static _API_CREATE_ROLE = "/role/create";
  static _API_UPDATE_ROLE = "/role/update";
  static _API_DELETE_ROLE = "/policy/role/delete";
  static _API_GET_ROLE_PERMISSION = "/role/permission";

  static _API_GET_MODULE_LIST = "/module/list";
  static _API_CREATE_MODULE = "/module/create";
  static _API_UPDATE_MODULE = "/module/update";
  static _API_DELETE_MODULE = "/module/delete";
}

/// <summary>
/// Author : -
/// Url to navigate to pages
/// </summary>
export class WebUrl {
  static _LOGIN = "/login";
  static _DASHBOARD = "/dashboard";

  static _ORGANIZATION_CHART = "/organization-chart";

  static _MANAGEMENT = "/management";
  static _USER_MANAGEMENT = "/management/users";
  static _DEPARTMENTS = "/management/departments";
  static _POSITIONS = "/management/positions";
  static _WAREHOUSE = "/management/warehouses";
  static _SERVICE_AGENTS = "/management/service-agents";
  static _ELEVATOR_MODELS = "/management/elevator-model";
  static _ELEVATOR_MODELS_CREATE_OR_UPDATE = "/management/elevator-model/:id";
  static _PROJECT_TASKS = "/management/project-tasks";
  static _SETTINGS = "/settings";
  static _NUMBER_GENERATION = "/settings/number-generation";
  static _ROLE_PERMISSION = "/settings/role-permission";
  static _GROUP_DETAIL = "/settings/role-permission/group-detail";

  static _MY_PROFILE = "/my/profile";
  static _MY_ACTIVITIES = "/my/activities";
  static _SETTINGS = "/management/settings";
  static _MODULE = "/settings/module";

  static _NECS = `${HostKey.getNecsHost()}/dashboard`;
}

/// <summary>
/// Author : -
/// </summary>
export class SessionKey {
  static _LANGUAGE = "language";
}

/// <summary>
/// Author : -
/// </summary>
export class Language {
  static _ENGLISH_GB = "en-GB";
  static _CHINESE_HK = "zh-HK";
  static _CHINESE_CN = "zh-CN";
}

/// <summary>
/// Author: Christopher Chan
/// </summary>
export const BtnTypes = {
  ADD: "add",
  DELETE: "delete",
  VIEW: "view",
  EDIT: "edit",
  MISC: "misc",
};

/// <summary>
/// Author: Christopher Chan
/// </summary>
export const InputTypes = {
  INPUT: "input",
  SELECT: "select",
  RADIO: "radio",
  PHONE: "phone",
  TEXTAREA: "textarea",
  ATTACHMENT: "attachment",
  DATEPICKER: "datepicker",
  CHECKBOX: "checkbox",
  FILEUPLOAD: "fileupload",
};

/// <summary>
/// Author: Christopher Chan
/// </summary>
export const SelectField = {
  _PHONE_TYPES: [
    { label: "Mobile", value: "Mobile" },
    { label: "Home", value: "Home" },
    { label: "Work", value: "Work" },
    { label: "Fax", value: "Fax" },
    { label: "Other", value: "Other" },
  ],

  _EMAIL_TYPES: [
    { label: "Personal", value: "Personal" },
    { label: "Work", value: "Work" },
    { label: "Other", value: "Other" },
  ],

  _ADDRESS_TYPES: [
    { label: "Home", value: "Home" },
    { label: "Work", value: "Work" },
    { label: "Other", value: "Other" },
  ],

  _STATE_OPTIONS: [
    { label: "Johor", value: "Johor" },
    { label: "Kedah", value: "Kedah" },
    { label: "Kelantan", value: "Kelantan" },
    { label: "Malacca", value: "Malacca" },
    { label: "Negeri Sembilan", value: "Negeri Sembilan" },
    { label: "Pahang", value: "Pahang" },
    { label: "Penang", value: "Penang" },
    { label: "Perak", value: "Perak" },
    { label: "Perlis", value: "Perlis" },
    { label: "Sabah", value: "Sabah" },
    { label: "Sarawak", value: "Sarawak" },
    { label: "Selangor", value: "Selangor" },
    { label: "Terengganu", value: "Terengganu" },
  ],

  _COUNTRY_OPTIONS: [{ label: "Malaysia", value: "Malaysia" }],

  _GENDER_OPTIONS: [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
  ],

  STATUS_OPTIONS: [
    { label: "Pending", value: 0 },
    { label: "Rejected", value: 1 },
    { label: "Approved", value: 2 },
  ],
};

export const SidebarType = {
  _ACCOUTANT: 1,
  _ADMIN: 2,
};

/// <summary>
/// Author: Christopher Chan
/// </summary>
export const Colors = {
  THEME: "#E08963",
  OFF_THEME: "#000000",
  WHITE: "#FFF",
  RED: "#f64e60",
  GRAY: "#dfe1e4",
};

/// <summary>
/// Author: Samuel
/// </summary>
export const Permission = {
  VIEW: "view",
  CREATE: "create",
  EDIT: "edit",
  DELETE: "delete",
};

export const PermissionKey = {
  [Permission.VIEW]: "canView",
  [Permission.CREATE]: "canCreate",
  [Permission.EDIT]: "canEdit",
  [Permission.DELETE]: "canDelete",
};

/// <summary>
/// Author: Samuel
/// </summary>
export const AppFeatureKeys = {
  _PERMISSIONS: "policies",
  _SETTINGS: "settings",
  _USERS: "users",
  _SERVICE_AGENTS: "service agents",
  _MODULES: "modules",
};
