import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useForm } from "react-hook-form";
import InputHoc from "components/form/InputHoc.js";

/// <summary>
/// Author: Christopher Chan
/// </summary>
export default ({ isOpen, toggle, setGroups }) => {
  const { register, errors, handleSubmit } = useForm();

  /// <summary>
  /// Author: Christopher Chan
  /// </summary>
  const onSubmit = (data) => {
    setGroups((prevState) => [
      ...prevState,
      {
        id: null,
        name: data.name,
        tasks: [],
      },
    ]);
    toggle();
  };

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle} centered>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader toggle={toggle}>Add Group</ModalHeader>
          <ModalBody>
            <InputHoc name="name" label="Group Name" error={errors?.name} />
          </ModalBody>
          <ModalFooter>
            <button type="submit" className="btn btn-themed btn-min-width">
              Add Group
            </button>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
};
