import React from 'react';
import { Route, withRouter, Redirect, useLocation } from 'react-router-dom';
import routes from './../../config/page-route.jsx';
import { PageSettings } from './../../config/page-settings.js';
import { useEffect } from 'react';
import { WebUrl } from 'util/Constant.js';
import useAuthController from 'recoil/useAuthController.js';

/// <summary>
/// Author: Christopher Chan
/// </summary>
const compareRoutes = (routePath, path) => {
        const splitRoutePath = routePath.split('/').slice(1);
        const splitPath = path.split('/').slice(1);

        for (let i = 0; i < splitRoutePath.length; i++) {
                if (splitRoutePath[i].charAt(0) === ':') {
                        splitRoutePath.splice(i, 1);

                        if (splitPath[i] !== undefined) {
                                splitPath.splice(i, 1);
                        }
                }
        }

        return splitRoutePath.join('/') === splitPath.join('/') && splitRoutePath.length === splitPath.length;
}

const setTitle = (path, routeArray) => {
        var pageTitle;

        for (var i = 0; i < routeArray.length; i++) {
                if (compareRoutes(routeArray[i].path, path)) {
                        pageTitle = `NEHR | ${routeArray[i].title}`;
                }
        }

        document.title = pageTitle;
}

const Content = ({ history }) => {
        const { isLoggedIn } = useAuthController({});
        const _location = useLocation();

        /// <summary>
        /// Author: Christopher Chan
        /// </summary>
        useEffect(() => {
                setTitle(history.location.pathname, routes);
                return history.listen(() => {
                        setTitle(history.location.pathname, routes);
                });
        }, []);

        return (
                <PageSettings.Consumer>
                        {({ pageContentFullWidth, pageContentClass, pageContentInverseMode, pageFooter }) => (
                                <div className={'content ' + (pageContentFullWidth ? 'content-full-width ' : '') + (pageContentInverseMode ? 'content-inverse-mode ' : '') + pageContentClass + (pageFooter ? 'has-footer ' : '')}>
                                        {routes.map((route, index) => (
                                                <Route key={index} {...route} />
                                        ))}
                                </div>
                        )}
                </PageSettings.Consumer>
        )
}

export default withRouter(Content);
