import React, { useEffect } from "react";
import { Card, CardBody, Row, Col, CardHeader } from "reactstrap";
import UserPositionForm from "./UserPositionForm";
import UserDepartmentForm from "./UserDepartmentForm";
import UserBuildingForm from "./UserBuildingForm";
import AgentPositionForm from "./AgentPositionForm";
import AgentDepartmentForm from "./AgentDepartmentForm";
import AgentBuildingForm from "./AgentBuildingForm";

/// <summary>
/// Author: KurisuCodes
/// </summary>
export default () => {
  return (
    <>
      <h1 className="page-header">Management / Settings</h1>
      <form>
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeader>JSON Config</CardHeader>
              <CardBody>
                <div
                  style={{ fontSize: 18, fontWeight: "bold", marginBottom: 10 }}
                >
                  User Profile
                </div>
                <UserPositionForm />
                <UserDepartmentForm />
                <UserBuildingForm />
                <div
                  style={{
                    fontSize: 18,
                    fontWeight: "bold",
                    marginBottom: 10,
                    marginTop: 20,
                  }}
                >
                  Agent Profile
                </div>
                <AgentPositionForm />
                <AgentDepartmentForm />
                <AgentBuildingForm />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </form>
    </>
  );
};
