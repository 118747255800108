import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { Config, ConfigEnum } from "util/Config";
import { useForm } from "react-hook-form";
import WarehouseDao from "data/WarehouseDao";
import Notiflix from "notiflix";
import { Can } from "config/user-access";
import { ApiKey, AppFeatureKeys, Permission } from "util/Constant";

import CustomInput from "components/form/CustomInput";

const AgentBuildingForm = ({}) => {
  const { register, handleSubmit, control, errors, reset } = useForm({});
  let configInstance = Config.getInstance();
  var token = configInstance.getValue(ConfigEnum._TOKEN);
  const [building, setBuilding] = useState(null);

  const getBuildingList = async () => {
    let dao = new WarehouseDao();
    await dao.getBuildingListJsonAgent(token).then((response) => {
      if (response[ApiKey._API_SUCCESS_KEY]) {
        var data = response[ApiKey._API_DATA_KEY];
        setBuilding(data);
      } else {
        Notiflix.Report.failure(
          "Error",
          "Failed to load building. Please try again later"
        );
      }
    });
  };

  useEffect(() => {
    getBuildingList();
  }, []);

  const onSubmit = async (data) => {
    let positionData = {
      name: data.name,
    };

    let action = "";
    let dao = new WarehouseDao();

    action = dao.registerJsonAgent(positionData, token);

    await action.then((responseJson) => {
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        var message = "Update Successful";
        Notiflix.Report.success("Success", message);
      } else {
        Notiflix.Report.failure(
          "Error",
          "Registration Failed. " + responseJson[ApiKey._API_MESSAGE_KEY]
        );
      }
    });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row className="m-b-10">
        <Col xs={2} style={{ height: "100%" }}>
          <div style={{ fontSize: 14, marginTop: 7 }}>Region</div>
        </Col>
        <Col xs={8}>
          <CustomInput
            {...register("name")}
            name="name"
            defaultValue={building}
            placeholder="Enter value in CSV format with comma (,) as delimiter."
          />
        </Col>
        <Col xs={2}>
          <Can I={Permission.CREATE} this={AppFeatureKeys._SETTINGS}>
            <button type="submit" className="btn btn-width btn-themed">
              Update
            </button>
          </Can>
        </Col>
      </Row>
    </form>
  );
};

export default AgentBuildingForm;
