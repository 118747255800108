import React, { useState, useEffect } from 'react';
import ReactSelect from 'react-select';
import { Controller } from 'react-hook-form';
import classnames from 'classnames';
import { Colors } from 'util/Constant';
import { ChromePicker } from 'react-color';

/// <summary>
/// Author: Christopher Chan
/// </summary>
const CustomColorPicker = (props) => {
    const {
        label,
        ...rest
    } = props;

    return <>
        <label className="custom-input-label">{label}</label>
        <Controller
            {...rest}
            render={
                ({ onChange, value }) => {
                    return (
                        <ChromePicker
                            color={value}
                            onChangeComplete={(color) => onChange(color.hex)}
                        />
                    );
                }
            }
        />
    </>;
};

export default CustomColorPicker;