import React from 'react';
import { Redirect } from 'react-router';
import { WebUrl } from './../util/Constant';

import Login from 'pages/common/LoginPage';
import Dashboard from 'pages/dashboard/Dashboard';
import RolePermission from '../pages/users/RolePermission';
import UserManagement from 'pages/users/UserManagement';
import DepartmentManagement from 'pages/departments/DepartmentManagement'
import WarehouseManagement from 'pages/warehouses/WarehouseManagement'
import PositionManagement from 'pages/position/PositionManagement'
import OrganizationChart from 'pages/organization/OrganizationChart';
import ServiceAgentsList from 'pages/service-agents/ServiceAgentsList';
import ElevatorModels from 'pages/elevator-models/List';
import ElevatorModelCreateOrUpdate from 'pages/elevator-models/CreateOrUpdate';
import NumberGeneration from 'pages/number-generation/NumberGeneration';
import Tasks from 'pages/tasks/Tasks';
import MyProfile from 'pages/MyProfile';
import MyActivities from 'pages/MyActivities';
import Settings from 'pages/settings';
import Module from 'pages/users/Module'

const routes = [
        {
                path: '/',
                exact: true,
                component: () => <Redirect to={WebUrl._LOGIN} />,
                authRequired: false,
        },
        {
                path: WebUrl._DASHBOARD,
                title: 'Dashboard',
                component: () => <Dashboard />,
                authRequired: true,
        },
        {
                path: WebUrl._LOGIN,
                title: 'Login',
                component: () => <Login />,
                authRequired: false,
        },
        {
                path: WebUrl._ORGANIZATION_CHART,
                title: "Organization",
                exact: true,
                component: () => <OrganizationChart />,
                authRequired: true,
        },
        {
                path: WebUrl._ROLE_PERMISSION,
                title: "Permissions",
                exact: true,
                component: () => <RolePermission />
        },
        {
                path: WebUrl._USER_MANAGEMENT,
                title: "Users",
                exact: true,
                component: () => <UserManagement />
        },
        {
                path: WebUrl._DEPARTMENTS,
                title: "Department Management",
                exact: true,
                component: () => <DepartmentManagement />
        },
        {
                path: WebUrl._WAREHOUSE,
                title: "Warehouse Management",
                exact: true,
                component: () => <WarehouseManagement />
        },
        {
                path: WebUrl._POSITIONS,
                title: "Position Management",
                exact: true,
                component: () => <PositionManagement />
        },
        {
                path: WebUrl._SERVICE_AGENTS,
                title: "Service Agents",
                exact: true,
                component: () => <ServiceAgentsList />
        },
        {
                path: WebUrl._ELEVATOR_MODELS,
                title: "Elevator Models",
                exact: true,
                component: () => <ElevatorModels />
        },
        {
                path: WebUrl._ELEVATOR_MODELS_CREATE_OR_UPDATE,
                title: "Elevator Models",
                exact: true,
                component: () => <ElevatorModelCreateOrUpdate />
        },
        {
                path: WebUrl._NUMBER_GENERATION,
                title: "Number Generation",
                exact: true,
                component: () => <NumberGeneration />
        },
        {
                path: WebUrl._PROJECT_TASKS,
                title: "Tasks",
                exact: true,
                component: () => <Tasks />
        },
        {
                path: WebUrl._MY_PROFILE,
                title: "My Profile",
                exact: true,
                component: () => <MyProfile />
        },
        {
                path: WebUrl._MY_ACTIVITIES,
                title: "My Activities",
                exact: true,
                component: () => <MyActivities />
        },
        {
                path: WebUrl._SETTINGS,
                title: "Settings",
                exact: true,
                component: () => <Settings />
        },
        {
                path: WebUrl._MODULE,
                title: "Module",
                exact: true,
                component: () => <Module />
        },
];


export default routes;