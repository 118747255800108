import React, { useState, useEffect, useMemo } from 'react';
import useApiResult from '../useApiResult';
import { getOrganization } from 'data/UserRequest';

/// <summary>
/// Author: KurisuCodes
/// </summary>
const useOrganization = () => {
    const [url, options] = useMemo(() => getOrganization(), []);
    return useApiResult(url, options);
}

export default useOrganization;