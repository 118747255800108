import { WebUrl } from "util/Constant";

const Menu = [
        { path: WebUrl._DASHBOARD, icon: 'fas fa-solid fa-grip-vertical', title: 'Dashboard' },
        { path: WebUrl._ORGANIZATION_CHART, icon: 'fas fa-solid fa-sitemap', title: 'Organization' },
        { path: WebUrl._MY_PROFILE, icon: 'fas fa-solid fa-id-badge', title: 'My Profile' },
        { path: WebUrl._MY_ACTIVITIES, icon: 'fas fa-solid fa-calendar-check', title: 'My Activities' },
        {
                path: WebUrl._MANAGEMENT, icon: 'fas fa-solid fa-wrench', title: 'Management',
                children: [
                        { path: WebUrl._USER_MANAGEMENT, title: 'Users' },
                        { path: WebUrl._SERVICE_AGENTS, title: 'Service Agents' },
                        { path: WebUrl._PROJECT_TASKS, title: 'Tasks' },
                        { path: WebUrl._MODULE, title: 'Module' },
                        { path: WebUrl._ROLE_PERMISSION, title: 'Permissions' },
                        { path: WebUrl._SETTINGS, title: 'Settings' }
                ]
        }

];

export const AdminMenu = [


];

export default Menu;
