import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useForm, useFieldArray } from "react-hook-form";
import { isEmpty, upperFirst } from "lodash";
import CustomInput from "components/form/CustomInput";
import { Permission } from "util/Constant";
import PolicyDao from "data/PolicyDao";
import { ApiKey, PermissionKey } from "util/Constant";
import { Config, ConfigEnum } from "util/Config";
import Notiflix, { Loading } from "notiflix";
import InputHoc from "components/form/InputHoc";
import CustomSelect from "components/form/CustomSelect";

/// <summary>
/// Author: Nicholas
/// </summary>
export default ({
  isOpen,
  toggleRoleModal,
  role,
  setRole,
  successCallback,
}) => {
  const { control, register, handleSubmit, reset, errors } = useForm();
  const fieldsKey = "appPermissions";
  const { fields } = useFieldArray({
    control,
    name: fieldsKey,
  });
  const [appFeaturelist, setAppFeauteList] = useState([]);
  const [rolePermissionList, setRolePermissionList] = useState([]);
  let policyDao = useMemo(() => new PolicyDao(), []);
  let configInstance = Config.getInstance();
  var token = configInstance.getValue(ConfigEnum._TOKEN);

  /// <summary>
  /// Author: Nicholas
  /// </summary>
  const getAppfeatures = useCallback(async () => {
    await policyDao
      .getFeatureList(token)
      .then((responseJson) => {
        if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
          throw new Error("Failed to retrieve features");
        }

        setAppFeauteList(responseJson[ApiKey._API_DATA_KEY]);
      })
      .catch((error) => {
        Notiflix.Report.failure("Error", "Failed to load features");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policyDao, token]);

  /// <summary>
  /// Author: Samuel
  /// </summary>
  const getRolePermission = async (id) => {
    await policyDao
      .getRolePermission(id, token)
      .then((responseJson) => {
        if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
          throw new Error("Failed to retrieve permissions");
        }

        setRolePermissionList(responseJson[ApiKey._API_DATA_KEY] ?? []);
      })
      .catch((error) => {
        Notiflix.Report.failure("Error", error?.message);
      });
  };

  /// <summary>
  /// Author: Chris
  /// </summary>
  useEffect(() => {
    if (isOpen) {
      if (role?.id) {
        getRolePermission(role?.id);
      }

      reset({ [fieldsKey]: appFeaturelist, ...role });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, appFeaturelist]);

  /// <summary>
  /// Author: Samuel
  /// </summary>
  const onOpened = async () => {
    Notiflix.Block.circle(".modal-content");
    appFeaturelist.length <= 0 && (await getAppfeatures());
    Notiflix.Block.remove(".modal-content");
  };

  /// <summary>
  /// Author: Samuel
  /// </summary>
  useEffect(() => {
    console.log(role);
    if (isOpen) {
      if (!isEmpty(rolePermissionList)) {
        console.log(rolePermissionList);

        reset({  [fieldsKey]: rolePermissionList });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rolePermissionList]);

  const onSubmit = async (data) => {
    console.log(data);
    Loading.circle();
    if (role) {
      data["id"] = role.id;
      await policyDao
        .update(data, token)
        .then((responseJson) => {
          if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
            throw new Error("Failed to add role");
          }

          Notiflix.Report.success(
            "Success",
            "Role successfully updated",
            "Okay",
            successCallback
          );
        })
        .catch((error) => {
          Notiflix.Report.failure("Error", "Failed to add role");
        })
        .finally(Loading.remove);
    } else {
      await policyDao
        .create(data, token)
        .then((responseJson) => {
          if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
            throw new Error("Failed to add role");
          }
          Notiflix.Report.success(
            "Success",
            "Role successfully created",
            "Okay",
            successCallback
          );
        })
        .catch((error) => {
          Notiflix.Report.failure("Error", "Failed to add role");
        })
        .finally(Loading.remove);
    }
  };

  const _STATUS_OPTIONS = [
    { label: "Active", value: 1 },
    { label: "Inactive", value: 0 },
  ];

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleRoleModal}
      centered
      size="lg"
      onOpened={onOpened}
      onClosed={() => {
        setRole(null);
      }}
    >
      <ModalHeader className="modal-themed" toggle={toggleRoleModal}>
        <h2 style={{ marginBottom: 0 }}>
          {role ? "MANAGEMENT / Edit Role" : "MANAGEMENT / Add Role"}
        </h2>
      </ModalHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody id="modalBody" style={{ height: "80vh", overflowY: "auto" }}>
          <Row>
            <Col md={5}>
              <CustomInput
                {...register("roleName")}
                name="roleName"
                label="Role Name *"
              //  defaultValue={role?.roleName}
                error={errors?.roleName}
                showLabel
              />
              <br></br>
              <CustomInput
                {...register("roleDescription")}
                name="roleDescription"
                label="Role Description *"
             //   defaultValue={role?.roleDescription}
                error={errors?.roleDescription}
                showLabel
              />
              <br></br>
              <label className="custom-input-label">Status *</label>
              <CustomSelect
                control={control}
                name="isActive"
                label="Status"
                placeholder="Status *"
                defaultValue={role?.isActive}
                options={_STATUS_OPTIONS}
                rules={{
                  required: true,
                }}
                error={errors?.isActive && "Status is required"}
                errors={errors?.isActive && "Status is required"}
              />
            </Col>
            <Col md={7}>
              <table className="w-100 table">
                <thead>
                  <tr>
                    <th>Permission</th>
                    <th className="text-center">
                      {upperFirst(Permission.VIEW)}
                    </th>
                    <th className="text-center">
                      {upperFirst(Permission.CREATE)}
                    </th>
                    <th className="text-center">
                      {upperFirst(Permission.EDIT)}
                    </th>
                    <th className="text-center">
                      {upperFirst(Permission.DELETE)}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {fields.map((field, index) => (
                    <tr key={role ? field.appFeatureId ?? index : field.id}>
                      {console.log(field)}
                      <td>
                        <InputHoc
                          type="hidden"
                          name={`${fieldsKey}.${index}.appFeatureId`}
                          {...register(`${fieldsKey}.${index}.appFeatureId`)}
                          value={
                            role ? field.appFeatureId ?? "" : field.module_id
                          }
                        />
                        <InputHoc
                          type="hidden"
                          name={`${fieldsKey}.${index}.appFeatureName`}
                          {...register(`${fieldsKey}.${index}.appFeatureName`)}
                          value={role ? field.appFeatureName ?? "" : field.name}
                        />

                        <b>{role ? field.appFeatureName : field.name}</b>
                      </td>
                      <td className="text-center">
                        <div className="switcher">
                          <input
                            type="checkbox"
                            id={`${fieldsKey}.${index}.${field.name}.${
                              PermissionKey[Permission.VIEW]
                            }`}
                            name={`${fieldsKey}.${index}.${field.name}.${
                              PermissionKey[Permission.VIEW]
                            }`}
                            {...register(
                              `${fieldsKey}.${index}.${field.name}.${
                                PermissionKey[Permission.VIEW]
                              }`
                            )}
                          />
                          <label
                            htmlFor={`${fieldsKey}.${index}.${field.name}.${
                              PermissionKey[Permission.VIEW]
                            }`}
                          ></label>
                        </div>
                      </td>
                      <td className="text-center">
                        <div className="switcher">
                          <input
                            type="checkbox"
                            id={`${fieldsKey}.${index}.${field.name}.${
                              PermissionKey[Permission.CREATE]
                            }`}
                            name={`${fieldsKey}.${index}.${field.name}.${
                              PermissionKey[Permission.CREATE]
                            }`}
                            {...register(
                              `${fieldsKey}.${index}.${field.name}.${
                                PermissionKey[Permission.CREATE]
                              }`
                            )}
                          />
                          <label
                            htmlFor={`${fieldsKey}.${index}.${field.name}.${
                              PermissionKey[Permission.CREATE]
                            }`}
                          ></label>
                        </div>
                      </td>
                      <td className="text-center">
                        <div className="switcher">
                          <input
                            type="checkbox"
                            id={`${fieldsKey}.${index}.${field.name}.${
                              PermissionKey[Permission.EDIT]
                            }`}
                            name={`${fieldsKey}.${index}.${field.name}.${
                              PermissionKey[Permission.EDIT]
                            }`}
                            {...register(
                              `${fieldsKey}.${index}.${field.name}.${
                                PermissionKey[Permission.EDIT]
                              }`
                            )}
                          />
                          <label
                            htmlFor={`${fieldsKey}.${index}.${field.name}.${
                              PermissionKey[Permission.EDIT]
                            }`}
                          ></label>
                        </div>
                      </td>
                      <td className="text-center">
                        <div className="switcher">
                          <input
                            type="checkbox"
                            id={`${fieldsKey}.${index}.${field.name}.${
                              PermissionKey[Permission.DELETE]
                            }`}
                            name={`${fieldsKey}.${index}.${field.name}.${
                              PermissionKey[Permission.DELETE]
                            }`}
                            {...register(
                              `${fieldsKey}.${index}.${field.name}.${
                                PermissionKey[Permission.DELETE]
                              }`
                            )}
                          />
                          <label
                            htmlFor={`${fieldsKey}.${index}.${field.name}.${
                              PermissionKey[Permission.DELETE]
                            }`}
                          ></label>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <button
            type="button"
            className="btn btn-red mr-auto btn-min-width"
            onClick={toggleRoleModal}
          >
            Cancel
          </button>
          <button type="submit" className="btn btn-min-width btn-themed">
            Save
          </button>
        </ModalFooter>
      </form>
    </Modal>
  );
};
