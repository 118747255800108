import React, { useEffect, useState } from 'react';
import { PageSettings } from './config/page-settings.js';

import Header from './components/header/header.jsx';
import Sidebar from './components/sidebar/sidebar.jsx';
import SidebarRight from './components/sidebar-right/sidebar-right';
import Content from './components/content/content.jsx';

import { PageOverlayLoader } from 'components/PageOverlayLoader.js';
import { ability, UserAccessContext } from 'config/user-access.js';
import { useHistory } from "react-router";
import { SidebarType, WebUrl } from './util/Constant';
import classNames from 'classnames';
import { Config, ConfigEnum } from 'util/Config';
import useAuthController from "recoil/useAuthController.js";

/// <summary>
/// Author: Andrew
/// </summary>
export default function App() {
        let configInstance = Config.getInstance();
        const [user, setUser] = useState(configInstance.getValue(ConfigEnum._USER));
        const [isAppLoadFinish, setIsAppLoadFinish] = useState(true);
        const _history = useHistory();
        const { validateCredentials, authCredential } = useAuthController({
                validateCredentialsCallback: {
                        success: () => {
                                // _history.push(WebUrl._DASHBOARD);
                        },
                        fail: () => {
                                _history.push(WebUrl._LOGIN)
                        },
                }
        });

        useEffect(() => {
                if (isAppLoadFinish) {
                        Config.getInstance();

                        validateCredentials();

                        setIsAppLoadFinish(false);
                }
        }, [isAppLoadFinish, validateCredentials]);

        var floatSubMenuRemoveTime = 250;
        var floatSubMenuRemove;
        var floatSubMenuCalculate;

        const handleSidebarOnMouseOut = (e) => {
                if (pageOptions.pageSidebarMinify) {
                        floatSubMenuRemove = setTimeout(() => {
                                this.setState(state => ({
                                        pageFloatSubMenuActive: false
                                }));
                        }, floatSubMenuRemoveTime);
                }
        }

        const handleSidebarOnMouseOver = (e, menu) => {
                if (pageOptions.pageSidebarMinify) {
                        if (menu.children) {
                                var left = (document.getElementById('sidebar').offsetWidth + document.getElementById('sidebar').offsetLeft) + 'px';

                                clearTimeout(floatSubMenuRemove);
                                clearTimeout(floatSubMenuCalculate);

                                this.setState(state => ({
                                        pageFloatSubMenu: menu,
                                        pageFloatSubMenuActive: true,
                                        pageFloatSubMenuLeft: left
                                }));

                                var offset = e.currentTarget.offsetParent.getBoundingClientRect();

                                floatSubMenuCalculate = setTimeout(() => {
                                        var targetTop = offset.top;
                                        var windowHeight = window.innerHeight;
                                        var targetHeight = document.querySelector('.float-sub-menu-container').offsetHeight;
                                        var top, bottom, arrowTop, arrowBottom, lineTop, lineBottom;

                                        if ((windowHeight - targetTop) > targetHeight) {
                                                top = offset.top + 'px';
                                                bottom = 'auto';
                                                arrowTop = '20px';
                                                arrowBottom = 'auto';
                                                lineTop = '20px';
                                                lineBottom = 'auto';
                                        } else {
                                                var aBottom = (windowHeight - targetTop) - 21;
                                                top = 'auto';
                                                bottom = '0';
                                                arrowTop = 'auto';
                                                arrowBottom = aBottom + 'px';
                                                lineTop = '20px';
                                                lineBottom = aBottom + 'px';
                                        }

                                        this.setState(state => ({
                                                pageFloatSubMenuTop: top,
                                                pageFloatSubMenuBottom: bottom,
                                                pageFloatSubMenuLineTop: lineTop,
                                                pageFloatSubMenuLineBottom: lineBottom,
                                                pageFloatSubMenuArrowTop: arrowTop,
                                                pageFloatSubMenuArrowBottom: arrowBottom,
                                                pageFloatSubMenuOffset: offset
                                        }));
                                }, 0);

                        } else {
                                floatSubMenuRemove = setTimeout(() => {
                                        this.setState(state => ({
                                                pageFloatSubMenu: '',
                                                pageFloatSubMenuActive: false
                                        }));
                                }, floatSubMenuRemoveTime);
                        }
                }
        }

        const toggleActiveSidebar = () => {
                let newActiveSidebar = pageOptions.activeSidebar === SidebarType._ACCOUTANT ? SidebarType._ADMIN : SidebarType._ACCOUTANT;
                setOptions('activeSidebar', newActiveSidebar);
        }

        const setAllPageOptions = (value) => {
                setPageOptions(
                        pageOptions =>
                        ({
                                ...pageOptions,
                                pageHeader: value,
                                pageSidebar: value,
                                pageContentFullWidth: !value,
                        })
                );
        }

        const [pageOptions, setPageOptions] = useState({
                activeSidebar: localStorage.getItem('activeSidebar') ?? SidebarType._ACCOUTANT,
                pageTopMenu: true,
                pageHeader: true,
                pageSidebar: true,
                pageContent: true,
                pageSidebarMinify: false,
                pageSidebarToggled: false,
                pageContentFullWidth: false,
                setAllPageOptions: setAllPageOptions,
                toggleMobileSidebar: () => toggleOptions('pageSidebarToggled'),
                toggleSidebarMinify: () => toggleOptions('pageSidebarMinify'),
                toggleActiveSidebar: toggleActiveSidebar,
                handleSidebarOnMouseOver: handleSidebarOnMouseOver,
                handleSidebarOnMouseOut: handleSidebarOnMouseOut,
                setOptions: (option, value) => { setOptions(option, value) }
        });

        /// <summary>
        /// Author: Christopher Chan
        /// </summary>
        const toggleOptions = option => {
                setPageOptions(pageOptions => ({ ...pageOptions, [option]: !pageOptions[option] }));
        }

        /// <summary>
        /// Author: Christopher Chan
        /// </summary>
        const setOptions = (option, value) => {
                let tempOptions = pageOptions;
                if (option === 'activeSidebar') {
                        localStorage.setItem('activeSidebar', value);
                }
                tempOptions[option] = value;
                setPageOptions({ ...pageOptions });
        }
        /// <summary>
        /// Author: Samuel
        /// </summary>
        useEffect(() => {
                setUser(configInstance.getValue(ConfigEnum._USER));
        }, [authCredential]);

        /// <summary>
        /// Author: Christopher Chan
        /// </summary>
        const _pageClasses = classNames(
                'fade page-sidebar-fixed show page-container',
                {
                        'page-header-fixed': pageOptions.pageHeader,
                        'page-without-sidebar': !pageOptions.pageSidebar,
                        'page-sidebar-minified': pageOptions.pageSidebarMinify,
                        'page-sidebar-toggled': pageOptions.pageSidebarToggled,
                        'page-with-right-sidebar': pageOptions.pageHeader && (pageOptions.activeSidebar === SidebarType._ADMIN || localStorage.getItem('activeSidebar') === SidebarType._ADMIN)
                }
        )

        if (isAppLoadFinish) {
                return (
                        <PageOverlayLoader />
                );
        } else {
                return (
                        <PageSettings.Provider value={pageOptions}>
                                <UserAccessContext.Provider value={ability(user)}>
                                        <div className={_pageClasses}>
                                                {pageOptions.pageHeader && (<Header />)}
                                                {pageOptions.pageSidebar && (<SidebarRight />)}
                                                {pageOptions.pageSidebar && (<Sidebar />)}
                                                {pageOptions.pageContent && (<Content />)}
                                        </div>
                                </UserAccessContext.Provider>

                        </PageSettings.Provider>
                );
        }
}