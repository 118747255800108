import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Notiflix from "notiflix";
import { Config, ConfigEnum } from "util/Config";
import { useForm } from "react-hook-form";
import ServiceAgentDao from "data/ServiceAgentDao.js";
import DepartmentDao from "data/DepartmentDao.js";
import PositionDao from "data/PositionDao.js";
import CommonDao from "data/CommonDao.js";
import { stringIsNullOrEmpty } from "util/Utility.js";
import WarehouseDao from "data/WarehouseDao";
import NoImagePlaceholder from "assets/img/user/No-Image-Placeholder.svg.png";
import moment from "moment";
import { Can } from "config/user-access";
import { ApiKey, AppFeatureKeys, Permission, HostKey } from "util/Constant";

import {
  FaUserTie,
  FaUserAlt,
  FaKey,
  FaNetworkWired,
  FaSuitcase,
  FaSitemap,
  FaBuilding,
  FaPhone,
  FaMobile,
  FaEnvelope,
  FaAddressCard,
  FaUserLock,
  FaUserShield,
  FaMap,
  FaTransgender,
  FaBirthdayCake,
  FaCarAlt,
} from "react-icons/fa";

import CustomInput from "components/form/CustomInput";
import CustomSelect from "components/form/CustomSelect";
import { FileUpload } from "components/form";

const _GENDER_OPTIONS = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
];

const _STATUS_OPTIONS = [
  { label: "Active", value: "1" },
  { label: "Suspended", value: "0" },
];

const _ACCOUNT_TYPE_OPTIONS = [
  { label: "Admin", value: "super admin" },
  { label: "Staff", value: "Staff" },
  { label: "Workers", value: "Workers" },
  { label: "Subcon", value: "Subcon" },
  { label: "Service Agent", value: "Service Agent" },
];

/// <summary>
/// Author: Christopher Chan
/// </summary>
export default ({
  isOpen,
  toggleModal,
  agent,
  getServiceAgentsList,
  onOpened,
  onClosed,
  isNewAgent,
  successCallback,
}) => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const [departmentList, setDepartmentList] = useState([]);
  const [positionList, setPositionList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [subordinateOptions, setSubordinateOptions] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  let configInstance = Config.getInstance();
  const [image, setImage] = useState();
  const [submitDisable, setSubmitDisable] = useState(false);
  var token = configInstance.getValue(ConfigEnum._TOKEN);

  /// <summary>
  /// Author: Christopher Chan
  /// </summary>
  const onSubmit = async (data) => {
    console.log(data);
    if(data.password) {
      if(data.password.length < 6) {
          Notiflix.Report.warning(
            "warning",
            `Password minimum 6 Characters`
          );
          return;
      }
    }

    setSubmitDisable(true);
    const formData = new FormData();
    formData.append("firstName", data.firstName);
    formData.append("lastName", data.lastName);
    formData.append("username", data.username);
    formData.append("password", data.password);
    formData.append("confirmPassword", data.password);
    formData.append("gender", data.gender);
    formData.append("dob", moment(data.birthday).format("YYYY-MM-DD"));
    formData.append("mobilePhone", data.mobilePhone);
    formData.append("housePhone", data.housePhone);
    formData.append("email", data.email);
    formData.append("employeeNumber", data.employeeNumber);
    formData.append("status", data.status);
    formData.append("icNumber", data.icNumber);
    formData.append("jobPosition", data.jobPosition);
    formData.append("departmentName", data.departmentName);
    formData.append("building", data.building);
    formData.append("accountType", data.accountType);
    formData.append("citizen", data.citizen);
    formData.append("subordinateTo", data.subordinateTo);
    formData.append("roleId", data?.jobRoleId);
    formData.append("warehouseId", 1);
    formData.append("citizen", data?.country);
    formData.append("vehicleNumber", data?.vehicleNumber);
    formData.append("addressLine1", data?.addressLine1);
    formData.append("addressLine2", data?.addressLine2);
    formData.append("city", data?.city);
    formData.append("postcode", data?.postcode);
    formData.append("state", data?.state);
    formData.append("country", data?.country);
    formData.append("agentCompany", data?.agentCompany);
    formData.append("region", data?.region);
    formData.append("employeeNumber", "0");
    if (!stringIsNullOrEmpty(data.photo)) {
      formData.append(`photo`, data.photo[0]);
    }

    if (agent) {
      formData.append("id", agent.id);
      formData.append("serviceAgentableId", agent?.serviceAgentableId)
    }

    let action = "";
    let dao = new ServiceAgentDao();

    if (agent) {
      data = {
        ...data,
        id: agent?.id,
        serviceAgentableId: agent?.serviceAgentableId,
      };
      action = dao.updateServiceAgent(formData, token);
    } else {
      action = dao.addServiceAgent(formData, token);
    }

    await action.then((responseJson) => {
      setSubmitDisable(false);
      console.log(responseJson);
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        Notiflix.Report.success(
          "Success",
          `Successfully ${agent ? "updated" : "added"} service agent.`,
          "Okay",
          successCallback
        );
      } else {        
        Notiflix.Report.failure(
          "Error",
          `Failed to ${agent ? "update" : "add"} service agent. ` +
            responseJson[ApiKey._API_MESSAGE_KEY]
        );
      }
    });
  };

  /// <summary>
  /// Author: Christopher Chan
  /// </summary>
  useEffect(() => {
    console.log(agent);
    setIsEditing(false);
  }, [agent]);

  /// <summary>
  /// Author: KurisuCodes
  /// </summary>
  useEffect(() => {
    getDepartmentList();
    getPositionList();
    getRoleList();
    getUserList();
    getRegionList();
  }, []);

  const getRegionList = async () => {
    let dao = new WarehouseDao();
    await dao.getBuildingListJsonAgent(token).then((response) => {
      if (response[ApiKey._API_SUCCESS_KEY]) {
        var data = response[ApiKey._API_DATA_KEY];
        var arr = data.split(",");
        var selectOptions = [];
        selectOptions = arr.map((item) => ({
          label: item,
          value: item,
        }));
        setRegionList(selectOptions);
      } else {
        Notiflix.Report.failure(
          "Error",
          "Failed to load building. Please try again later"
        );
      }
    });
  };

  const getDepartmentList = async () => {
    let dao = new DepartmentDao();
    await dao.getDepartmentListJsonAgent().then((response) => {
      if (response[ApiKey._API_SUCCESS_KEY]) {
        var data = response[ApiKey._API_DATA_KEY];
        var selectOptions = [];
        var arr = data.split(",");
        selectOptions = arr.map((item) => ({
          label: item,
          value: item,
        }));
        setDepartmentList(selectOptions);
      }
    });
  };

  const getPositionList = async () => {
    let dao = new PositionDao();
    await dao.getPositionListJsonAgent().then((response) => {
      if (response[ApiKey._API_SUCCESS_KEY]) {
        var data = response[ApiKey._API_DATA_KEY];
        var arr = data.split(",");
        var selectOptions = [];
        selectOptions = arr.map((item) => ({
          label: item,
          value: item,
        }));
        setPositionList(selectOptions);
      }
    });
  };

  /// <summary>
  /// Author: KurisuCodes
  /// </summary>
  const getRoleList = async () => {
    let dao = new CommonDao();
    await dao.getRoleList(token).then((response) => {
      if (response[ApiKey._API_SUCCESS_KEY]) {
        var data = response[ApiKey._API_DATA_KEY];
        var selectOptions = [];
        selectOptions = data.map((item) => ({
          label: item.name,
          value: item.name,
        }));
        setRoleList(selectOptions);
      }
    });
  };

  /// <summary>
  /// Author: KurisuCodes
  /// </summary>
  const getUserList = async () => {
    let dao = new ServiceAgentDao();
    await dao.getServiceAgentsList(token).then((response) => {
      if (response[ApiKey._API_SUCCESS_KEY]) {
        var data = response[ApiKey._API_DATA_KEY];
        var selectOptions = [];
        var placeHolder = {
          label : "No subordinate",
          value : ""
        };
      
        selectOptions = data.map((item) => ({
          label: `${item.lastName}, ${item.firstName}`,
          value: item.userId.toString(),
        }));
        selectOptions.unshift(placeHolder);
        setSubordinateOptions(selectOptions);
      }
    });
  };

  return (
    <Modal size="lg" {...{ isOpen, toggleModal, onClosed, onOpened }} centered>
      <ModalHeader className="modal-themed" toggle={toggleModal}>
        <h2 style={{ marginBottom: 0 }}>
          {agent ? "MANAGEMENT / Edit Service Agent" : "MANAGEMENT / New Agent"}
        </h2>
      </ModalHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody className="scroll-component" style={{ padding: "15px" }}>
          {isEditing == false && agent && (
            <Can I={Permission.EDIT} this={AppFeatureKeys._SERVICE_AGENTS}>
              <button
                type="button"
                onClick={() => {
                  setIsEditing(true);
                }}
                className="btn btn-themed pull-right"
              >
                <i className="fa fa-edit"></i>
              </button>
            </Can>
          )}
          {isEditing == true && (
            <button
              type="button"
              onClick={() => {
                setIsEditing(false);
              }}
              className="btn btn-themed pull-right"
            >
              <i className="fa fa-door-open"></i> Exit Edit Mode
            </button>
          )}
          <div style={{ fontSize: 16, fontWeight: "bold", marginBottom: 10 }}>
            Personal Details
          </div>
          <Row className="m-b-10">
            <Col xs={4}>
              <CustomInput
                {...register("firstName", {
                  required: "First Name is required",
                })}
                name="firstName"
                label="First Name *"
                icon={<FaUserTie />}
                defaultValue={agent?.firstName}
                readOnly={agent ? !isEditing : isEditing}
                error={errors?.firstName?.message}
              />
            </Col>
            <Col xs={4}>
              <CustomInput
                {...register("lastName", {
                  required: "Last Name is required",
                })}
                name="lastName"
                label="Last Name *"
                icon={<FaUserTie />}
                defaultValue={agent?.lastName}
                readOnly={agent ? !isEditing : isEditing}
                error={errors?.lastName?.message}
              />
            </Col>
            <Col xs={4}>
              <CustomSelect
                control={control}
                name="jobPosition"
                label="Position"
                icon={<FaSuitcase />}
                options={positionList}
                placeholder="Position *"
                defaultValue={agent?.jobPosition}
                readOnly={agent ? !isEditing : isEditing}
                rules={{
                  required: true,
                }}
                error={errors?.jobPosition && "Job Position is required"}
                errors={errors?.jobPosition && "Job Position is required"}
              />
            </Col>
          </Row>
          <Row className="m-b-10">
            <Col xs={4}>
              <CustomInput
                {...register("icNumber", {
                  required: "IC Number is required",
                })}
                name="icNumber"
                label="IC Number *"
                icon={<FaAddressCard />}
                defaultValue={agent?.icNumber}
                readOnly={agent ? !isEditing : isEditing}
                error={errors?.icNumber?.message}
              />
            </Col>
            <Col xs={4}>
              <CustomInput
                {...register("vehicleNumber", {
                  required: "Vehicle Number is required",
                })}
                name="vehicleNumber"
                label="Vehicle Number *"
                icon={<FaCarAlt />}
                defaultValue={agent?.vehicleNumber}
                readOnly={agent ? !isEditing : isEditing}
                error={errors?.vehicleNumber?.message}
              />
            </Col>
            <Col xs={4}>
              <CustomSelect
                control={control}
                name="gender"
                label="Gender"
                icon={<FaTransgender />}
                defaultValue={agent?.gender}
                options={_GENDER_OPTIONS}
                readOnly={agent ? !isEditing : isEditing}
              />
            </Col>
          </Row>
          <Row className="m-b-10">
            <Col xs={4}>
              <CustomInput
                {...register("mobilePhone", {
                  required: "Mobile Phone is required",
                })}
                name="mobilePhone"
                label="Mobile Phone *"
                icon={<FaMobile />}
                defaultValue={agent?.mobilePhone}
                readOnly={agent ? !isEditing : isEditing}
                error={errors?.mobilePhone?.message}
              />
            </Col>
            <Col xs={4}>
              <CustomInput
                {...register("housePhone", false)}
                name="housePhone"
                label="House Phone"
                icon={<FaPhone />}
                defaultValue={agent?.housePhone}
                readOnly={agent ? !isEditing : isEditing}
                error={errors?.housePhone?.message}
              />
            </Col>
            <Col xs={4}>
              <CustomInput
                {...register("email", {
                  required: "Email is required",
                })}
                name="email"
                label="Email *"
                icon={<FaEnvelope />}
                defaultValue={agent?.email?.email}
                readOnly={agent ? !isEditing : isEditing}
                error={errors?.email?.message}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={4}>
              <CustomInput
                {...register("agentCompany", {
                  required: "Company Name is required",
                })}
                name="agentCompany"
                label="Company Name *"
                icon={<FaSuitcase />}
                defaultValue={agent?.agentCompany}
                readOnly={agent ? !isEditing : isEditing}
                error={errors?.agentCompany?.message}
              />
            </Col>
            <Col xs={4}>
              <CustomSelect
                control={control}
                name="departmentName"
                label="Department"
                icon={<FaSitemap />}
                options={departmentList}
                placeholder="Department *"
                defaultValue={agent?.departmentName}
                readOnly={agent ? !isEditing : isEditing}
                rules={{
                  required: true,
                }}
                error={errors?.departmentName && "Department Name is required"}
                errors={errors?.departmentName && "Department Name is required"}
              />
            </Col>
            <Col xs={4}>
              <CustomSelect
                control={control}
                name="region"
                label="Region"
                icon={<FaBuilding />}
                options={regionList}
                placeholder="Region *"
                defaultValue={agent?.region}
                readOnly={agent ? !isEditing : isEditing}
                rules={{ required: true }}
                error={errors?.region && "Region is required"}
                errors={errors?.region && "Region is required"}
              />
            </Col>
          </Row>
          <div
            style={{
              fontSize: 16,
              fontWeight: "bold",
              marginBottom: 10,
              marginTop: 20,
            }}
          >
            Company Address
          </div>
          <Row className="m-b-10">
            <Col xs={6}>
              <CustomInput
                {...register("addressLine1", false)}
                name="addressLine1"
                label="Address Line 1"
                defaultValue={agent?.address?.addressLine1}
                icon={<FaMap />}
                readOnly={agent ? !isEditing : isEditing}
              />
            </Col>
            <Col xs={6}>
              <CustomInput
                {...register("addressLine2", false)}
                name="addressLine2"
                label="Address Line 2"
                defaultValue={agent?.address?.addressLine2}
                icon={<FaMap />}
                readOnly={agent ? !isEditing : isEditing}
              />
            </Col>
          </Row>
          <Row className="m-b-10">
            <Col xs={3}>
              <CustomInput
                {...register("postcode", false)}
                name="postcode"
                defaultValue={agent?.address?.postcode}
                label="Postcode"
                icon={<FaMap />}
                readOnly={agent ? !isEditing : isEditing}
              />
            </Col>
            <Col xs={3}>
              <CustomInput
                {...register("city", false)}
                name="city"
                label="City"
                defaultValue={agent?.address?.city}
                icon={<FaMap />}
                readOnly={agent ? !isEditing : isEditing}
              />
            </Col>
            <Col xs={3}>
              <CustomInput
                {...register("state", false)}
                name="state"
                label="State"
                defaultValue={agent?.address?.state}
                icon={<FaMap />}
                readOnly={agent ? !isEditing : isEditing}
              />
            </Col>
            <Col xs={3}>
              <CustomInput
                {...register("country", false)}
                name="country"
                label="Country"
                defaultValue={agent?.address?.country}
                icon={<FaMap />}
                readOnly={agent ? !isEditing : isEditing}
              />
            </Col>
          </Row>
          <div
            style={{
              fontSize: 16,
              fontWeight: "bold",
              marginBottom: 10,
              marginTop: 30,
            }}
          >
            User Details
          </div>
          <Row className="m-b-10">
            <Col xs={4}>
              <CustomInput
                {...register("username", {
                  required: "Username is required",
                })}
                name="username"
                label="Username *"
                icon={<FaUserAlt />}
                readOnly={agent ? !isEditing : isEditing}
                defaultValue={agent?.username}               
                error={errors?.username?.message}
              />
            </Col>
            <Col xs={4}>
              <CustomSelect
                control={control}
                name="subordinateTo"
                label="Subordinate To *"
                placeholder="Subordinate To"
                icon={<FaNetworkWired />}
                options={subordinateOptions}
                defaultValue={agent?.subordinateTo}
                readOnly={agent ? !isEditing : isEditing}
                rules={{
                  required: true,
                }}
                error={errors?.subordinateTo && "Subordinate is required"}
                errors={errors?.subordinateTo && "Subordinate is required"}
              />
            </Col>
            {!agent && (
              <Col xs={4}>
                <CustomInput
                  {...register("password", {
                    required: "Password is required",
                  })}
                  name="password"
                  label="Password *"
                  icon={<FaKey />}
                  readOnly={agent ? !isEditing : isEditing}
                  type="password"
                  error={errors?.password?.message}
                />
              </Col>
            )}
          </Row>
          <Row className="m-b-10">
            <Col xs={4}>
              <CustomSelect
                control={control}
                name="status"
                label="Access Status"
                placeholder="Status *"
                icon={<FaUserLock />}
                defaultValue={agent?.status}
                options={_STATUS_OPTIONS}
                readOnly={agent ? !isEditing : isEditing}
                rules={{ required: true }}
                error={errors?.status && "Status is required"}
                errors={errors?.status && "Status is required"}
              />
            </Col>
            <Col xs={4}>
              <CustomSelect
                control={control}
                name="accountType"
                label="Account Type"
                icon={<FaUserShield />}
                options={_ACCOUNT_TYPE_OPTIONS}
                defaultValue={agent?.accountType}
                placeholder="Account Type *"
                rules={{ required: true }}
                readOnly={agent ? !isEditing : isEditing}
                error={errors?.accountType && "Status is required"}
                errors={errors?.accountType && "Status is required"}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <label className="custom-input-label">Profile Image</label>
              {!isEditing && agent ? (
                <div className="user-img__container">
                  <img
                    src={
                      agent?.photo
                        ? HostKey.getApiHost() + agent?.photo
                        : NoImagePlaceholder
                    }
                  />
                </div>
              ) : (
                [
                  <FileUpload
                    control={control}
                    name="photo"
                    setImage={setImage}
                  />,
                ]
              )}
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-red mr-auto btn-min-width"
            onClick={toggleModal}
          >
            Cancel
          </button>
          {isEditing && agent && (
            <button
              type="submit"
              className="btn btn-themed btn-min-width pull-right mt-2"
            >
              Submit Changes
            </button>
          )}
          {isNewAgent && (
            <button
              disabled={submitDisable}
              type="submit"
              className="btn btn-themed btn-min-width pull-right mt-2"
            >
              Submit New Agent
            </button>
          )}
        </ModalFooter>
      </form>
    </Modal>
  );
};
