import React, { useState, useEffect, useCallback } from "react";
import { Card, CardBody, Row, Col } from 'reactstrap';
import { InputTypes, ApiKey } from "util/Constant";
import { Config, ConfigEnum } from 'util/Config';
import NumberGenerationDao from "data/NumberGenerationDao";
import InputHoc from "components/form/InputHoc.js";
import { useForm, useFieldArray } from "react-hook-form";
import { Report, Block } from 'notiflix';
import { tryCatch } from 'util/Utility';

/// <summary>
/// Author: Christopher Chan
/// </summary>
export default () => {
    const [numberGenerations, setNumberGenerations] = useState([]);
    const { register, control, handleSubmit, reset, errors } = useForm();
    const { fields } = useFieldArray({
        control,
        name: 'numberGenerations'
    });

    let configInstance = Config.getInstance();
    var token = configInstance.getValue(ConfigEnum._TOKEN);

    const _SEPERATOR_OPTIONS = [
        { label: 'None', value: '' },
        { label: "-", value: '-' },
        { label: "/", value: '/' },
    ]

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    const getNumberGenerations = useCallback(() => {
        tryCatch(async () => {
            Block.circle('#numberGenerationForm');

            let dao = new NumberGenerationDao();

            await dao.getNumberGenerations(token)
                .then(responseJson => {
                    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                        setNumberGenerations(responseJson[ApiKey._API_DATA_KEY]);
                    }
                    else {
                        Report.Failure('Error', 'Failed to retrieve number generations list. ' + responseJson[ApiKey._API_MESSAGE_KEY]);
                    }
                })
                .catch(error => {
                    Report.Failure('Error', 'Operation Failed. ' + error);
                });
        }, () => {
            Block.remove('#numberGenerationForm');
        });
    }, [token]);

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    const onSubmit = (data) => {
        console.log(data);
        tryCatch(async () => {
            let dao = new NumberGenerationDao();

            await dao.updateNumberGenerations(data, token)
                .then(responseJson => {
                    console.log(responseJson);
                    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                        Report.success('Success', 'Successfully updated number generations.', 'Okay', getNumberGenerations);
                    }
                    else {
                        Report.failure('Error', 'Failed to update number generations. ' + responseJson[ApiKey._API_MESSAGE_KEY]);
                    }
                })
                .catch(error => {
                    Report.failure('Error', 'Operation Failed. ' + error);
                });
        });
    }

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    useEffect(() => {
        reset({ numberGenerations });
    }, [numberGenerations, reset])

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    useEffect(() => {
        getNumberGenerations();
    }, [getNumberGenerations])

    return (
        <form id="numberGenerationForm" onSubmit={handleSubmit(onSubmit)}>
            <div className="float-xl-right">
                <button type="submit" className="btn btn-themed btn-min-width">Save</button>
            </div>
            <h1 className="page-header">Number Generation</h1>
            <Card className="mb-2">
                <CardBody>
                    {
                        fields.map((item, index) => {
                            return (
                                <Row key={item.id}>
                                    <Col>
                                        <InputHoc
                                            name={`numberGenerations[${index}].model`}
                                            label="Model" ref={register}
                                            defaultValue={item?.model}
                                            disabled
                                        />
                                    </Col>
                                    <Col>
                                        <InputHoc
                                            name={`numberGenerations[${index}].prefix`}
                                            label="Prefix" ref={register}
                                            error={errors?.parts?.[index]?.prefix}
                                            defaultValue={item?.prefix}
                                        />
                                    </Col>
                                    <Col>
                                        <InputHoc
                                            name={`numberGenerations[${index}].prefixSeperator`}
                                            label="Prefix Seperator"
                                            inputType={InputTypes.SELECT}
                                            options={_SEPERATOR_OPTIONS}
                                            control={control}
                                            error={errors?.parts?.[index]?.prefixSeperator}
                                            defaultValue={item?.prefixSeperator}
                                            smallnote="Seperator between prefix and running number"
                                        />
                                    </Col>
                                    <Col>
                                        <InputHoc
                                            name={`numberGenerations[${index}].suffix`}
                                            label="Suffix" ref={register}
                                            error={errors?.parts?.[index]?.suffix}
                                            defaultValue={item?.suffix}
                                        />
                                    </Col>
                                    <Col>
                                        <InputHoc
                                            name={`numberGenerations[${index}].suffixSeperator`}
                                            label="Suffix Seperator"
                                            inputType={InputTypes.SELECT}
                                            options={_SEPERATOR_OPTIONS}
                                            control={control}
                                            error={errors?.parts?.[index]?.suffixSeperator}
                                            defaultValue={item?.suffixSeperator}
                                            smallnote="Seperator between running number and suffix"
                                        />
                                    </Col>
                                    <input ref={register} name={`numberGenerations[${index}].id`} value={item?.id} type="hidden" />
                                </Row>
                            )
                        })
                    }
                </CardBody>
            </Card>
        </form>
    )
}
