import { Row, Col } from "reactstrap";
import { SystemLog, TaskEvent } from "util/TempActivitiesData";
import VerticalTimeline from "components/VerticalTimeline";
import React, { useState, useEffect, useRef, useMemo } from "react";
import Notiflix from "notiflix";
import { ApiKey } from "util/Constant";
import { Config, ConfigEnum } from "util/Config";
import ActivityLogDao from "data/ActivitylogDao";
import { useForm } from "react-hook-form";
import CustomSelect from "components/form/CustomSelect";

/// <summary>
/// Author: KurisuCodes
/// </summary>
const MyActivities = () => {
  const { register, handleSubmit, control, errors, reset } = useForm({});
  const [listQueryParams, setListQueryParams] = useState();
  const [systemLogs, setSystemLogs] = useState([]);
  const [eventLogs, setEventLogs] = useState([]);
  const [systemQuery, setSystemQuery] = useState();
  const [eventQuery, setEventQuery] = useState();
  let configInstance = Config.getInstance();
  var token = configInstance.getValue(ConfigEnum._TOKEN);
  let dao = useMemo(() => new ActivityLogDao(), []);

  const _SYSTEM_LOG_FILTER = [
    { label: "Last 7 Days", value: "7" },
    { label: "Last 30 Days", value: "30" },
    { label: "Last 60 Days", value: "60" },
    { label: "This Year", value: "This Year" },
  ];
  const getSystemLog = async () => {
    let dao = new ActivityLogDao();
    var obj = {
      pageIndex: 1,
      pageSize: 10,
      systemQuery: systemQuery ? systemQuery : 7,
    };

    await dao.getActivityLog(token, obj).then((response) => {
      if (response[ApiKey._API_SUCCESS_KEY]) {
        var data = response[ApiKey._API_DATA_KEY];
        let arr = [];
        for (var i = 0; i < data.length; i++) {
          let formatArray = [];
          for (var j = 0; j < data[i].length; j++) {
            if (data[i][j].portal == "NEUP") {
              var obj = {
                date: data[i][j].datetime,
                description: data[i][j].title,
                title: data[i][j].portal,
                theme: {
                  title: "black",
                  main: "orange",
                },
              };
              formatArray.push(obj);
            } else if (data[i][j].portal == "NECS") {
              var obj = {
                date: data[i][j].datetime,
                description: data[i][j].title,
                title: data[i][j].portal,
                theme: {
                  title: "pink",
                  main: "orange",
                },
              };
              formatArray.push(obj);
            } else if (data[i][j].portal == "NEPS") {
              var obj = {
                date: data[i][j].datetime,
                description: data[i][j].title,
                title: data[i][j].portal,
                theme: {
                  title: "blue",
                  main: "orange",
                },
              };
              formatArray.push(obj);
            } else if (data[i][j].portal == "NESM") {
              var obj = {
                date: data[i][j].datetime,
                description: data[i][j].title,
                title: data[i][j].portal,
                theme: {
                  title: "green",
                  main: "purple",
                },
              };
              formatArray.push(obj);
            }
          }
          arr.push(formatArray);
        }
        setSystemLogs(arr);
      } else {
        Notiflix.Report.failure(
          "Error",
          "Failed to load users. Please try again later"
        );
      }
    });
  };

  const getEventLog = async () => {
    let dao = new ActivityLogDao();
    var obj = {
      pageIndex: 1,
      pageSize: 10,
      systemQuery: eventQuery ? eventQuery : 7,
    };

    await dao.getEventLog(token, obj).then((response) => {
      if (response[ApiKey._API_SUCCESS_KEY]) {
        var data = response[ApiKey._API_DATA_KEY];
        let arr = [];
        for (var i = 0; i < data.length; i++) {
          let formatArray = [];
          for (var j = 0; j < data[i].length; j++) {
            if (data[i][j].portal == "NEUP") {
              var obj = {
                date: data[i][j].datetime,
                description: data[i][j].title,
                title: data[i][j].portal,
                theme: {
                  title: "black",
                  main: "purple",
                },
              };
              formatArray.push(obj);
            } else if (data[i][j].portal == "NECS") {
              var obj = {
                date: data[i][j].datetime,
                description: data[i][j].title,
                title: data[i][j].portal,
                theme: {
                  title: "pink",
                  main: "purple",
                },
              };
              formatArray.push(obj);
            } else if (data[i][j].portal == "NEPS") {
              var obj = {
                date: data[i][j].datetime,
                description: data[i][j].title,
                title: data[i][j].portal,
                theme: {
                  title: "blue",
                  main: "purple",
                },
              };
              formatArray.push(obj);
            } else if (data[i][j].portal == "NESM") {
              var obj = {
                date: data[i][j].datetime,
                description: data[i][j].title,
                title: data[i][j].portal,
                theme: {
                  title: "green",
                  main: "purple",
                },
              };
              formatArray.push(obj);
            }
          }
          arr.push(formatArray);
        }
        setEventLogs(arr);
      } else {
        Notiflix.Report.failure(
          "Error",
          "Failed to load users. Please try again later"
        );
      }
    });
  };

  useEffect(() => {
    getSystemLog();
    getEventLog();
  }, []);

  useEffect(() => {
    if (systemQuery) {
      getSystemLog();
    }
  }, [systemQuery]);

  useEffect(() => {
    if (eventQuery) {
      getEventLog();
    }
  }, [eventQuery]);

  return (
    <>
      <h1 className="page-header">MY ACTIVITIES</h1>
      <Row>
        <Col xs={4}>
          <div className="activities-title" style={{ display: "inline-block" }}>
            System Log
          </div>
          <CustomSelect
            control={control}
            name="Filter"
            placeholder="Filter"
            onChange={(data) => {
              setSystemQuery(data);
            }}
            options={_SYSTEM_LOG_FILTER}
            styles={{ display: "inline-block" }}
          />
          {systemLogs.length > 0 && <VerticalTimeline items={systemLogs} />}
        </Col>
        <Col xs={8}>
          <div className="activities-title" style={{ display: "inline-block" }}>
            Task Event
          </div>
          <Col xs={6}>
            <CustomSelect
              control={control}
              name="Filter"
              placeholder="Filter"
              onChange={(data) => {
                setEventQuery(data);
              }}
              options={_SYSTEM_LOG_FILTER}
            />
          </Col>

          {eventLogs.length > 0 && <VerticalTimeline items={eventLogs} />}
        </Col>
      </Row>
    </>
  );
};

export default MyActivities;
