import { ApiUrl } from 'util/Constant';
import DataAccessObject from './DataAccessObject';

/// <summary>
/// Author: Christopher Chan
/// </summary>
class ElevatorModelDao extends DataAccessObject {

    async getElevatorModels(token) {
        return this.get(ApiUrl._API_ELEVATOR_MODELS, token);
    }

    async createOrUpdateElevatorModel(data, token) {
        return this.post(ApiUrl._API_ELEVATOR_MODELS, data, token);
    }
}

export default ElevatorModelDao;
