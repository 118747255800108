import { ApiUrl } from "util/Constant";
import DataAccessObject from "./DataAccessObject";

/**
 * Author : Robin
 * DataAccessObject's fetch will return the promise.
 */
class AuthenticationDao extends DataAccessObject {
  async login(data) {
    console.log(data);
    return this.post(ApiUrl._API_LOGIN, data);
  }

  async validateLogin(token) {
    return this.get(ApiUrl._API_VALIDATE_LOGIN, token);
  }

  async logout(token) {
    return this.post(ApiUrl._API_LOGOUT, null, token);
  }
}

export default AuthenticationDao;
