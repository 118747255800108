import { useState, useEffect } from 'react';

/// <summary>
/// Author: KurisuCodes
/// </summary>
const useApiResult = (url, options) => {
    const [results, setResults] = useState(null);
    const [error, setError] = useState(null);

    /// <summary>
    /// Author: KurisuCodes
    /// </summary>
    useEffect(() => {
        fetch(url, options)
            .then(async (response) => {
                if (response.ok) {
                    let data = await response.json();

                    if (!data?.success) {
                        throw data?.message;
                    }

                    setResults(data?.data);
                    setError(null);
                }
                else {
                    setError(await response.text());
                }
            })
            .catch(err => {
                setError(err.message);
            });
    }, [url, options]);

    return [results, error];
}

export default useApiResult;