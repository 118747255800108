import { ApiUrl } from "util/Constant";
import DataAccessObject from "./DataAccessObject";

/**
 * Author : Sim
 * DataAccessObject's fetch will return the promise.
 */
class WarehouseDao extends DataAccessObject {
  async getWarehouseList(token) {
    return this.get(ApiUrl._API_GET_WAREHOUSE, token);
  }

  async getBuildingListJson(token) {
    return this.get(ApiUrl._API_GET_WAREHOUSE_JSON, token);
  }

  async getBuildingListJsonAgent(token) {
    return this.get(ApiUrl._API_GET_WAREHOUSE_JSON_AGENT, token);
  }

  async register(data, token) {
    return this.post(ApiUrl._API_WAREHOUSE_CREATE, data, token);
  }

  async registerJson(data, token) {
    return this.post(ApiUrl._API_WAREHOUSE_CREATE_JSON, data, token);
  }

  async registerJsonAgent(data, token) {
    return this.post(ApiUrl._API_WAREHOUSE_CREATE_JSON_AGENT, data, token);
  }

  async updateWarehouse(data, token) {
    return this.post(ApiUrl._API_WAREHOUSE_UPDATE, data, token);
  }

  async deleteWarehouse(id, token) {
    return this.post(`${ApiUrl._API_WAREHOUSE_DELETE}/${id}`, null, token);
  }
}

export default WarehouseDao;
