import { ApiUrl } from "util/Constant";
import DataAccessObject from "./DataAccessObject";

/**
 * Author : Lewis
 * DataAccessObject's fetch will return the promise.
 */
class CommonDao extends DataAccessObject {
  async getDepartmentList() {
    return this.get(ApiUrl._API_GET_DEPARTMENT);
  }

  async getPositionList() {
    return this.get(ApiUrl._API_GET_POSITION);
  }

  async getWarehouseList() {
    return this.get(ApiUrl._API_GET_WAREHOUSE);
  }

  async getRoleList(token) {
    return this.get(ApiUrl._API_GET_ROLE, token);
  }
}

export default CommonDao;
