import React, { useState, useEffect, useRef, useCallback } from "react";
import { Row, Col } from "reactstrap";
import ForceGraph2D from "react-force-graph-2d";

import {
  FaPlaneArrival,
  FaPlaneDeparture,
  FaMale,
  FaFemale,
} from "react-icons/fa";

import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import useOrganization from "hooks/api/user/useOrganization";
import Graph from "react-graph-vis";
import ProgressBar from "@ramonak/react-progress-bar";

/// <summary>
/// Author : Nicholas
/// </summary>
const OrganizationChart = () => {
  const colRef = useRef();
  const fgRef = useRef();
  const [canvasSize, setCanvasSize] = useState(null);
  const [details, error] = useOrganization();
  const [nodes, setNodes] = useState([]);
  const [newGraph, setGraph] = useState();

  /// <summary>
  /// Author: Nicholas
  /// </summary>
  useEffect(() => {
    console.log(details);
    let nodeArr = [];
    for (let i = 0; i < details?.nodes.length; i++) {
      if (details?.nodes[i].id === 1) {
        var obj = {
          id: details?.nodes[i].id,
          label: details?.nodes[i].name,
          shape: "box",
          color: "#b7b3b3",
        };
        nodeArr.push(obj);
      } else {
        var obj = {
          id: details?.nodes[i].id,
          label: details?.nodes[i].name,
          shape: "box",
        };
        nodeArr.push(obj);
      }
    }

    setNodes(nodeArr);

    let linkArr = [];

    for (let i = 0; i < details?.links.length; i++) {
      var obj = {
        from: details?.links[i].source,
        to: details?.links[i].target,
        smooth: {
          enabled: true,
        },
      };
      linkArr.push(obj);
    }
    var graph = {
      nodes: nodeArr,
      edges: linkArr,
    };
    setGraph(graph);
  }, [details]);

  /// <summary>
  /// Author: KurisuCodes
  /// </summary>
  useEffect(() => {
    console.log(colRef);
    if (colRef.current) {
      setCanvasSize({
        width: colRef.current.offsetWidth,
        height: colRef.current.offsetHeight,
      });
    }
  }, [colRef]);

  /// <summary>
  /// Author: KurisuCodes
  /// </summary>
  const calcuateBarWidth = (value) => {
    return `calc(${value ?? 0}% + 60px)`;
  };

  const graph = {
    nodes: [
      { id: 1, label: "Procedure", color: "#cb8782", shape: "box" },
      { id: 2, label: "Patient", color: "#f0c69d", shape: "box" },
      { id: 3, label: "Condition", color: "#f4dd9b", shape: "box" },
      { id: 4, label: "Observation", color: "#9cc49a", shape: "box" },
      { id: 5, label: "Encounter", color: "#9ab0c4", shape: "box" },
    ],
    edges: [
      {
        from: 1,
        to: 2,
        label: "Subject",
        smooth: {
          enabled: true,
        },
        color: "#Be3a25",
      },
      {
        from: 3,
        to: 2,
        label: "Subject",
        smooth: {
          enabled: true,
        },
        color: "#EAC215",
      },
      {
        from: 4,
        to: 2,
        label: "Subject",
        smooth: {
          enabled: true,
        },
        color: "#5e765c",
      },
      {
        from: 5,
        to: 2,
        label: "Subject",
        smooth: {
          enabled: true,
        },
        color: "#254460",
      },
      {
        from: 3,
        to: 5,
        label: "Subject",
        smooth: {
          enabled: true,
        },
        color: "#EAC215",
      },
    ],
  };

  const options = {
    nodes: {
      chosen: {
        borderColor: "EAC215",
      },
    },
    layout: {
      hierarchical: {
        enabled: true,
        levelSeparation: 150,
        nodeSpacing: 100,
        treeSpacing: 200,
        blockShifting: true,
        edgeMinimization: true,
        parentCentralization: true,
        direction: "LR", // UD, DU, LR, RL
        sortMethod: "hubsize", // hubsize, directed
        shakeTowards: "leaves", // roots, leaves
      },
    },
    edges: {
      color: "#000000",
    },
    height: "700px",
    physics: {
      enabled: true,
      barnesHut: {
        theta: 0.5,
        gravitationalConstant: -2000,
        centralGravity: 0.3,
        springLength: 110000,
        springConstant: 0.04,
        damping: 0.09,
        avoidOverlap: 0,
      },
      forceAtlas2Based: {
        theta: 0.5,
        gravitationalConstant: -50,
        centralGravity: 0.01,
        springConstant: 0.08,
        springLength: 100,
        damping: 0.4,
        avoidOverlap: 0,
      },
      repulsion: {
        centralGravity: 0.2,
        springLength: 200,
        springConstant: 0.05,
        nodeDistance: 100,
        damping: 0.09,
      },
      hierarchicalRepulsion: {
        centralGravity: 0.0,
        springLength: 100,
        springConstant: 0.01,
        nodeDistance: 120,
        damping: 0.09,
        avoidOverlap: 0,
      },
      maxVelocity: 50,
      minVelocity: 0.1,
      solver: "barnesHut",
      stabilization: {
        enabled: true,
        iterations: 1000,
        updateInterval: 100,
        onlyDynamicEdges: false,
        fit: true,
      },
      timestep: 0.5,
      adaptiveTimestep: true,
      wind: { x: 0, y: 0 },
    },
  };

  const events = {
    select: function (event) {
      var { nodes, edges } = event;
    },
  };

  return (
    <div>
      <h1 className="page-header">ORGANIZATION</h1>
      <Row style={{ height: "85vh" }}>
        <div className="col-md-9" ref={colRef} style={{ height: "100%" }}>
          {newGraph && (
            <Graph
              graph={newGraph}
              options={options}
              events={events}
              getNetwork={(network) => {
                //  if you want access to vis.js network api you can set the state in a parent component using this property
              }}
            />
          )}
          {/* {canvasSize && details !== null && (
            <ForceGraph2D
              ref={fgRef}
              width={canvasSize.width}
              height={canvasSize.height}
              graphData={{
                nodes: details?.nodes,
                links: details?.links,
              }}
              cooldownTicks={100}
              onEngineStop={() => fgRef.current.zoomToFit(canvasSize.width)}
              nodeAutoColorBy="group"
              nodeCanvasObject={(node, ctx, globalScale) => {
                console.log(node);
                console.log(ctx);
                const label = node.name;
                const fontSize = 12 / globalScale;
                ctx.font = `${fontSize}px Sans-Serif`;
                const textWidth = ctx.measureText(label).width;
                const bckgDimensions = [textWidth, fontSize].map(
                  (n) => n + fontSize * 0.2
                ); // some padding

                ctx.fillStyle = "rgba(255, 255, 255, 0.8)";
                ctx.fillRect(
                  node.x - bckgDimensions[0] / 2,
                  node.y - bckgDimensions[1] / 2,
                  ...bckgDimensions
                );

                ctx.textAlign = "center";
                ctx.textBaseline = "middle";
                ctx.fillStyle = "#000000";
                if (node.index == 0) {
                  ctx.fillStyle = "#000000";
                  const bckgDimensions = node.__bckgDimensions;
                  bckgDimensions &&
                    ctx.fillRect(
                      node.x - bckgDimensions[0] / 2,
                      node.y - bckgDimensions[1] / 2,
                      ...bckgDimensions
                    );
                }
                ctx.fillText(label, node.x, node.y);

                node.__bckgDimensions = bckgDimensions; // to re-use in nodePointerAreaPaint
              }}
              nodePointerAreaPaint={(node, color, ctx) => {
                ctx.fillStyle = color;
                console.log(color);
                const bckgDimensions = node.__bckgDimensions;
                bckgDimensions &&
                  ctx.fillRect(
                    node.x - bckgDimensions[0] / 2,
                    node.y - bckgDimensions[1] / 2,
                    ...bckgDimensions
                  );
              }}
            />
          )} */}
        </div>
        <Col md={3} style={{ paddingLeft: 40, paddingRight: 40 }}>
          <div className="hierarchy-circular-container">
            <CircularProgressbarWithChildren
              value={details?.statisticsPercentage?.staffs ?? 0}
              strokeWidth={5}
              styles={buildStyles({
                pathColor: "#009933",
                trailColor: "#a6a6a6",
              })}
            >
              <div style={{ width: "86%" }}>
                <CircularProgressbarWithChildren
                  value={details?.statisticsPercentage?.workers ?? 0}
                  strokeWidth={5}
                  styles={buildStyles({
                    pathColor: "#0066cc",
                    trailColor: "#a6a6a6",
                  })}
                >
                  <div style={{ fontSize: 50 }}>
                    {details?.totalWorkers ?? 0}
                  </div>
                </CircularProgressbarWithChildren>
              </div>
            </CircularProgressbarWithChildren>
            <div className="label-row">
              <div className="label-item green">Staff</div>
              <div className="label-item blue">Worker</div>
            </div>
          </div>
          <div
            className="statistic-container m-t-80"
            style={{ marginLeft: "-50px" }}
          >
            <div className="statistic-row">
              <FaPlaneArrival size={26} />
              <div
                className="statistic-progress-container"
                style={{
                  width: "200px",
                }}
              >
                <div className="statistic-progress-label orange">Local</div>
                <div className="statistic-progress-bar orange"></div>
              </div>
              <div className="statistic-value">
                {details?.statisticsPercentage?.local ?? 0}%
              </div>
            </div>
          </div>
          <div
            className="statistic-container m-t-10"
            style={{ marginLeft: "-50px" }}
          >
            <div className="statistic-row">
              <FaPlaneDeparture size={26} />
              <div
                className="statistic-progress-container"
                style={{
                  width: "200px",
                }}
              >
                <div className="statistic-progress-label purple">Foreign</div>
                <div className="statistic-progress-bar purple"></div>
              </div>
              <div className="statistic-value">
                {details?.statisticsPercentage?.foreign ?? 0}%
              </div>
            </div>
          </div>
          <div
            className="statistic-container m-t-80"
            style={{ marginLeft: "-50px" }}
          >
            <div className="statistic-row">
              <FaMale size={26} />
              <ProgressBar
                completed={details?.statisticsPercentage?.male ?? 0}
                width={"200px"}
                maxCompleted={100}
                bgColor={"blue"}
                baseBgColor={"rgb(129 147 218)"}
              />
              <div className="statistic-value">Male</div>
            </div>
          </div>
          <div
            className="statistic-container m-t-10"
            style={{ marginLeft: "-50px" }}
          >
            <div className="statistic-row">
              <FaFemale size={26} />
              <ProgressBar
                completed={details?.statisticsPercentage?.female ?? 0}
                width={"200px"}
                maxCompleted={100}
                bgColor={"pink"}
                baseBgColor={"rgb(129 147 218)"}
              />
              <div className="statistic-value">Female</div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default OrganizationChart;
