import React, { useState, useEffect } from "react";
import { Tab, Tabs, Card, CardContent } from "@mui/material";
import TabPanel, { a11yTabProps } from "components/panel/TabPanel";
import ActivityLogDao from "data/ActivitylogDao";
import VerticalTimeline from "components/VerticalTimeline";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Notiflix from "notiflix";
import { Config, ConfigEnum } from "util/Config";
import CommonDao from "data/CommonDao.js";
import DepartmentDao from "data/DepartmentDao";
import UserDao from "data/UserDao.js";
import moment from "moment";
import { useForm } from "react-hook-form";

import {
  FaUserTie,
  FaUserAlt,
  FaKey,
  FaPlane,
  FaNetworkWired,
  FaSuitcase,
  FaSitemap,
  FaBuilding,
  FaPhone,
  FaMobile,
  FaEnvelope,
  FaAddressCard,
  FaUserLock,
  FaUserShield,
  FaUserTag,
  FaTransgender,
  FaShieldAlt,
  FaBirthdayCake,
} from "react-icons/fa";

import CustomInput from "components/form/CustomInput";
import CustomSelect from "components/form/CustomSelect";
import CustomDatePicker from "components/form/CustomDatePicker";
import { FileUpload } from "components/form";
import PositionDao from "data/PositionDao";
import WarehouseDao from "data/WarehouseDao";
import NoImagePlaceholder from "assets/img/user/No-Image-Placeholder.svg.png";
import { Can } from "config/user-access";
import { ApiKey, AppFeatureKeys, Permission, HostKey } from "util/Constant";

const UserForm = ({
  toggle,
  user,
  onClosed,
  isOpen,
  onOpened,
  successCallback,
  isNewUser,
}) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({});
  let configInstance = Config.getInstance();
  var token = configInstance.getValue(ConfigEnum._TOKEN);
  const [isEditing, setIsEditing] = useState(false);
  const [departmentList, setDepartmentList] = useState([]);
  const [positionList, setPositionList] = useState([]);
  const [warehouseList, setWarehouseList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [subordinateOptions, setSubordinateOptions] = useState([]);
  const [image, setImage] = useState();
  const [submitDisable, setSubmitDisable] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [systemLogs, setSystemLogs] = useState([]);
  const [eventLogs, setEventLogs] = useState([]);
  const [systemQuery, setSystemQuery] = useState();
  const [eventQuery, setEventQuery] = useState();

  useEffect(() => {
    setIsEditing(false);
    console.log(user);
  }, [user]);

  useEffect(() => {
    getDepartmentList();
    getPositionList();
    getWarehouseList();
    getAllUserList();
    getRoleList();
  }, [isOpen]);

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  const _GENDER_OPTIONS = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
  ];

  const _ACCOUNT_TYPE_OPTIONS = [
    { label: "Admin", value: "super admin" },
    { label: "Staff", value: "Staff" },
    { label: "Worker", value: "Worker" },
    { label: "Subcon", value: "Subcon" },
    { label: "Service Agent", value: "Service Agent" },
  ];

  const _STATUS_OPTIONS = [
    { label: "Active", value: 1 },
    { label: "Suspended", value: 0 },
  ];

  const _CITIZENSHIP_OPTIONS = [
    { label: "Local", value: "Local" },
    { label: "Foreign", value: "Foreign" },
  ];

  const _SYSTEM_LOG_FILTER = [
    { label: "Last 7 Days", value: "7" },
    { label: "Last 30 Days", value: "30" },
    { label: "Last 60 Days", value: "60" },
    { label: "This Year", value: "This Year" },
  ];

  const onSubmit = async (data) => {
    setSubmitDisable(true);
    const formData = new FormData();
    console.log(data);
    formData.append("firstName", data.firstName);
    formData.append("lastName", data.lastName);
    formData.append("username", data.username);
    formData.append("password", data.password);
    formData.append("confirmPassword", data.password);
    formData.append("gender", data.gender);
    formData.append("dob", moment(data.birthday).format("YYYY-MM-DD"));
    formData.append("phoneNumber", data.phoneNumber);
    formData.append("housePhone", data.housePhone);
    formData.append("email", data.email);
    formData.append("employeeNumber", data.employeeNumber);
    formData.append("status", data.status);
    formData.append("icNumber", data.icNumber);
    formData.append("jobPosition", data.jobPosition);
    formData.append("departmentName", data.departmentName);
    formData.append("building", data.building);
    formData.append("accountType", data.accountType);
    formData.append("citizen", data.citizen);
    formData.append("subordinateTo", data.subordinateTo);
    formData.append("role", data.policy);

    if (Array.isArray(data.photo)) {
      formData.append(`photo`, data.photo[0]);
    }

    if (user) {
      formData.append("id", user.id);
    }

    let action = "";
    let dao = new UserDao();

    if (user) {
      action = dao.updateUser(formData, token);
    } else {
      action = dao.register(formData, token);
    }

    await action.then((responseJson) => {
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        var message = user ? "Update Successful" : "Registration Successful";
        Notiflix.Report.success("Success", message, "Okay", successCallback);
      } else {
        setSubmitDisable(false);
        Notiflix.Report.failure(
          "Error",
          "Registration Failed. " + responseJson[ApiKey._API_MESSAGE_KEY]
        );
      }
    });
  };

  const getDepartmentList = async () => {
    let dao = new DepartmentDao();
    await dao.getDepartmentListJson().then((response) => {
      if (response[ApiKey._API_SUCCESS_KEY]) {
        var data = response[ApiKey._API_DATA_KEY];
        var selectOptions = [];

        if(data !== null) {
          var arr = data.split(",");
          selectOptions = arr.map((item) => ({
            label: item,
            value: item,
          }));
          setDepartmentList(selectOptions);
        }        
      }
    });
  };

  const getAllUserList = async () => {
    let dao = new UserDao();
    await dao.getAllUserList(token).then((response) => {
      if (response[ApiKey._API_SUCCESS_KEY]) {
        var data = response[ApiKey._API_DATA_KEY];
        var selectOptions = [];

        selectOptions = data.map((item) => {
          var option = {
            label: `${item.lastName}, ${item.firstName}`,
            value: item.id,
          };
          return option;
        });

        setSubordinateOptions(selectOptions);
      }
    });
  };

  const getEventLog = async () => {
    let dao = new ActivityLogDao();
    var obj = {
      pageIndex: 1,
      pageSize: 10,
      systemQuery: eventQuery ? eventQuery : 7,
      userId: user?.id,
    };

    await dao.getEventLog(token, obj).then((response) => {
      if (response[ApiKey._API_SUCCESS_KEY]) {
        var data = response[ApiKey._API_DATA_KEY];
        let arr = [];
        for (var i = 0; i < data.length; i++) {
          let formatArray = [];
          for (var j = 0; j < data[i].length; j++) {
            if (data[i][j].portal == "NEUP") {
              var obj = {
                date: data[i][j].datetime,
                description: data[i][j].title,
                title: data[i][j].portal,
                theme: {
                  title: "black",
                  main: "purple",
                },
              };
              formatArray.push(obj);
            } else if (data[i][j].portal == "NECS") {
              var obj = {
                date: data[i][j].datetime,
                description: data[i][j].title,
                title: data[i][j].portal,
                theme: {
                  title: "pink",
                  main: "purple",
                },
              };
              formatArray.push(obj);
            } else if (data[i][j].portal == "NEPS") {
              var obj = {
                date: data[i][j].datetime,
                description: data[i][j].title,
                title: data[i][j].portal,
                theme: {
                  title: "blue",
                  main: "purple",
                },
              };
              formatArray.push(obj);
            } else if (data[i][j].portal == "NESM") {
              var obj = {
                date: data[i][j].datetime,
                description: data[i][j].title,
                title: data[i][j].portal,
                theme: {
                  title: "green",
                  main: "purple",
                },
              };
              formatArray.push(obj);
            }
          }
          arr.push(formatArray);
        }
        setEventLogs(arr);
      } else {
        Notiflix.Report.failure(
          "Error",
          "Failed to load users. Please try again later"
        );
      }
    });
  };

  const getPositionList = async () => {
    let dao = new PositionDao();
    await dao.getPositionListJson().then((response) => {
      if (response[ApiKey._API_SUCCESS_KEY]) {
        var data = response[ApiKey._API_DATA_KEY];

        if(data !== null) {
          var arr = data.split(",");
          var selectOptions = [];
          selectOptions = arr.map((item) => ({
            label: item,
            value: item,
          }));
          setPositionList(selectOptions);
        }
      }
    });
  };

  const getWarehouseList = async () => {
    let dao = new WarehouseDao();
    await dao.getBuildingListJson().then((response) => {
      if (response[ApiKey._API_SUCCESS_KEY]) {
        var data = response[ApiKey._API_DATA_KEY];

        if(data !== null) {
            var arr = data.split(",");
            var selectOptions = [];
            selectOptions = arr.map((item) => ({
              label: item,
              value: item,
            }));
            setWarehouseList(selectOptions);
        }
      }
    });
  };

  const getRoleList = async () => {
    let dao = new CommonDao();
    await dao.getRoleList(token).then((response) => {
      if (response[ApiKey._API_SUCCESS_KEY]) {
        var data = response[ApiKey._API_DATA_KEY];
        var selectOptions = [];
        selectOptions = data.map((item) => ({
          label: item.roleName,
          value: item.id,
        }));
        setRoleList(selectOptions);
      }
    });
  };

  const getSystemLog = async () => {
    let dao = new ActivityLogDao();
    var obj = {
      pageIndex: 1,
      pageSize: 10,
      systemQuery: systemQuery ? systemQuery : 7,
      userId: user?.id,
    };

    await dao.getActivityLog(token, obj).then((response) => {
      if (response[ApiKey._API_SUCCESS_KEY]) {
        var data = response[ApiKey._API_DATA_KEY];
        let arr = [];
        for (var i = 0; i < data.length; i++) {
          let formatArray = [];
          for (var j = 0; j < data[i].length; j++) {
            if (data[i][j].portal == "NEUP") {
              var obj = {
                date: data[i][j].datetime,
                description: data[i][j].title,
                title: data[i][j].portal,
                theme: {
                  title: "black",
                  main: "orange",
                },
              };
              formatArray.push(obj);
            } else if (data[i][j].portal == "NECS") {
              var obj = {
                date: data[i][j].datetime,
                description: data[i][j].title,
                title: data[i][j].portal,
                theme: {
                  title: "pink",
                  main: "orange",
                },
              };
              formatArray.push(obj);
            } else if (data[i][j].portal == "NEPS") {
              var obj = {
                date: data[i][j].datetime,
                description: data[i][j].title,
                title: data[i][j].portal,
                theme: {
                  title: "blue",
                  main: "orange",
                },
              };
              formatArray.push(obj);
            } else if (data[i][j].portal == "NESM") {
              var obj = {
                date: data[i][j].datetime,
                description: data[i][j].title,
                title: data[i][j].portal,
                theme: {
                  title: "green",
                  main: "purple",
                },
              };
              formatArray.push(obj);
            }
          }
          arr.push(formatArray);
        }
        setSystemLogs(arr);
      } else {
        Notiflix.Report.failure(
          "Error",
          "Failed to load users. Please try again later"
        );
      }
    });
  };

  useEffect(() => {
    if (
      user &&
      departmentList.length > 0 &&
      positionList.length > 0 &&
      warehouseList.length > 0
    ) {
      reset({
        ...user,
      });

      getSystemLog();
      getEventLog();
    }
  }, [user, departmentList, positionList, warehouseList]);

  useEffect(() => {
    if (systemQuery) {
      getSystemLog();
    }
  }, [systemQuery]);

  useEffect(() => {
    if (eventQuery) {
      getEventLog();
    }
  }, [eventQuery]);

  return (
    <Modal size="lg" {...{ isOpen, toggle, onClosed, onOpened }} centered>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader className="modal-themed" toggle={toggle}>
          <h2 style={{ marginBottom: 0 }}>
            {user ? "MANAGEMENT / Edit User" : "MANAGEMENT /  New User"}
          </h2>
        </ModalHeader>

        <ModalBody className="scroll-component">
          <Tabs
            variant="scrollable"
            value={tabValue}
            onChange={(e, value) => {
              setTabValue(value);
            }}
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              marginBottom: "1rem",
            }}
          >
            <Tab label={"Profile"} {...a11yTabProps(0)} />
            <Tab label={"Event"} {...a11yTabProps(1)} />
          </Tabs>
          <TabPanel value={tabValue} index={0}>
            {isEditing === false && user && (
              <Can I={Permission.EDIT} this={AppFeatureKeys._USERS}>
                <button
                  type="button"
                  onClick={() => {
                    setIsEditing(true);
                  }}
                  className="btn btn-themed pull-right"
                >
                  <i className="fa fa-edit"></i>
                </button>
              </Can>
            )}
            {isEditing === true && (
              <button
                type="button"
                onClick={() => {
                  setIsEditing(false);
                }}
                className="btn btn-themed pull-right"
              >
                <i className="fa fa-door-open"></i> Exit Edit Mode
              </button>
            )}
            <div style={{ fontSize: 16, fontWeight: "bold", marginBottom: 10 }}>
              Personal Details
            </div>
            <Row className="m-b-10">
              <Col xs={4}>
                <CustomInput
                  {...register("firstName", {
                    required: "First Name is required",
                  })}
                  name="firstName"
                  label="First Name *"
                  icon={<FaUserTie />}
                  defaultValue={user?.firstName}
                  readOnly={user ? !isEditing : isEditing}
                  error={errors?.firstName?.message}
                  showLabel
                />
              </Col>
              <Col xs={4}>
                <CustomInput
                  {...register("lastName", {
                    required: "Last Name is required",
                  })}
                  name="lastName"
                  label="Last Name *"
                  defaultValue={user?.lastName}
                  icon={<FaUserTie />}
                  readOnly={user ? !isEditing : isEditing}
                  error={errors?.lastName?.message}
                  showLabel
                />
              </Col>
              <Col xs={4}>
                <label className="custom-input-label">Citizenship *</label>
                <CustomSelect
                  control={control}
                  name="citizen"
                  label="Citizenship *"
                  placeholder="Citizenship *"
                  defaultValue={user?.citizen}
                  icon={<FaPlane />}
                  options={_CITIZENSHIP_OPTIONS}
                  readOnly={user ? !isEditing : isEditing}
                  showLabel
                  rules={{
                    required: true,
                  }}
                  error={errors?.citizen && "Citizen is required"}
                  errors={errors?.citizen && "Citizen is required"}
                />
              </Col>
            </Row>
            <Row className="m-b-10">
              <Col xs={4}>
                <CustomInput
                  {...register("icNumber", {
                    required: "IC Number is required",
                  })}
                  name="icNumber"
                  label="IC Number *"
                  defaultValue={user?.icNumber}
                  icon={<FaAddressCard />}
                  readOnly={user ? !isEditing : isEditing}
                  error={errors?.icNumber?.message}
                  showLabel
                />
              </Col>
              <Col xs={4}>
                <label className="custom-input-label">Birthday</label>
                <CustomDatePicker
                  control={control}
                  {...register("dob", false)}
                  name="dob"
                  label="Birthday"
                  defaultValue={user?.dob}
                  value={user?.dob}
                  icon={<FaBirthdayCake />}
                  readOnly={user ? !isEditing : isEditing}
                />
              </Col>
              <Col xs={4}>
                <label className="custom-input-label">Gender</label>
                <CustomSelect
                  control={control}
                  name="gender"
                  placeholder="Gender"
                  defaultValue={user?.gender}
                  icon={<FaTransgender />}
                  options={_GENDER_OPTIONS}
                  readOnly={user ? !isEditing : isEditing}
                  error={errors?.gender && "Gender is required"}
                  errors={errors?.gender && "Gender is required"}
                />
              </Col>
            </Row>
            <Row className="m-b-10">
              <Col xs={4}>
                <CustomInput
                  {...register("phoneNumber", {
                    required: "Phone Number is required",
                  })}
                  name="phoneNumber"
                  label="Mobile Phone *"
                  icon={<FaMobile />}
                  defaultValue={user?.phone}
                  readOnly={user ? !isEditing : isEditing}
                  error={errors?.phoneNumber?.message}
                  showLabel
                />
              </Col>
              <Col xs={4}>
                <CustomInput
                  {...register("housePhone", false)}
                  name="housePhone"
                  label="House Phone"
                  defaultValue={user?.housePhone}
                  icon={<FaPhone />}
                  readOnly={user ? !isEditing : isEditing}
                  showLabel
                />
              </Col>
              <Col xs={4}>
                <CustomInput
                  {...register("email", {
                    required: "Email is required",
                  })}
                  name="email"
                  label="Email *"
                  defaultValue={user?.email}
                  icon={<FaEnvelope />}
                  readOnly={user ? !isEditing : isEditing}
                  error={errors?.email?.message}
                  showLabel
                />
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                <label className="custom-input-label">Position *</label>
                <CustomSelect
                  control={control}
                  name="jobPosition"
                  label="Position"
                  placeholder="Position *"
                  defaultValue={user?.jobPosition}
                  icon={<FaSuitcase />}
                  options={positionList}
                  rules={{
                    required: true,
                  }}
                  readOnly={user ? !isEditing : isEditing}
                  error={errors?.jobPosition && "Job Position is required"}
                  errors={errors?.jobPosition && "Job Position is required"}
                />
              </Col>
              <Col xs={4}>
                <label className="custom-input-label">Department *</label>
                <CustomSelect
                  control={control}
                  name="departmentName"
                  label="Department"
                  placeholder="Department *"
                  icon={<FaSitemap />}
                  options={departmentList}
                  defaultValue={user?.departmentName}
                  readOnly={user ? !isEditing : isEditing}
                  rules={{
                    required: true,
                  }}
                  error={errors?.departmentName && "Department is required"}
                  errors={errors?.departmentName && "Department is required"}
                />
              </Col>
              <Col xs={4}>
                <label className="custom-input-label">Building *</label>
                <CustomSelect
                  control={control}
                  name="building"
                  label="Building"
                  defaultValue={user?.building}
                  placeholder="Building *"
                  icon={<FaBuilding />}
                  options={warehouseList}
                  rules={{
                    required: true,
                  }}
                  readOnly={user ? !isEditing : isEditing}
                  error={errors?.building && "Building is required"}
                  errors={errors?.building && "Building is required"}
                />
              </Col>
            </Row>
            <div
              style={{
                fontSize: 16,
                fontWeight: "bold",
                marginBottom: 10,
                marginTop: 30,
              }}
            >
              User Details
            </div>
            <Row>
              <Col xs={12}>
                <Row className="m-b-10">
                  <Col xs={4}>
                    <CustomInput
                      {...register("username", {
                        required: "Username is required",
                      })}
                      name="username"
                      label="Username *"
                      icon={<FaUserAlt />}
                      defaultValue={user?.username}
                      readOnly={user ? !isEditing : isEditing}
                      error={errors?.username?.message}
                      showLabel
                    />
                  </Col>
                  {!user && (
                    <Col xs={4}>
                      <CustomInput
                        {...register("password", {
                          required: "Password is required",
                        })}
                        name="password"
                        label="Password *"
                        icon={<FaKey />}
                        readOnly={user ? !isEditing : isEditing}
                        type="password"
                        error={errors?.password?.message}
                        showLabel
                      />
                    </Col>
                  )}

                  <Col xs={4}>
                    <label className="custom-input-label">
                      Subordinate To *
                    </label>
                    <CustomSelect
                      control={control}
                      name="subordinateTo"
                      label="Subordinate To"
                      placeholder="Subordinate To *"
                      defaultValue={user?.subordinateTo}
                      icon={<FaNetworkWired />}
                      options={subordinateOptions}
                      readOnly={user ? !isEditing : isEditing}
                      rules={{
                        required: true,
                      }}
                      error={errors?.subordinateTo && "Subordinate is required"}
                      errors={
                        errors?.subordinateTo && "Subordinate is required"
                      }
                      showLabel
                    />
                  </Col>
                </Row>
                <Row className="m-b-10">
                  <Col xs={4}>
                    <CustomInput
                      {...register("employeeNumber", {
                        required: "Employee Number is required",
                      })}
                      name="employeeNumber"
                      label="Employee Number *"
                      defaultValue={user?.employeeNumber}
                      icon={<FaUserTag />}
                      readOnly={user ? !isEditing : isEditing}
                      error={errors?.employeeNumber?.message}
                      showLabel
                    />
                  </Col>
                  <Col xs={4}>
                    <label className="custom-input-label">
                      Access Status *
                    </label>
                    <CustomSelect
                      control={control}
                      name="status"
                      label="Access Status"
                      placeholder="Access Status *"
                      defaultValue={user?.status}
                      icon={<FaUserLock />}
                      options={_STATUS_OPTIONS}
                      rules={{
                        required: true,
                      }}
                      readOnly={user ? !isEditing : isEditing}
                      error={errors?.status && "Status is required"}
                      errors={errors?.status && "Status is required"}
                    />
                  </Col>
                  <Col xs={4}>
                    <label className="custom-input-label">Account Type *</label>
                    <CustomSelect
                      control={control}
                      name="accountType"
                      label="Account Type"
                      placeholder="Account Type *"
                      defaultValue={user?.accountType}
                      icon={<FaUserShield />}
                      options={_ACCOUNT_TYPE_OPTIONS}
                      rules={{
                        required: true,
                      }}
                      readOnly={user ? !isEditing : isEditing}
                      error={errors?.accountType && "Account Type is required"}
                      errors={errors?.accountType && "Account Type is required"}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <label className="custom-input-label">Profile Image</label>
                {!isEditing && user ? (
                  <div className="user-img__container">
                    <img
                      src={
                        user?.photo
                          ? HostKey.getApiHost() + user?.photo
                          : NoImagePlaceholder
                      }
                    />
                  </div>
                ) : (
                  [
                    <FileUpload
                      control={control}
                      name="photo"
                      setImage={setImage}
                    />,
                  ]
                )}
              </Col>
            </Row>
            <div
              style={{
                fontSize: 16,
                fontWeight: "bold",
                marginBottom: 10,
                marginTop: 30,
              }}
            >
              Policy
            </div>
            <Row>
              <Col xs={4}>
                <CustomSelect
                  control={control}
                  name="policy"
                  label="Policy"
                  placeholder="Policy *"
                  defaultValue={user?.roleId}
                  icon={<FaShieldAlt />}
                  options={roleList}
                  rules={{
                    required: true,
                  }}
                  readOnly={user ? !isEditing : isEditing}
                  error={errors?.policy && "Policy is required"}
                  errors={errors?.policy && "Policy is required"}
                />
              </Col>
            </Row>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <Row>
              <Col xs={4}>
                <div
                  className="activities-title"
                  style={{ display: "inline-block" }}
                >
                  System Log
                </div>
                <CustomSelect
                  control={control}
                  name="Filter"
                  placeholder="Filter"
                  onChange={(data) => {
                    setSystemQuery(data);
                  }}
                  options={_SYSTEM_LOG_FILTER}
                  styles={{ display: "inline-block" }}
                />
                {systemLogs.length > 0 && (
                  <VerticalTimeline items={systemLogs} />
                )}
              </Col>
              <Col xs={8}>
                <div
                  className="activities-title"
                  style={{ display: "inline-block" }}
                >
                  Task Event
                </div>
                <Col xs={6}>
                  <CustomSelect
                    control={control}
                    name="Filter"
                    placeholder="Filter"
                    onChange={(data) => {
                      setEventQuery(data);
                    }}
                    options={_SYSTEM_LOG_FILTER}
                  />
                </Col>

                {eventLogs.length > 0 && <VerticalTimeline items={eventLogs} />}
              </Col>
            </Row>
          </TabPanel>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-red mr-auto btn-min-width"
            onClick={toggle}
          >
            Cancel
          </button>
          {isEditing && user && (
            <button
              type="submit"
              className="btn btn-themed btn-min-width pull-right mt-2"
            >
              Submit Changes
            </button>
          )}
          {isNewUser && (
            <button
              disabled={submitDisable}
              type="submit"
              className="btn btn-themed btn-min-width pull-right mt-2"
            >
              Submit User
            </button>
          )}
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default UserForm;
