import React, { useMemo, useState, useRef, useEffect } from "react";
import ReactTable from 'components/react-table/ReactTable';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Notiflix from "notiflix";
import { ApiKey } from "util/Constant";
import FormBuilder, { submitForm } from "components/form/FormBuilder";
import { Config, ConfigEnum } from 'util/Config';
import PositionDao from "data/PositionDao.js";
import { Block } from 'notiflix';

///<summary>
///Author: Nicholas
///</summary>
const PositionManagement = props => {
    const [modal, setModal] = useState(false);
    const [position, setPosition] = useState();


    const [positionList, setPositionList] = useState([]);

    const formRef = useRef();
    console.log()
    const toggleModal = () => {
        setModal(!modal);
    }

    let configInstance = Config.getInstance();
    var token = configInstance.getValue(ConfigEnum._TOKEN);

    useEffect(() => {

        getPositionList();
    }, []);


    const setSelectedPosition = (row) => {
        console.log(row);
        setPosition({
            id: row.id,
            name: row.positionName,
            description: row.positionDescription
        });
        console.log(setPosition);
    }

    const getPositionList = async () => {
        Block.circle('#positionsTable');

        let dao = new PositionDao();
        await dao.getPositionList(token).then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                var data = response[ApiKey._API_DATA_KEY];
                var positions = [];
                data.map((item) => {
                    var position = {
                        id: item.id,
                        positionName: item.name,
                        positionDescription: item.description
                    }
                    positions.push(position);
                })
                setPositionList(positions);
            } else {
                Notiflix.Report.Failure('Error', 'Failed to load positions. Please try again later');
            }
        })
    }


    const _FIELDS = [
        {
            rowOptions: { xl: 1 },

            columns: [
                { label: 'Position Name *', name: 'positionName', rules: { required: 'Position Name is required.' }, columnOptions: { xl: 12 }, defaultValue: position && position.name },
                { label: 'Position Description*', name: 'positionDescription', rules: { required: 'Position Description is required.' }, columnOptions: { xl: 12 }, defaultValue: position && position.description }

            ]
        }
    ];

    const deletePosition = async (row) => {
        var id = row.id;
        let dao = new PositionDao();

        await dao.delete(id, token).then(responseJson => {
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                Notiflix.Report.Success('Success', "Position Deleted");
                getPositionList();
            } else {
                Notiflix.Report.Failure('Error', 'Failed to delete position. ' + responseJson[ApiKey._API_MESSAGE_KEY]);
            }
        })
    }

    const _COLUMN = useMemo(() => [
        {
            Header: "Position Name",
            accessor: "positionName"
        },
        {
            Header: "Position Description",
            accessor: "positionDescription"
        },
        {
            Header: "Action",
            Cell: ({ row }) => (<>
                <div className="btn-round expand-theme btn-sm" onClick={() => { toggle(); setSelectedPosition(row.original) }}><i className="fa fa-edit"></i></div>
                <div className="btn-round expand-theme btn-sm m-l-5"
                    onClick={() => {
                        Notiflix.Confirm.Show('Confirmation', 'Do you sure to delete position?', 'Yes', 'No',
                            function () {
                                deletePosition(row.original);
                            }
                        )
                    }}><i className="fa fa-trash"></i></div>
            </>
            ),
            disableSortBy: true,
            disableFilters: true,
            style: { overflow: "visible" }
        }
    ])



    ///<summary>
    ///Author: Nicholas Lai
    ///</summary>
    const toggle = () => { setModal(!modal); setPosition(null) };

    ///<summary>
    ///Author: Nicholas Lai
    ///</summary>
    const onSubmit = async (data) => {
        let positionData = {
            "name": data.positionName,
            "description": data.positionDescription

        };

        if (position) {
            positionData["id"] = position.id;
        }

        let action = '';
        let dao = new PositionDao();

        if (positionData.id) {
            action = dao.updatePosition(positionData, token);
        } else {
            action = dao.register(positionData, token);
        }

        await action.then(responseJson => {
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                var message = positionData.id ? "Update Successful" : "Position Added Successfully";
                Notiflix.Report.Success('Success', message);
                getPositionList();
                toggle();
            } else {
                Notiflix.Report.Failure('Error', 'Registration Failed. ' + responseJson[ApiKey._API_MESSAGE_KEY]);
            }
        })
    }

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    useEffect(() => {
        if (positionList.length > 0) {
            Block.remove('#positionsTable');
        }
    }, [positionList]);

    return (<>
        <div className="float-xl-right">
            <button type="type" className="btn btn-sm btn-themed mb-2" onClick={toggle} ><i className="fa fa-plus mr-1"></i>Add Position</button>
        </div>
        <h1 className="page-header">Position Management</h1>
        <div id="positionsTable">
            <ReactTable columns={_COLUMN} data={positionList && positionList} />
        </div>
        <Modal isOpen={modal} toggle={toggle} centered>
            <ModalHeader toggle={toggleModal}>{position ? "Edit Position" : "Add New Position"}</ModalHeader>
            <ModalBody>
                <FormBuilder fields={_FIELDS} onSubmit={onSubmit} formRef={formRef} />
            </ModalBody>
            <ModalFooter>
                <button type="button" className="btn btn-red" onClick={toggle}>Cancel</button>
                <button type="button" className="btn btn-themed" onClick={() => { submitForm(formRef) }}>
                    Save
                </button>
            </ModalFooter>
        </Modal>
    </>)
}

export default PositionManagement;
