import React, {
  useMemo,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import ReactTable from "components/react-table/ReactTable";
import Notiflix from "notiflix";
import { Config, ConfigEnum } from "util/Config";
import ServiceAgentDao from "data/ServiceAgentDao.js";
import AddServiceAgentModel from "./AddServiceAgentModel";
import { Block } from "notiflix";
import { tryCatch } from "util/Utility";
import { handleSetPageOptions } from "util/Utility.js";
import { Can } from "config/user-access";
import { ApiKey, AppFeatureKeys, Permission } from "util/Constant";

/// <summary>
/// Author: Christopher Chan
/// </summary>
export default () => {
  const [showModal, setShowModal] = useState(false);
  const [agent, setAgent] = useState(null);
  const [serviceAgentsList, setServiceAgentsList] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isNewAgent, setIsNewAgent] = useState();
  const [listQueryParams, setListQueryParams] = useState();
  let dao = useMemo(() => new ServiceAgentDao(), []);
  const _tableRef = useRef();
  let configInstance = Config.getInstance();
  var token = configInstance.getValue(ConfigEnum._TOKEN);

  const getServiceAgentsList = useCallback(
    async (params) => {
      Block.circle("#usersTable");

      try {
        let dao = new ServiceAgentDao();

        await dao.getServiceAgentsList(token, params).then((response) => {
          if (response[ApiKey._API_SUCCESS_KEY]) {
            var data = response[ApiKey._API_DATA_KEY];
            console.log(data);
            setServiceAgentsList(data);
            handleSetPageOptions(
              _tableRef,
              response[ApiKey._API_TOTAL_KEY] ?? 0
            );
          } else {
            Notiflix.Report.failure(
              "Error",
              "Failed to load service agents. Please try again later"
            );
          }
        });
      } catch (error) {
        console.log(error);
      }

      Block.remove("#usersTable");
    },
    [token, dao]
  );

  /// <summary>
  /// Author: Christopher Chan
  /// </summary>
  const deleteAgentHandler = (id) => {
    tryCatch(async () => {
      let dao = new ServiceAgentDao();

      await dao.deleteServiceAgent(id, token).then((response) => {
        if (response[ApiKey._API_SUCCESS_KEY]) {
          Notiflix.Report.success(
            "Success",
            `Successfully deleted service agent.`,
            "Okay",
            getServiceAgentsList
          );
        } else {
          Notiflix.Report.failure(
            "Error",
            `Failed to delete service agent ${agent.firstName}. Please try again later`
          );
        }
      });
    });
  };

  /// <summary>
  /// Author: Christopher Chan
  /// </summary>
  const deleteAgentPrompt = (agent) => {
    Notiflix.Confirm.init({
      plainText: false,
      titleColor: "#FF0000",
      okButtonBackground: "#FF0000",
    });
    Notiflix.Confirm.show(
      "Delete service agent?",
      `Are you sure you would like to delete:- <b>${agent.firstName}<br/>(${agent.agentCompany})</b>`,
      "Delete",
      "No",
      () => deleteAgentHandler(agent.id)
    );
  };

  /// <summary>
  /// Author: Christopher Chan
  /// </summary>
  useEffect(() => {
    listQueryParams && getServiceAgentsList(listQueryParams);
  }, [getServiceAgentsList, listQueryParams]);

  const _COLUMN = useMemo(() => [
    {
      Header: "Name",
      Cell: ({ row }) => row.original.firstName + " " + row.original.lastName,
    },
    {
      Header: "Region",
      accessor: "region",
    },
    {
      Header: "Position",
      accessor: "jobPosition",
    },
    {
      Header: "Company",
      accessor: "agentCompany",
    },
    {
      Header: "Mobile Phone",
      accessor: "mobilePhone",
    },
    {
      Header: " ",
      Cell: ({ row }) => (
        <>
          <div
            className="btn-table"
            onClick={() => {
              toggleModal();
              setIsNewAgent(false);
              setIsEditing(false);
              setAgent(row.original);
            }}
          >
            <i className="fa fa-eye"></i>
          </div>
          <div
              className="btn-table last-btn"
              onClick={() => {
                deleteAgentPrompt(row.original)
              }}
            >
              <i className="fa fa-trash"></i>
            </div>
        </>
      ),
      disableSortBy: true,
      disableFilters: true,
      style: { overflow: "visible", textAlign: "right", width: "100px" },
      sticky: "right",
    },
  ]);

  /// <summary>
  /// Author: Christopher Chan
  /// </summary>
  const toggleModal = () => {
    setIsNewAgent(true);
    console.log(agent);
    setAgent(null);
    setShowModal((prev) => !prev);

    if (showModal) {
      setAgent(null);
    }
  };

  /// <summary>
  /// Author: Nicholas Lai
  /// </summary>
  const tableClickEvent = (data) => {
    setIsNewAgent(false);
    setAgent(data);
    setShowModal((prev) => !prev);
  };

  return (
    <>
      <div className="float-xl-right">
        <Can I={Permission.CREATE} this={AppFeatureKeys._SERVICE_AGENTS}>
          <button
            type="type"
            className="btn btn-sm btn-themed mb-2"
            onClick={() => {
              toggleModal();
            }}
          >
            <i className="fa fa-plus mr-1"></i>New Agent
          </button>
        </Can>
      </div>
      <h1 className="page-header">Service Agents Management</h1>
      <div id="usersTable">
        <ReactTable
          columns={_COLUMN}
          data={serviceAgentsList && serviceAgentsList}
          getRowProps={(row) => {
            return {
              onDoubleClick: () => tableClickEvent(row.original),
            };
          }}
          filterable
          canPreviousPage={true}
          canNextPage={true}
          ref={_tableRef}
          setQueryParams={setListQueryParams}
          disabledApiPagination={false}
        />
      </div>
      {showModal && (
        <AddServiceAgentModel
          agent={agent && agent}
          isOpen={showModal}
          toggleModal={toggleModal}
          getServiceAgentsList={getServiceAgentsList}
          isNewAgent={isNewAgent}
          onClosed={() => {
            setAgent(null);
          }}
          successCallback={() => {
            getServiceAgentsList();
            toggleModal();
          }}
          deleteAgentPrompt={deleteAgentPrompt}
        />
      )}
    </>
  );
};
