import React, { useState, useEffect } from "react";
import { Report, Block } from 'notiflix';
import _uniqueId from 'lodash/uniqueId';

import TaskDao from "data/TaskDao";
import { InputTypes, ApiKey } from "util/Constant";
import { Config, ConfigEnum } from 'util/Config';
import { tryCatch } from 'util/Utility';

import CreateOrUpdateGroup from "components/modals/CreateOrUpdateGroup";
import TaskGroup from './TaskGroup';
import CreateOrUpdateTask from 'components/modals/CreateOrUpdateTask';

/// <summary>
/// Author: Christopher Chan
/// </summary>
export default () => {
    const [groupModal, setGroupModal] = useState(false);
    const [taskModal, setTaskModal] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState({
        group: null,
        task: null
    });
    const [groups, setGroups] = useState([]);

    let configInstance = Config.getInstance();
    var token = configInstance.getValue(ConfigEnum._TOKEN);

    // /// <summary>
    // /// Author: Christopher Chan
    // /// </summary>
    const getTasks = () => {
        tryCatch(async () => {
            Block.circle('#groupList');

            let dao = new TaskDao();

            await dao.getTasks(token)
                .then(responseJson => {
                    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                        setGroups(responseJson[ApiKey._API_DATA_KEY]);
                    }
                    else {
                        Report.Failure('Error', 'Failed to retrieve tasks list. ' + responseJson[ApiKey._API_MESSAGE_KEY]);
                    }
                })
                .catch(error => {
                    Report.Failure('Error', 'Operation Failed. ' + error);
                });
        }, () => {
            Block.remove('#groupList');
        });
    }

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    const toggleGroupModal = () => {
        setGroupModal(!groupModal);
    }

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    const toggleTaskModal = (e, index = null, taskIndex = null) => {
        if (e !== null) {
            e.stopPropagation();
        }

        setSelectedIndex({
            group: index !== null ? index : null,
            task: taskIndex !== null ? taskIndex : null,
        });
    }

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    const removeGroup = (index) => {
        let temp = groups;
        temp.splice(index, 1);

        setGroups([...temp]);
    }

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    const removeTask = (groupIndex, taskIndex) => {
        let temp = groups;
        let tasks = temp[groupIndex].tasks;
        tasks.splice(taskIndex, 1);

        setGroups([...temp]);
    }

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    const onSubmit = () => {
        tryCatch(async () => {
            let dao = new TaskDao();

            await dao.createOrUpdateTasks(groups, token)
                .then(responseJson => {
                    console.log(responseJson);
                    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                        Report.Success('Success', 'Successfully updated tasks.', 'Okay');
                    }
                    else {
                        Report.Failure('Error', 'Failed to update tasks. ' + responseJson[ApiKey._API_MESSAGE_KEY]);
                    }
                })
                .catch(error => {
                    Report.Failure('Error', 'Operation Failed. ' + error);
                });
        });
    }

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    useEffect(() => {
        setTaskModal(selectedIndex?.group !== null);
    }, [selectedIndex]);

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    useEffect(() => {
        getTasks();
    }, [])

    return <>
        <div className="float-xl-right">
            <button type="button" className="btn btn-grey btn-min-width mr-3" onClick={toggleGroupModal}>
                <i className="fas fa-plus"></i> Add Group
            </button>
            <button type="button" className="btn btn-themed btn-min-width" onClick={onSubmit}>Save</button>
        </div>
        <h1 className="page-header">Tasks Management</h1>
        <div id="groupList">
            {
                groups.map((item, index) => (
                    <TaskGroup
                        key={item.uid}
                        item={item}
                        index={index}
                        remove={removeGroup}
                        toggle={toggleTaskModal}
                        removeTask={removeTask}
                    />
                ))
            }
        </div>
        <CreateOrUpdateGroup isOpen={groupModal} toggle={toggleGroupModal} setGroups={setGroups} />
        <CreateOrUpdateTask isOpen={taskModal} toggle={toggleTaskModal} selectedIndex={selectedIndex} groups={groups} setGroups={setGroups} />
    </>
}
