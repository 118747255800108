import React, { useState, useEffect, Fragment } from "react";
import { Modal, ModalHeader, Collapse, ModalFooter, Row, Col } from 'reactstrap';
import { Report, Block } from 'notiflix';
import { useForm} from "react-hook-form";
import _uniqueId from 'lodash/uniqueId';

import UserDao from "data/UserDao";
import { InputTypes, ApiKey } from "util/Constant";
import { Config, ConfigEnum } from 'util/Config';
import { tryCatch } from 'util/Utility';
import InputHoc from "components/form/InputHoc.js";

const _DURATION_SELECTOR = [
    { label: 'days', value: 'days' },
    { label: 'months', value: 'months' },
]

/// <summary>
/// Author: Christopher Chan
/// </summary>
const TaskDetails = ({ register, errors, control, setValue, priority }) => {
    const PRIORITY_TYPES = {
        LOW: { name: 'Low', value: 0, btnType: 'btn-green' },
        MEDIUM: { name: 'Medium', value: 1, btnType: 'btn-yellow' },
        HIGH: { name: 'High', value: 2, btnType: 'btn-orange' },
        URGENT: { name: 'Urgent', value: 3, btnType: 'btn-red' },
    }

    const [prioritySelection, setPrioritySelection] = useState(PRIORITY_TYPES.LOW.value);

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    const priorityHandler = (type) => {
        setPrioritySelection(type);
        setValue('priority', type);
    }

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    useEffect(() => {
        priorityHandler(priority ?? PRIORITY_TYPES.LOW.value);
    }, []);

    return (
        <div className="task-details-container">
            <h5 className="section-title">Task Details</h5>

            <div className="p-15 p-t-0">
                <InputHoc
                    name="name"
                    label="Task Name"
                    ref={register({ required: true })}
                    error={errors?.name}
                />
                <InputHoc
                    name="description"
                    label="Task Description"
                    ref={register({ required: true })}
                    error={errors?.description}
                />
                <Row>
                    <Col className="p-l-10">
                        <InputHoc
                            name="duration"
                            type="number"
                            label="Duration"
                            ref={register({ required: true })}
                            error={errors?.duration}
                        />
                    </Col>
                    <Col className="p-r-10">
                        <InputHoc
                            name="type"
                            label="Duration Type"
                            inputType={InputTypes.SELECT}
                            options={_DURATION_SELECTOR}
                            control={control}
                            defaultValue={_DURATION_SELECTOR[0].value}
                            error={errors?.type}
                        />
                    </Col>
                </Row>
                <label className="f-s-12">Priority</label>
                <div className="d-flex flex-row">
                    {
                        Object.keys(PRIORITY_TYPES).map((key, index) => (
                            <button
                                key={index}
                                type="button"
                                className={`btn ${prioritySelection === PRIORITY_TYPES[key].value ? PRIORITY_TYPES[key].btnType : 'btn-default'}`}
                                style={{ flex: 1, marginRight: '5px' }}
                                onClick={() => priorityHandler(PRIORITY_TYPES[key].value)}
                            >
                                {PRIORITY_TYPES[key].name}
                            </button>
                        ))
                    }
                    <input type="number" style={{ display: 'none' }} ref={register} name="priority" />
                </div>
            </div>
        </div>
    )
}

/// <summary>
/// Author: Christopher Chan
/// </summary>
const UsersList = ({ register, users, setUsers }) => {
    const [userCount, setUserCount] = useState(0);

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    const toggleCollapse = (index) => {
        let temp = users;
        temp[index] = {
            ...temp[index],
            isOpen: !temp[index].isOpen
        };

        setUsers([...temp]);
    }

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    const toggleSelection = (index, userIndex) => {
        let temp = users;
        temp[index].users[userIndex] = {
            ...temp[index].users[userIndex],
            selected: !temp[index].users[userIndex].selected
        };

        temp[index] = {
            ...temp[index],
            selectedCount: temp[index].users.reduce((a, v) => (v.selected ? ++a : a), 0)
        }

        setUsers([...temp]);
        setUserCount(temp.reduce((a, v) => (a + v.selectedCount), 0));
    }

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    useEffect(() => {
        setUserCount(users.reduce((val, item) => (val + item.selectedCount), 0));
    }, [users]);

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    const UserItem = ({ user, departmentIndex, userIndex }) => <>
        <div key={user.id} className="inner-list-item d-flex flex-row align-items-center" onClick={() => toggleSelection(departmentIndex, userIndex)}>
            <i className={user.selected ? "fas fa-check-circle active" : "far fa-circle"}></i>
            <div className="flex-grow-1">
                <div className="inner-list-item-title">{user.name} ({user.employeeNumber})</div>
            </div>
        </div>
        <input type="checkbox" style={{ display: 'none' }} ref={register} name="users[]" value={user.id} checked={user.selected} readOnly />
    </>;

    return (
        <div id="departmentList">
            <h5 className="section-title">
                Default Assignees <span>| {userCount} selected</span>
            </h5>
            <div className="department-list">
                {
                    users.map((item, index) => {
                        if (item.users.length > 0) {
                            return (
                                <Fragment key={item.uid}>
                                    <div className="list-item d-flex flex-row align-items-center" onClick={() => toggleCollapse(index)}>
                                        <i className={item.isOpen ? "fas fa-chevron-down" : "fas fa-chevron-right"}></i>
                                        <div className="flex-grow-1">
                                            <div className="list-item-title">{item.name}</div>
                                        </div>
                                        <span className="label label-secondary">{item.selectedCount}</span>
                                    </div>
                                    <Collapse isOpen={item.isOpen}>
                                        {item.users.map((user, userIndex) => (<UserItem key={user.id} user={user} departmentIndex={index} userIndex={userIndex} />))}
                                    </Collapse>
                                </Fragment>
                            );
                        }

                        return;
                    })
                }
            </div>
        </div>
    );
}

/// <summary>
/// Author: Christopher Chan
/// </summary>
export default ({ isOpen, toggle, groups, setGroups, selectedIndex }) => {
    const methods = useForm();
    const [users, setUsers] = useState([]);

    let configInstance = Config.getInstance();
    var token = configInstance.getValue(ConfigEnum._TOKEN);

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    const getUsers = () => {
        Block.pulse('div#departmentList', 'Retrieving list of users...');

        tryCatch(async () => {
            let dao = new UserDao();

            await dao.getUserByDepartmentList(token)
                .then(responseJson => {
                    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                        let users = responseJson[ApiKey._API_DATA_KEY].map(department => {
                            let existingUsersArr = selectedIndex.task !== null ? groups?.[selectedIndex.group]?.tasks?.[selectedIndex.task].users : [];

                            return {
                                id: department.id,
                                uid: _uniqueId('department-'),
                                name: department.name,
                                selectedCount: department.users.filter(item => existingUsersArr.includes(item.id)).length,
                                isOpen: true,
                                users: department.users.map(user => {

                                    return {
                                        id: user.id,
                                        name: user.name,
                                        employeeNumber: user.employeeNumber,
                                        selected: selectedIndex.task !== null ? existingUsersArr.includes(user.id) : false
                                    }
                                })
                            }
                        });

                        setUsers(users);
                    }
                    else {
                        Report.Failure('Error', 'Failed to retrieve user list. ' + responseJson[ApiKey._API_MESSAGE_KEY]);
                    }
                })
                .catch(error => {
                    Report.Failure('Error', 'Operation Failed. ' + error);
                });
        }, () => {
            Block.remove('div#departmentList', 600);
        });
    }

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    const onSubmit = (data) => {
        let taskDetails = {
            ...data,
            duration: parseInt(data.duration),
            priority: parseInt(data.priority),
        }

        console.log(taskDetails);

        setGroups(prevState => {
            let temp = prevState;

            if (selectedIndex?.task !== null) {
                temp[selectedIndex.group].tasks[selectedIndex.task] = taskDetails;
            }
            else {
                temp[selectedIndex.group].tasks = [
                    ...prevState[selectedIndex.group].tasks,
                    {
                        uid: _uniqueId('task-'),
                        ...taskDetails
                    }
                ];
            }

            return temp;
        });
        toggle(null);
    }

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    useEffect(() => {
        if (selectedIndex?.task !== null) {
            methods.reset({ ...groups?.[selectedIndex.group]?.tasks?.[selectedIndex.task] });
        }
    }, [isOpen, selectedIndex]);

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    useEffect(() => {
        if (!isOpen) {
            setUsers([]);
            methods.reset();
        }
    }, [isOpen]);

    return <>
        <Modal isOpen={isOpen} toggle={(e) => toggle(e)} size="lg" onOpened={getUsers} centered>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <ModalHeader toggle={(e) => toggle(e)}>{selectedIndex.task !== null ? 'Edit' : 'Add'} Task</ModalHeader>
                <div className="task-modal">
                    <TaskDetails {...methods} priority={selectedIndex.task !== null ? groups?.[selectedIndex.group]?.tasks?.[selectedIndex.task].priority : null} />
                    <UsersList {...methods} users={users} setUsers={setUsers} />
                </div>
                <ModalFooter>
                    <button type="submit" className="btn btn-themed btn-min-width">Add</button>
                </ModalFooter>
            </form>
        </Modal>
    </>
}
