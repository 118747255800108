import { ApiUrl } from "util/Constant";
import DataAccessObject from "./DataAccessObject";

/**
 * Author : Sim
 * DataAccessObject's fetch will return the promise.
 */
class DepartmentDao extends DataAccessObject {
  async getDepartmentList(token) {
    return this.get(ApiUrl._API_GET_DEPARTMENT, token);
  }

  async getDepartmentListJson(token) {
    return this.get(ApiUrl._API_GET_DEPARTMENT_JSON, token);
  }

  async getDepartmentListJsonAgent(token) {
    return this.get(ApiUrl._API_GET_DEPARTMENT_JSON_AGENT, token);
  }

  async register(data, token) {
    return this.post(ApiUrl._API_DEPARTMENT_CREATE, data, token);
  }

  async registerJson(data, token) {
    return this.post(ApiUrl._API_DEPARTMENT_CREATE_JSON, data, token);
  }

  async registerJsonAgent(data, token) {
    return this.post(ApiUrl._API_DEPARTMENT_CREATE_JSON_AGENT, data, token);
  }

  async updateDepartment(data, token) {
    console.log(ApiUrl._API_DEPARTMENT_UPDATE);
    return this.post(ApiUrl._API_DEPARTMENT_UPDATE, data, token);
  }

  async delete(id, token) {
    return this.post(`${ApiUrl._API_DEPARTMENT_DELETE}/${id}`, null, token);
  }
  // async verifyActivationCode({ email, otp }) {
  //     return this.get(`${ApiUrl._API_VERIFY_ACTIVATION_CODE}/${email}/${otp}`);
  // }

  // async signUpAccount(postData) {
  //     return this.postForm(ApiUrl._API_ACCOUNT_REGISTRATION, postData);
  // }

  // async checkUsername(username) {
  //     return this.get(`${ApiUrl._API_CHECK_USERNAME}/${username}`);
  // }

  // async checkEmail(email) {
  //     return this.get(`${ApiUrl._API_CHECK_EMAIL}/${email}`);
  // }

  // async checkPhoneNum(phoneNum) {
  //     return this.get(`${ApiUrl._API_CHECK_PHONENUMBER}/${phoneNum}`);
  // }
}

export default DepartmentDao;
