import React, {
  useMemo,
  useState,
  useRef,
  useCallback,
  useEffect,
} from "react";
import ReactTable from "components/react-table/ReactTable";
import { Row, Col } from "reactstrap";
import { useForm } from "react-hook-form";
import RoleModal from "./RoleModal";
import PolicyDao from "data/PolicyDao";
import { ApiKey, AppFeatureKeys, Permission } from "util/Constant";
import { Config, ConfigEnum } from "util/Config";
import Notiflix, { Loading } from "notiflix";
import { Can } from "config/user-access";
import { Block } from "notiflix";
import UserDao from "data/UserDao.js";
import { handleSetPageOptions } from "util/Utility.js";
import CustomSelect from "components/form/CustomSelect";

///<summary>
///Author: Ong Sze Hua
///</summary>
const RolePermission = (props) => {
  const [showRoleModal, setShowRoleModal] = useState(false);
  const { control } = useForm();
  const [policyList, setPolicyList] = useState([]);
  const [pagedPolicyList, setPagedPolicyList] = useState([]);
  const [role, setRole] = useState();
  const [userList, setUserList] = useState([]);
  const [policySelect, setPolicySelect] = useState([]);
  const _tableRef = useRef();
  const _tableRef2 = useRef();
  let dao = useMemo(() => new UserDao(), []);
  const [listQueryParams, setListQueryParams] = useState();
  const [rolelistQueryParams, setRoleListQueryParams] = useState();

  let policyDao = useMemo(() => new PolicyDao(), []);
  let configInstance = Config.getInstance();
  var token = configInstance.getValue(ConfigEnum._TOKEN);

  const _GROUP_COLUMN = useMemo(() => [
    {
      Header: "Policy Name",
      accessor: "roleName",
    },
    {
      Header: "Users",
      accessor: "numberOfUsers",
      Cell: ({ row }) => {
        return row?.original?.numberOfUsers + " Users";
      },
    },
    {
      Header: "Status",
      accessor: "isActive",
      Cell: ({ row }) => {
        return row?.original?.isActive == 1 ? "Active" : "Inactive";
      },
    },
    {
      Header: " ",
      Cell: ({ row }) => (
        <>
          <Can I={Permission.EDIT} this={AppFeatureKeys._PERMISSIONS}>
            <div
              className="btn-table last-btn"
              onClick={() => {
                toggleRoleModal();
                setRole(row.original);
              }}
            >
              <i className="fa fa-edit"></i>
            </div>
            <div
              className="btn-table last-btn"   
              onClick={() => {
                deleteRole(row.original);
              }}     
            >
              <i className="fa fa-trash"></i>
            </div>
          </Can>
        </>
      ),
      disableSortBy: true,
      disableFilters: true,
      style: { overflow: "visible", textAlign: "right" },
      sticky: "right",
    },
  ]);

  const _USER_COLUMN = useMemo(() => [
    {
      Header: "Name",
      accessor: "fullName",
    },
    {
      Header: " ",
      Cell: ({ row }) => (
        <>
          {console.log(row)}
          <Can I={Permission.EDIT} this={AppFeatureKeys._PERMISSIONS}>
            <CustomSelect
              control={control}
              name="policy"
              label="Policy"
              options={policySelect && policySelect}
              defaultValue={policySelect && row?.original.roleId}
              onChange={(data) => {
                updateUserPolicy(data, row.original);
              }}
              showLabel
              placeholder="Policy *"
              rules={{
                required: true,
              }}
            />
          </Can>
        </>
      ),
      disableSortBy: true,
      disableFilters: true,
      style: { overflow: "visible", textAlign: "right" },
      sticky: "right",
    },
  ]);

  const updateUserPolicy = async (roleid, user) => {
    var data = {
      id: user.id,
      role: roleid,
    };
    await policyDao.updateUserPolicy(data, token).then((response) => {
      if (!response[ApiKey._API_SUCCESS_KEY]) {
        throw new Error("Failed to retrieve features");
      }

      if (response[ApiKey._API_SUCCESS_KEY]) {
        var message = "Policy Updated Successful";
        Notiflix.Report.success("Success", message, "Okay");
      } else {
        Notiflix.Report.failure(
          "Error",
          "Policy update failed. " + response[ApiKey._API_MESSAGE_KEY]
        );
      }
    });
  };
  /// <summary>
  /// Author: Nicholas
  /// </summary>
  const getPolicyList = useCallback(async () => {
    await policyDao
      .getRoleList(token)
      .then((responseJson) => {
        if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
          throw new Error("Failed to retrieve features");
        }

        setPolicyList(responseJson[ApiKey._API_DATA_KEY]);

        var selectOptions = [];
        selectOptions = responseJson[ApiKey._API_DATA_KEY].map((item) => ({
          label: item.roleName,
          value: item.id,
        }));
        console.log(selectOptions);
        setPolicySelect(selectOptions);
      })
      .catch((error) => {
        Notiflix.Report.failure("Error", "Failed to load features");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policyDao, token]);

  /// <summary>
  /// Author: Nicholas
  /// </summary>
  const getPagedPolicyList = useCallback(
    async (params) => {
      await policyDao
        .getRoleListPaged(token, params)
        .then((responseJson) => {
          if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
            throw new Error("Failed to retrieve features");
          }

          setPagedPolicyList(responseJson[ApiKey._API_DATA_KEY]);

          handleSetPageOptions(
            _tableRef2,
            responseJson[ApiKey._API_TOTAL_KEY] ?? 0
          );
        })
        .catch((error) => {
          Notiflix.Report.failure("Error", "Failed to load features");
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [policyDao, token]
  );

  /// <summary>
  /// Author: Nicholas
  /// </summary>
  const toggleRoleModal = useCallback(() => {
    setShowRoleModal((prev) => !prev);
  }, []);

    /// <summary>
  /// Author: Nicholas
  /// </summary>
  const deleteRole = (data) => {

    Notiflix.Confirm.init({
      plainText: false,
      titleColor: "#FF0000",
      okButtonBackground: "#FF0000",
    });

    Notiflix.Confirm.show(
      'Delete Module ('+data.roleName+')?',
      `Are you sure you would like to delete</b>`,
      'Delete',
      'No',
      () => deleteRoleHandler(data.id)
    )
  } 

  /// <summary>
  /// Author: Nicholas Lai
  /// </summary>
  const deleteRoleHandler = (id) => {
    let dao = new PolicyDao();

     dao.deleteRole(id, token).then((response) => {
      if (response[ApiKey._API_SUCCESS_KEY]) {
        Notiflix.Report.success(
          "Success",
          `Successfully deleted role.`,
          "Okay",
          getPagedPolicyList(listQueryParams)
        );
      } else {
        Notiflix.Report.failure(
          "Error",
          `Failed to delete role. Please try again later`
        );
      }
    });
};

  const getUserList = useCallback(
    async (params) => {
      console.log("aaa");
      let dao = new UserDao();
      await dao.getUserList(token, params).then((response) => {
        if (response[ApiKey._API_SUCCESS_KEY]) {
          var data = response[ApiKey._API_DATA_KEY];
          var users = [];
          var selectOptions = [];
          console.log(data);
          selectOptions = data.map((item) => {
            var user = {
              id: item.id,
              employeeNumber: item.employeeNumber,
              firstName: item.firstName,
              lastName: item.lastName,
              username: item.username,
              gender: item.gender,
              dob: item.dob,
              status: item.status,
              icNumber: item.icNumber,
              jobPosition: item.jobPosition,
              warehouse: item.warehouse,
              department: item.department,
              role: item.role,
              email: item?.email?.email,
              contact: item?.phone?.number,
              creation_date: item.creation_date,
              modified_date: item.modified_date,
              fullName: item.fullName,
              lastModifiedBy: item.last_modified_by,
              createdBy: item.created_by,
              position: item.position,
              departmentName: item.departmentName,
              building: item.warehouse,
              photo: item.photo,
              citizen: item.citizen,
              phone: item.phone?.number,
              subordinateTo: item.subordinateTo,
              accountType: item.accountType,
              roleId: item.roleId,
            };

            var option = {
              label: `${item.lastName}, ${item.firstName}`,
              value: item.id,
            };
            users.push(user);

            return option;
          });

          setUserList(users);
          handleSetPageOptions(_tableRef, response[ApiKey._API_TOTAL_KEY] ?? 0);
        } else {
          Notiflix.Report.failure(
            "Error",
            "Failed to load users. Please try again later"
          );
        }
      });
    },
    [token, dao]
  );

  useEffect(() => {
    listQueryParams && getUserList(listQueryParams);
  }, [getUserList, listQueryParams]);

  useEffect(() => {
    rolelistQueryParams && getPagedPolicyList(rolelistQueryParams);
  }, [getPagedPolicyList, rolelistQueryParams]);

  /// Author: Nicholas
  /// </summary>
  useEffect(() => {
    getPolicyList();
  }, []);

  return (
    <>
      <div className="float-xl-right">
        <Can I={Permission.CREATE} this={AppFeatureKeys._PERMISSIONS}>
          <button
            type="type"
            className="btn btn-sm btn-themed mb-2"
            onClick={() => {
              toggleRoleModal();
            }}
          >
            <i className="fa fa-plus mr-1"></i>Add New Policy
          </button>
        </Can>
      </div>
      <h1 className="page-header">Management / Permissions</h1>
      <Row>
        <Col xs="6">
          <ReactTable
            columns={_USER_COLUMN}
            filterable
            canPreviousPage={true}
            canNextPage={true}
            ref={_tableRef}
            setQueryParams={setListQueryParams}
            disabledApiPagination={false}
            data={userList && userList}
          />
        </Col>
        <Col xs="6">
          <ReactTable
            columns={_GROUP_COLUMN}
            data={pagedPolicyList}
            filterable
            canPreviousPage={true}
            canNextPage={true}
            ref={_tableRef2}
            setQueryParams={setRoleListQueryParams}
            disabledApiPagination={false}
          />
        </Col>
      </Row>
      <RoleModal
        isOpen={showRoleModal}
        role={role}
        setRole={setRole}
        toggleRoleModal={toggleRoleModal}
        successCallback={() => {
          getPagedPolicyList(rolelistQueryParams);
          getPolicyList();
          toggleRoleModal();
        }}
      />
    </>
  );
};

export default RolePermission;
