import React, { useMemo, useState, useRef, useEffect } from "react";
import ReactTable from 'components/react-table/ReactTable';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Notiflix from "notiflix";
import { ApiKey } from "util/Constant";
import { Config, ConfigEnum } from 'util/Config';
import DepartmentDao from "data/DepartmentDao.js";
import { Block } from 'notiflix';
import { useForm } from "react-hook-form";
import CustomInput from "components/form/CustomInput";

import { FaSignature } from 'react-icons/fa';
import CustomColorPicker from "components/form/CustomColorPicker";

///<summary>
///Author: Nicholas
///</summary>
const DepartmentManagement = props => {
    const [modal, setModal] = useState(false);
    const [department, setDepartment] = useState();
    const { register, control, handleSubmit } = useForm();


    const [departmentList, setDepartmentList] = useState([]);

    const toggleModal = () => {
        setModal(!modal);
    }

    let configInstance = Config.getInstance();
    var token = configInstance.getValue(ConfigEnum._TOKEN);

    /// <summary>
    /// Author: KurisuCodes
    /// </summary>
    useEffect(() => {
        getDepartmentList();
    }, []);

    /// <summary>
    /// Author: KurisuCodes
    /// </summary>
    const setSelectedDepartment = (row) => {
        setDepartment({
            id: row.id,
            name: row.departmentName,
            description: row.departmentDescription
        });
    }

    /// <summary>
    /// Author: KurisuCodes
    /// </summary>
    const getDepartmentList = async () => {
        Block.circle('#departmentsTable');

        let dao = new DepartmentDao();
        await dao.getDepartmentList(token).then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                var data = response[ApiKey._API_DATA_KEY];
                var departments = [];
                data.map((item) => {
                    var department = {
                        id: item.id,
                        departmentName: item.name,
                        departmentDescription: item.description
                    }
                    departments.push(department);
                })
                setDepartmentList(departments);
            } else {
                Notiflix.Report.failure('Error', 'Failed to load departments. Please try again later');
            }
        })
    }

    /// <summary>
    /// Author: KurisuCodes
    /// </summary>
    const deleteDepartment = async (row) => {
        var id = row.id;
        let dao = new DepartmentDao();

        await dao.delete(id, token).then(responseJson => {
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                Notiflix.Report.success('Success', "Department Deleted");
                getDepartmentList();
            } else {
                Notiflix.Report.failure('Error', 'Failed to delete department. ' + responseJson[ApiKey._API_MESSAGE_KEY]);
            }
        })
    }

    const _COLUMN = useMemo(() => [
        {
            Header: "Department Name",
            accessor: "departmentName"
        },
        {
            Header: "Department Description",
            accessor: "departmentDescription"
        },
        {
            Header: " ",
            Cell: ({ row }) => (<>
                <div className="btn-table" onClick={() => { toggle(); setSelectedDepartment(row.original) }}><i className="fa fa-eye"></i></div>
                <div className="btn-table last-btn"
                    onClick={() => {
                        Notiflix.Confirm.show('Confirmation', 'Do you sure to delete department?', 'Yes', 'No',
                            function () {
                                deleteDepartment(row.original);
                            }
                        )
                    }}><i className="fa fa-edit"></i></div>
            </>
            ),
            disableSortBy: true,
            disableFilters: true,
            style: { overflow: "visible", textAlign: 'right' },
            sticky: 'right',
        }
    ])



    ///<summary>
    ///Author: Nicholas Lai
    ///</summary>
    const toggle = () => { setModal(!modal); setDepartment(null) };

    ///<summary>
    ///Author: Nicholas Lai
    ///</summary>
    const onSubmit = async (data) => {
        let departmentData = data;

        if (department) {
            departmentData["id"] = department.id;
        }

        let action = '';
        let dao = new DepartmentDao();

        if (departmentData.id) {
            action = dao.updateDepartment(departmentData, token);
        } else {
            action = dao.register(departmentData, token);
        }

        await action.then(responseJson => {
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                var message = departmentData.id ? "Update Successful" : "Department Added Successfully";
                Notiflix.Report.success('Success', message);
                getDepartmentList();
                toggle();
            } else {
                Notiflix.Report.failure('Error', 'Registration Failed. ' + responseJson[ApiKey._API_MESSAGE_KEY]);
            }
        })
    }

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    useEffect(() => {
        if (departmentList.length > 0) {
            Block.remove('#departmentsTable');
        }
    }, [departmentList]);

    return (<>
        <div className="float-xl-right">
            <button type="type" className="btn btn-sm btn-themed mb-2" onClick={toggle} ><i className="fa fa-plus mr-1"></i>Add Department</button>
        </div>
        <h1 className="page-header">Department Management</h1>
        <div id="departmentsTable">
            <ReactTable columns={_COLUMN} data={departmentList && departmentList} />
        </div>
        <Modal isOpen={modal} toggle={toggle} centered>
            <ModalHeader toggle={toggleModal}>{department ? "Edit Department" : "Add New Department"}</ModalHeader>
            <form onSubmit={handleSubmit(onSubmit)}>
                <ModalBody style={{ padding: 15 }}>
                    <CustomInput
                        ref={register}
                        name="name"
                        label="Name"
                        icon={<FaSignature />}
                        defaultValue={department?.name ?? ''}
                        showLabel
                    />
                    <CustomInput
                        ref={register}
                        name="description"
                        label="Description"
                        icon={<FaSignature />}
                        defaultValue={department?.description ?? ''}
                        showLabel
                    />
                    <CustomColorPicker
                        control={control}
                        name="colour"
                        label="Colour"
                        defaultValue={department?.colour ?? '#00AABB'}
                    />
                </ModalBody>
                <ModalFooter style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <button type="button" className="btn btn-red" onClick={toggle}>Cancel</button>
                    <button type="submit" className="btn btn-themed">
                        Save
                    </button>
                </ModalFooter>
            </form>
        </Modal>
    </>)
}

export default DepartmentManagement;
