import React, { useMemo, useState, useRef, useEffect } from "react";
import ReactTable from 'components/react-table/ReactTable';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Notiflix from "notiflix";
import { InputTypes, ApiKey } from "util/Constant";
import FormBuilder, { submitForm } from "components/form/FormBuilder";
import { Config, ConfigEnum } from 'util/Config';
import WarehouseDao from "data/WarehouseDao.js";
import getCountryList from 'react-select-country-list';
import { sortByPopularCountry } from 'util/Utility';
import { Block } from 'notiflix';

///<summary>
///Author: Nicholas
///</summary>
const WarehouseManagement = props => {
    const [modal, setModal] = useState(false);
    const [warehouse, setWarehouse] = useState();
    const [warehouseList, setWarehouseList] = useState([]);


    const COUNTRY_OPTIONS = sortByPopularCountry(getCountryList().getData());
    const formRef = useRef();
    console.log()
    const toggleModal = () => {
        setModal(!modal);
    }

    let configInstance = Config.getInstance();
    var token = configInstance.getValue(ConfigEnum._TOKEN);

    useEffect(() => {
        getWarehouseList();
    }, []);


    const getWarehouseList = async () => {
        Block.circle('#warehousesTable');

        let dao = new WarehouseDao();
        await dao.getWarehouseList(token).then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                var data = response[ApiKey._API_DATA_KEY];
                var warehouses = [];
                data.map((item) => {
                    var warehouse = {
                        id: item.id,
                        warehouseName: item.name,
                        warehouseDescription: item.description,
                        warehouseCode: item.code,
                        addressLine1: item.address_line1,
                        addressLine2: item.address_line2,
                        postCode: item.postcode,
                        city: item.city,
                        state: item.state,
                        country: item.country
                    }
                    warehouses.push(warehouse);
                })
                console.log(warehouses);
                setWarehouseList(warehouses);
            } else {
                Notiflix.Report.Failure('Error', 'Failed to load warehouses. Please try again later');
            }
        })
    }



    const setSelectedWarehouse = (row) => {
        setWarehouse({
            id: row.id,
            name: row.warehouseName,
            description: row.warehouseDescription,
            code: row.warehouseCode,
            addressLine1: row.addressLine1,
            addressLine2: row.addressLine2,
            postCode: row.postCode,
            city: row.city,
            state: row.state,
            country: row.country
        });

        console.log(row);
    }

    const deleteWarehouse = async (row) => {
        var id = row.id;
        let dao = new WarehouseDao();

        await dao.deleteWarehouse(id, token).then(responseJson => {
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                Notiflix.Report.Success('Success', "Warehouse Deleted");
                getWarehouseList();
            } else {
                Notiflix.Report.Failure('Error', 'Failed to delete warehouse. ' + responseJson[ApiKey._API_MESSAGE_KEY]);
            }
        })
    }



    const _FIELDS = [
        {
            rowOptions: { xl: 1 },
            rowTitle: <strong>Warehouse Details</strong>,
            columns: [
                { label: 'Warehouse Name *', name: 'warehouseName', rules: { required: 'Warehouse Name is required' }, columnOptions: { xl: 12 }, defaultValue: warehouse && warehouse.name },
                { label: 'Warehouse Description *', name: 'warehouseDescription', rules: { required: 'Warehouse Description is required.' }, columnOptions: { xl: 12 }, defaultValue: warehouse && warehouse.description },
                { label: 'Warehouse Code *', name: 'warehouseCode', rules: { required: 'Warehouse Code is required.' }, columnOptions: { xl: 12 }, defaultValue: warehouse && warehouse.code }
            ]
        },
        {
            rowOptions: { xl: 1 },
            rowTitle: <strong>Warehouse Address</strong>,
            columns: [

                { label: 'Address Line 1 *', name: 'addressLine1', rules: { required: 'Address Line 1 is required' }, columnOptions: { xl: 6 }, defaultValue: warehouse && warehouse.addressLine1 },
                { label: 'Address Line 2', name: 'addressLine2', columnOptions: { xl: 6 }, rules: { required: 'Password is required' }, defaultValue: warehouse && warehouse.addressLine2 },
                { label: 'Postcode *', name: 'postcode', columnOptions: { xl: 3 }, rules: { required: 'Postcode is required' }, defaultValue: warehouse && warehouse.postCode },
                { label: 'City  *', name: 'city', rules: { required: 'City is required' }, columnOptions: { xl: 3 }, defaultValue: warehouse && warehouse.city },
                { label: 'State  *', name: 'state', rules: { required: 'State is required' }, columnOptions: { xl: 3 }, defaultValue: warehouse && warehouse.state },
                { label: 'Country *', name: 'country', input: InputTypes.SELECT, options: COUNTRY_OPTIONS, rules: { required: 'Country is required' }, columnOptions: { xl: 3 }, defaultValue: warehouse && warehouse.country },
            ]
        }
    ];

    const _COLUMN = useMemo(() => [
        {
            Header: "Warehouse Name",
            accessor: "warehouseName"
        },
        {
            Header: "Warehouse Description",
            accessor: "warehouseDescription"
        },
        {
            Header: "Warehouse Code",
            accessor: "warehouseCode"
        },
        {
            Header: "Address Line 1",
            accessor: "addressLine1"
        },
        {
            Header: "Address Line 2",
            accessor: "addressLine2"
        },
        {
            Header: "Postcode ",
            accessor: "postCode"
        },
        {
            Header: "City ",
            accessor: "city"
        },
        {
            Header: "State",
            accessor: "state"
        },
        {
            Header: "Country ",
            accessor: "country"
        },
        {
            Header: "Action",
            Cell: ({ row }) => (<>
                <div className="btn-round expand-theme btn-sm" onClick={() => { toggle(); setSelectedWarehouse(row.original) }}><i className="fa fa-edit"></i></div>
                <div className="btn-round expand-theme btn-sm m-l-5"
                    onClick={() => {
                        Notiflix.Confirm.Show('Confirmation', 'Do you sure to delete warehouse?', 'Yes', 'No',
                            function () {
                                deleteWarehouse(row.original);
                            }
                        )
                    }}><i className="fa fa-trash"></i></div>
            </>
            ),
            disableSortBy: true,
            disableFilters: true,
            style: { overflow: "visible" }
        }
    ])



    ///<summary>
    ///Author: Nicholas Lai
    ///</summary>
    const toggle = () => { setModal(!modal); setWarehouse(null) };

    ///<summary>
    ///Author: Nicholas Lai
    ///</summary>
    const onSubmit = async (data) => {
        let warehouseData = {
            "name": data.warehouseName,
            "description": data.warehouseDescription,
            "code": data.warehouseCode,
            "address_line1": data.addressLine1,
            "address_line2": data.addressLine2,
            "city": data.city,
            "postcode": data.postcode,
            "state": data.state,
            "country": data.country

        };

        console.log(warehouseData);
        console.log(warehouse);
        if (warehouse) {
            warehouseData["id"] = warehouse.id;
        }

        let action = '';
        let dao = new WarehouseDao();

        if (warehouseData.id) {
            action = dao.updateWarehouse(warehouseData, token);
        } else {
            action = dao.register(warehouseData, token);
        }

        await action.then(responseJson => {
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                var message = warehouseData.id ? "Update Successful" : "Registration Successful";
                Notiflix.Report.Success('Success', message);
                getWarehouseList();
                toggle();
            } else {
                Notiflix.Report.Failure('Error', 'Registration Failed. ' + responseJson[ApiKey._API_MESSAGE_KEY]);
            }
        })
    }

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    useEffect(() => {
        if (warehouseList.length > 0) {
            Block.remove('#warehousesTable');
        }
    }, [warehouseList]);

    return (<>
        <div className="float-xl-right">
            <button type="type" className="btn btn-sm btn-themed mb-2" onClick={toggle} ><i className="fa fa-plus mr-1"></i>New Warehouse</button>
        </div>
        <h1 className="page-header">Warehouse Management</h1>
        <div id="warehousesTable">
            <ReactTable columns={_COLUMN} data={warehouseList && warehouseList} />
        </div>
        <Modal isOpen={modal} toggle={toggle} centered>
            <ModalHeader toggle={toggleModal}>{warehouse ? "Edit Warehouse" : "Add New Warehouse"}</ModalHeader>
            <ModalBody>
                <FormBuilder fields={_FIELDS} onSubmit={onSubmit} formRef={formRef} />
            </ModalBody>
            <ModalFooter>
                <button type="button" className="btn btn-red" onClick={toggle}>Cancel</button>
                <button type="button" className="btn btn-themed" onClick={() => { submitForm(formRef) }}>
                    Save
                </button>
            </ModalFooter>
        </Modal>
    </>)
}

export default WarehouseManagement;
