import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import ReactTable from "components/react-table/ReactTable";
import Notiflix from "notiflix";
import { Can } from "config/user-access";
import { ApiKey, AppFeatureKeys, Permission } from "util/Constant";
import { Config, ConfigEnum } from "util/Config";
import { handleSetPageOptions } from "util/Utility.js";
import UserDao from "data/UserDao.js";
import { Block } from "notiflix";
import { useForm } from "react-hook-form";
import ChangePasswordModal from "./ChangePasswordModal";
import UserForm from "./UserForm";

///<summary>
///Author: Sim
///</summary>
const UserManagement = (props) => {
  const [modal, setModal] = useState(null);
  const [passwordModal, setPasswordModal] = useState(null);
  const [user, setUser] = useState();
  const [subordinateOptions, setSubordinateOptions] = useState([]);
  const [userList, setUserList] = useState([]);
  const [isNewUser, setIsNewUser] = useState();
  const { register, control, handleSubmit, reset, errors } = useForm();
  const _tableRef = useRef();
  const [listQueryParams, setListQueryParams] = useState();
  let dao = useMemo(() => new UserDao(), []);
  let configInstance = Config.getInstance();
  var token = configInstance.getValue(ConfigEnum._TOKEN);

  const getUserList = useCallback(
    async (params) => {
      Block.circle("#usersTable");

      let dao = new UserDao();
      await dao.getUserList(token, params).then((response) => {
        if (response[ApiKey._API_SUCCESS_KEY]) {
          var data = response[ApiKey._API_DATA_KEY];
          var users = [];
          var selectOptions = [];

          selectOptions = data.map((item) => {
            var user = {
              id: item.id,
              employeeNumber: item.employeeNumber,
              firstName: item.firstName,
              lastName: item.lastName,
              username: item.username,
              gender: item.gender,
              dob: item.dob,
              status: item.status,
              icNumber: item.icNumber,
              jobPosition: item.jobPosition,
              warehouse: item.warehouse,
              department: item.department,
              role: item.role,
              email: item?.email?.email,
              contact: item?.phone?.number,
              creation_date: item.creation_date,
              modified_date: item.modified_date,
              fullName: item.fullName,
              lastModifiedBy: item.last_modified_by,
              createdBy: item.created_by,
              position: item.position,
              departmentName: item.departmentName,
              building: item.warehouse,
              photo: item.photo,
              citizen: item.citizen,
              phone: item.phone?.number,
              subordinateTo: item.subordinateTo,
              accountType: item.accountType,
              housePhone: item.housePhone?.number,
              roleId: item.roleId,
            };

            var option = {
              label: `${item.lastName}, ${item.firstName}`,
              value: item.id,
            };
            users.push(user);

            return option;
          });

          setSubordinateOptions(selectOptions);
          setUserList(users);
          handleSetPageOptions(_tableRef, response[ApiKey._API_TOTAL_KEY] ?? 0);
        } else {
          Notiflix.Report.failure(
            "Error",
            "Failed to load users. Please try again later"
          );
        }
      });
    },
    [token, dao]
  );

  useEffect(() => {
    listQueryParams && getUserList(listQueryParams);
  }, [getUserList, listQueryParams]);

  const deleteUser = async (row) => {
    var id = row.id;
    let dao = new UserDao();

    await dao.delete(id, token).then((responseJson) => {
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        Notiflix.Report.success("Success", "User Deleted");
        getUserList();
      } else {
        Notiflix.Report.failure(
          "Error",
          "Failed to delete user. " + responseJson[ApiKey._API_MESSAGE_KEY]
        );
      }
    });
  };

  /// <summary>
  /// Author: Nicholas Lai
  /// </summary>
  const tableClickEvent = (data) => {
    setIsNewUser(false);
    setUser(data);
    setModal((prev) => !prev);
  };

  /// <summary>
  /// Author: Nicholas Lai
  /// </summary>
  const changePasswordHandler = (data) => {
    setIsNewUser(false);
    setUser(data);
    setPasswordModal((prev) => !prev);
  };

  ///<summary>
  ///Author: Nicholas Lai
  ///</summary>
  const toggle = () => {
    setIsNewUser(true);
    setUser(null);
    setModal((prev) => !prev);
  };

  const toggleChangePassword = () => {
    setUser(null);
    setPasswordModal((prev) => !prev);
  };

  const _COLUMN = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "fullName",
      },
      {
        Header: "Employee ID",
        accessor: "employeeNumber",
      },
      {
        Header: "Position",
        accessor: "jobPosition",
      },
      {
        Header: "Department",
        accessor: "departmentName",
      },
      {
        Header: "Mobile Phone",
        accessor: "contact",
        Cell: ({ row }) => row?.original?.contact ?? "-",
      },
      {
        Header: " ",
        Cell: ({ row }) => (
          <>
            <div
              className="btn-table"
              onClick={() => tableClickEvent(row.original)}
            >
              <i className="fa fa-eye"></i>
            </div>
            <div
              className="btn-table"
              onClick={() => changePasswordHandler(row.original)}
            >
              <i className="fa fa-key"></i>
            </div>
          </>
        ),
        disableSortBy: true,
        disableFilters: true,
        style: { overflow: "visible", textAlign: "right" },
        sticky: "right",
      },
    ],
    []
  );

  /// <summary>
  /// Author: Christopher Chan
  /// </summary>
  useEffect(() => {
    if (userList.length > 0) {
      Block.remove("#usersTable");
    }
  }, [userList]);

  return (
    <>
      <div className="float-xl-right">
        <Can I={Permission.CREATE} this={AppFeatureKeys._USERS}>
          <button
            type="type"
            className="btn btn-sm btn-themed mb-2"
            onClick={() => {
              toggle();
            }}
          >
            <i className="fa fa-plus mr-1"></i>New User
          </button>
        </Can>
      </div>
      <h1 className="page-header">Management / Users</h1>
      <div id="usersTable">
        <ReactTable
          columns={_COLUMN}
          data={userList && userList}
          getRowProps={(row) => {
            return {
              onDoubleClick: () => tableClickEvent(row.original),
            };
          }}
          filterable
          canPreviousPage={true}
          canNextPage={true}
          ref={_tableRef}
          setQueryParams={setListQueryParams}
          disabledApiPagination={false}
        />
      </div>
      {modal && (
        <UserForm
          toggle={toggle}
          isOpen={modal}
          user={user && user}
          isNewUser={isNewUser}
          onClosed={() => {
            setUser(null);
          }}
          successCallback={() => {
            getUserList();
            toggle();
          }}
        />
      )}
      {passwordModal && (
        <ChangePasswordModal
          toggle={changePasswordHandler}
          isOpen={passwordModal}
          user={user && user}
          onClosed={() => {
            setUser(null);
          }}
          successCallback={() => {
            toggleChangePassword();
          }}
        />
      )}
    </>
  );
};

export default UserManagement;
