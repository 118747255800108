import React, { useState } from "react";
import classnames from "classnames";
import { Controller } from "react-hook-form";
import ReactDatepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CustomInput from "./CustomInput";

/// <summary>
/// Author: Christopher Chan
/// </summary>
const CustomDatePicker = (props) => {
  const [toggleClass, setToggleClass] = useState(props.defaultValue ?? false);
  const [selectDate, setSelectedDate] = useState();

  return (
    <Controller
      {...props}
      render={({ field: { onChange, onBlur }, value, ...otherProps }) => (
        <ReactDatepicker
          selected={
            selectDate ? new Date(selectDate) : new Date(props?.defaultValue)
          }
          customInput={
            <CustomInput
              label={props?.label}
              icon={props?.icon}
              isEditable={false}
              defaultValue={props?.defaultValue}
            />
          }
          dateFormat="MM/dd/yyyy"
          showMonthDropdown
          showYearDropdown
          dropdownMode={"select"}
          disabled={!props?.isEditable}
          onFocus={() => {
            setToggleClass(true);
          }}
          wrapperClassName={classnames({ "datepicker-selected": toggleClass })}
          {...otherProps}
          onChange={(e) => {
            onChange(e);
            console.log(e);
            setSelectedDate(e);
            setToggleClass(Boolean(e));
          }}
          onBlur={({ target: { value } }) => {
            onBlur(value);
            setToggleClass(Boolean(value));
          }}
        />
      )}
    />
  );
};

CustomDatePicker.defaultProps = {
  defaultValue: null,
};

export default CustomDatePicker;
