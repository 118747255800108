import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Notiflix, { Loading } from "notiflix";
import { Config, ConfigEnum } from "util/Config";
import UserDao from "data/UserDao.js";
import { useForm } from "react-hook-form";
import CustomInput from "components/form/CustomInput";
import { ApiKey } from "util/Constant";
import PolicyDao from "data/PolicyDao";

const ModuleModal = ({
  toggle,
  module,
  onClosed,
  isOpen,
  onOpened,
  successCallback,
}) => {
  const { register, handleSubmit, control, errors, reset, ref } = useForm({});
  let configInstance = Config.getInstance();
  var token = configInstance.getValue(ConfigEnum._TOKEN);

  useEffect(() => {
    console.log(errors);
  }, [errors]);

    /// <summary>
  /// Author: Samuel
  /// </summary>
  useEffect(() => {
    if (isOpen) {
      reset({ ...module });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const onSubmit = async (data) => {
    console.log(data);
    const formData = new FormData();

    formData.append("moduleName", data.moduleName);
    formData.append("moduleDescription", data.moduleDescription);

    let action = "";
    let dao = new PolicyDao();

    Loading.circle();

    if(data.id) {
      action = dao.updateModule(data, token);

      await action.then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          var message = "Module Updated successfully";
          Notiflix.Report.success("Success", message, "Okay", successCallback);
        } else {
          Notiflix.Report.failure(
            "Error",
            "Module Update Failed. " + responseJson[ApiKey._API_MESSAGE_KEY]
          );
        }
      })        
      .catch((error) => {
        Notiflix.Report.failure("Error", "Failed to update module");
      })
      .finally(Loading.remove);;
    } else {
      action = dao.createModule(data, token);

      await action.then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          var message = "Module created successfully";
          Notiflix.Report.success("Success", message, "Okay", successCallback);
        } else {
          Notiflix.Report.failure(
            "Error",
            "Module creation Failed. " + responseJson[ApiKey._API_MESSAGE_KEY]
          );
        }
      })
      .catch((error) => {
        Notiflix.Report.failure("Error", "Failed to add module");
      })
      .finally(Loading.remove);;;
    }
  };

  return (
    <Modal size="xs" {...{ isOpen, toggle, onClosed, onOpened }} centered>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader className="modal-themed" toggle={toggle}>
          <h2 style={{ marginBottom: 0 }}>   {module ? "MANAGEMENT / Edit Module" : "MANAGEMENT / Add Module"}</h2>
        </ModalHeader>

        <ModalBody>
          <Row>
            <Col xs={8}>
              <Row className="m-b-10">
                <Col xs={12}>
                  <CustomInput
                    {...register("moduleName")}
                    name="moduleName"
                    label="Module Name *"
                    error={errors?.roleName}
                    showLabel
                  />
                  <br></br>
                  <CustomInput
                    {...register("moduleDescription")}
                    name="moduleDescription"
                    label="Module Description *"
                    error={errors?.roleDescription}
                    showLabel
                  />
                </Col>
              </Row>
              <Row className="m-b-10"></Row>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-red mr-auto btn-min-width"
            onClick={toggle}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-themed btn-min-width pull-right mt-2"
          >
            Submit
          </button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default ModuleModal;
