import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Card, CardBody, Row, Col, CardHeader } from "reactstrap";
import {
  FaUserTie,
  FaUserAlt,
  FaKey,
  FaPlane,
  FaNetworkWired,
  FaSuitcase,
  FaSitemap,
  FaBuilding,
  FaPhone,
  FaMobile,
  FaEnvelope,
  FaAddressCard,
  FaUserLock,
  FaUserShield,
  FaUserTag,
  FaTransgender,
} from "react-icons/fa";

import CustomInput from "components/form/CustomInput";
import { useRecoilValue } from "recoil";
import { authCredentialState } from "recoil/Atoms";

/// <summary>
/// Author: KurisuCodes
/// </summary>
export default () => {
  const { register, reset, handleSubmit } = useForm();
  const userData = useRecoilValue(authCredentialState);

  /// <summary>
  /// Author: KurisuCodes
  /// </summary>
  useEffect(() => {
    console.log(userData);
    reset({
      ...userData,
      accountType: userData?.role?.name ?? "-",
      mobilePhone: userData?.phone?.number ?? "-",
      housePhone: userData?.housePhone?.number ?? "-",
      gender: userData?.gender ?? "-",
      email: userData?.email?.email ?? "-",
      position: userData?.jobPosition ?? "-",
      department: userData?.departmentName ?? "-",
      building: userData?.warehouse ?? "-",
      birthday: userData?.dob,
      managerName: userData?.managerName ?? "-",
    });
  }, [userData]);

  return (
    <>
      <h1 className="page-header">My Profile</h1>
      <form>
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeader>Personal Details</CardHeader>
              <CardBody>
                <Row className="m-b-10">
                  <Col xs={4}>
                    <CustomInput
                      {...register("firstName")}
                      name="firstName"
                      label="First Name"
                      icon={<FaUserTie />}
                      showLabel
                      readOnly
                    />
                  </Col>
                  <Col xs={4}>
                    <CustomInput
                      {...register("lastName")}
                      name="lastName"
                      label="Last Name"
                      icon={<FaUserTie />}
                      readOnly
                      showLabel
                    />
                  </Col>
                  <Col xs={4}>
                    <CustomInput
                      {...register("citizenship")}
                      name="citizenship"
                      label="Citizenship"
                      icon={<FaPlane />}
                      isEditable={false}
                      value="Malaysian"
                      showLabel
                      readOnly
                    />
                  </Col>
                </Row>
                <Row className="m-b-10">
                  <Col xs={4}>
                    <CustomInput
                      {...register("icNumber")}
                      name="icNumber"
                      label="IC Number"
                      icon={<FaAddressCard />}
                      readOnly
                      showLabel
                    />
                  </Col>
                  <Col xs={4}>
                    <CustomInput
                      {...register("birthday")}
                      name="birthday"
                      label="Birthday"
                      icon={<FaUserTie />}
                      readOnly
                      showLabel
                    />
                  </Col>
                  <Col xs={4}>
                    <CustomInput
                      {...register("gender")}
                      name="gender"
                      label="Gender"
                      icon={<FaTransgender />}
                      readOnly
                      showLabel
                    />
                  </Col>
                </Row>
                <Row className="m-b-10">
                  <Col xs={4}>
                    <CustomInput
                      {...register("housePhone")}
                      name="housePhone"
                      label="House Phone"
                      readOnly
                      icon={<FaPhone />}
                      showLabel
                    />
                  </Col>
                  <Col xs={4}>
                    <CustomInput
                      {...register("mobilePhone")}
                      name="mobilePhone"
                      label="Mobile Phone"
                      readOnly
                      icon={<FaMobile />}
                      showLabel
                    />
                  </Col>
                  <Col xs={4}>
                    <CustomInput
                      {...register("email")}
                      name="email"
                      label="Email"
                      icon={<FaEnvelope />}
                      readOnly
                      showLabel
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={4}>
                    <CustomInput
                      {...register("position")}
                      name="position"
                      label="Position"
                      icon={<FaSuitcase />}
                      value="Managing Director"
                      showLabel
                      readOnly
                    />
                  </Col>
                  <Col xs={4}>
                    <CustomInput
                      {...register("department")}
                      name="department"
                      label="Department"
                      icon={<FaSitemap />}
                      value="Fabrication"
                      showLabel
                      readOnly
                    />
                  </Col>
                  <Col xs={4}>
                    <CustomInput
                      {...register("building")}
                      name="building"
                      label="Building"
                      icon={<FaBuilding />}
                      value="Factory 1"
                      showLabel
                      readOnly
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeader>User Details</CardHeader>
              <CardBody>
                <Row className="m-b-10">
                  <Col xs={4}>
                    <CustomInput
                      {...register("username")}
                      name="username"
                      label="Username"
                      icon={<FaUserAlt />}
                      readOnly
                      showLabel
                    />
                  </Col>
                  <Col xs={4}>
                    <CustomInput
                      {...register("password")}
                      name="password"
                      label="Password"
                      icon={<FaKey />}
                      readOnly
                      showLabel
                    />
                  </Col>
                  <Col xs={4}>
                    <CustomInput
                      {...register("managerName")}
                      name="managerName"
                      label="Subordinate To"
                      icon={<FaNetworkWired />}
                      showLabel
                      readOnly
                    />
                  </Col>
                </Row>
                <Row className="m-b-10">
                  <Col xs={4}>
                    <CustomInput
                      {...register("employeeNumber")}
                      name="employeeNumber"
                      label="Employee Number"
                      icon={<FaUserTag />}
                      readOnly
                      showLabel
                    />
                  </Col>
                  <Col xs={4}>
                    <CustomInput
                      {...register("accessStatus")}
                      name="accessStatus"
                      label="Access Status"
                      icon={<FaUserLock />}
                      value="Active"
                      showLabel
                      readOnly
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </form>
    </>
  );
};
