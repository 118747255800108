import { createContext } from "react";
import { createContextualCan } from "@casl/react";
import { defineAbility } from "@casl/ability";
import { Permission, SuperAdminRole } from "util/Constant";

export const UserAccessContext = createContext();
export const Can = createContextualCan(UserAccessContext.Consumer);

export const ability = (user) =>
  defineAbility((can) => {
    if (user !== null) {
      let objUser;
      if (typeof user === "object") {
        objUser = user;
      } else {
        var formatted = user.replace(/\\/g, "");
        objUser = JSON.parse(formatted);
      }

      if (user !== "null" && user !== undefined && user !== null) {
        let roleStr = `${SuperAdminRole}`;
        if (objUser?.policy?.roles[0] === SuperAdminRole) {
          can("manage", "all");
        } else {
          objUser.appPermissions.forEach((item) => {
            item.canCreate &&
              can(Permission.CREATE, item.appFeatureName, roleStr);
            item.canDelete &&
              can(Permission.DELETE, item.appFeatureName, roleStr);
            item.canEdit && can(Permission.EDIT, item.appFeatureName, roleStr);
            item.canView && can(Permission.VIEW, item.appFeatureName, roleStr);
          });
        }
      }
    }
  });
