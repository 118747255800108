import { createUrl, getToken, stringIsNullOrEmpty } from 'util/Utility';
import { ApiKey, ApiUrl } from "util/Constant";

/// <summary>
/// Author: KurisuCodes
/// </summary>
export const getOrganization = () => [
    createUrl(ApiUrl._API_ORGANIZATION),
    {
        method: ApiKey._API_GET,
        headers: {
            'Authorization': 'Bearer ' + getToken()
        }
    }
]