import React, { useEffect, forwardRef } from "react";
import { Card, CardBody, Row, Col } from 'reactstrap';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { InputTypes, ApiKey } from "util/Constant";
import { Config, ConfigEnum } from 'util/Config';
import ElevatorModelDao from "data/ElevatorModelDao";
import InputHoc from "components/form/InputHoc.js";
import { useForm, useFieldArray } from "react-hook-form";
import { Report } from 'notiflix';
import NavigationButton from 'components/buttons/NavigationButton';
import { tryCatch } from 'util/Utility';

const _YEAR_OPTIONS = [
    { label: "1 Year", value: 1 },
    { label: "2 Years", value: 2 },
    { label: "5 Years", value: 5 },
]

const _MONTH_OPTIONS = [
    { label: "3 months", value: 3 },
    { label: "6 months", value: 6 },
    { label: "1 Year", value: 1 },
    { label: "2 Years", value: 2 },
]

/// <summary>
/// Author: Christopher Chan
/// </summary>
const ModelPartsArray = forwardRef(({ register, control, errors, parts }, ref) => {
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'parts'
    });

    return <>
        {
            fields.map((item, index) => {
                return (
                    <Row key={item.id}>
                        <Col className="custom-col">
                            {index + 1}.
                        </Col>
                        <Col>
                            <InputHoc
                                name={`parts[${index}].partName`}
                                label="Part Name" ref={register({ required: 'Part Name is required.' })}
                                error={errors?.parts?.[index]?.partName}
                                defaultValue={item?.partName}
                            />
                        </Col>
                        <Col>
                            <InputHoc
                                name={`parts[${index}].serialNumber`}
                                label="Serial Number (optional)"
                                ref={register}
                                defaultValue={item?.serialNumber}
                            />
                        </Col>
                        <Col>
                            <InputHoc
                                name={`parts[${index}].lifespanYear`}
                                label="Lifespan year"
                                inputType={InputTypes.SELECT}
                                options={_YEAR_OPTIONS}
                                control={control}
                                rules={{ required: 'Lifespan Year is required.' }}
                                error={errors?.parts?.[index]?.lifespanYear}
                                defaultValue={item?.lifespanYear}
                            />
                        </Col>
                        <Col>
                            <InputHoc
                                name={`parts[${index}].serviceTimeFrame`}
                                label="Service Time Frame"
                                inputType={InputTypes.SELECT}
                                options={_MONTH_OPTIONS}
                                control={control}
                                rules={{ required: 'Service Time Frame is required.' }}
                                error={errors?.parts?.[index]?.serviceTimeFrame}
                                defaultValue={item?.serviceTimeFrame}
                            />
                        </Col>
                        <Col>
                            <InputHoc name={`parts[${index}].remark`} label="Remark" ref={register} defaultValue={item?.remark} />
                        </Col>
                        <Col xs={1} className="custom-col-btn">
                            {
                                fields.length - 1 === index ?
                                    <div className="btn-round expand-theme mt-2" onClick={append}><i className="las la-plus"></i></div>
                                    :
                                    <div className="btn-round expand-red mt-2" onClick={() => remove(index)}><i className="las la-times"></i></div>
                            }
                        </Col>
                    </Row>
                )
            })
        }
    </>;
});

///<summary>
///Author: Christopher Chan
///</summary>
export default () => {
    const history = useHistory();
    const { id } = useParams();
    const { state } = useLocation();
    const { register, control, handleSubmit, reset, errors } = useForm();
    const isEditing = id !== 'create';

    let configInstance = Config.getInstance();
    var token = configInstance.getValue(ConfigEnum._TOKEN);

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    const onSubmit = (data) => {
        tryCatch(async () => {
            let dao = new ElevatorModelDao();

            if (isEditing) {
                data = {
                    ...data,
                    id: state.details.id
                }
            }

            await dao.createOrUpdateElevatorModel(data, token).then(responseJson => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    Report.Success('Success', 'Successfully created model parts.', 'Okay', () => history.goBack());
                } else {
                    Report.Failure('Error', 'Operation Failed. ' + responseJson[ApiKey._API_MESSAGE_KEY]);
                }
            })
        });
    }

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    useEffect(() => {
        if (state?.details) {
            reset(state?.details);
        }
        else {
            reset({
                parts: [{
                    partName: '',
                    serialNumber: '',
                    lifespanYear: null,
                    serviceTimeFrame: null,
                    remark: ''
                }]
            });
        }

        document.title = `NEHR | ${isEditing ? 'Update' : 'Create'} Elevator Model`;
    }, [reset, isEditing, state])

    return (
        <form id="elevatorModelForm" onSubmit={handleSubmit(onSubmit)}>
            <div className="float-xl-right">
                <button type="submit" className="btn btn-themed btn-min-width">Save</button>
            </div>
            <h1 className="page-header">
                <NavigationButton />
                {!isEditing && 'Create '}Elevator Model {isEditing && ` - ${id}`}
            </h1>
            <Card className="mb-2">
                <CardBody>
                    <Row>
                        <Col xs={6}>
                            <InputHoc name='name' label="Model Name" error={errors?.name?.message} ref={register({ required: 'required' })} />
                        </Col>
                        <Col xs={3}>
                            <InputHoc name='serialNumber' label="Serial Number" ref={register({ required: 'required' })} error={errors?.serialNumber?.message} />
                        </Col>
                        <Col xs={3}>
                            <InputHoc name='price' label="Item Price (RM)" type="number" ref={register({ required: 'required' })} error={errors?.price?.message} />
                        </Col>
                        <Col xs={9}>
                            <InputHoc name='description' label="Description" ref={register({ required: 'required' })} error={errors?.description?.message} />
                        </Col>
                        <Col xs={3}>
                            <InputHoc name='suffix' label="Suffix" ref={register({ required: 'required' })} error={errors?.suffix?.message} smallnote="To be used in SNE number generation" />
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <Card>
                <CardBody>
                    <ModelPartsArray register={register} control={control} errors={errors} />
                </CardBody>
            </Card>
        </form>
    );
}