import React, { useState, useEffect } from "react";
import { Card, CardBody, Collapse } from 'reactstrap';
import ReactTooltip from 'react-tooltip';

import Task from './Task';

/// <summary>
/// Author: Christopher Chan
/// </summary>
export default ({ item, index, toggle, collapseAll, remove, removeTask }) => {
    const [isCollapsed, setIsCollapsed] = useState(true);

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    const handleCollapsible = () => {
        setIsCollapsed(!isCollapsed);
    }

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    useEffect(() => {
        if (collapseAll) {
            setIsCollapsed(false);
        }
    }, [collapseAll]);

    return <>
        <div className="d-flex flex-row align-items-center mb-3">
            <Card className="flex-fill task-group-card" onClick={handleCollapsible}>
                <CardBody className="p-t-10 p-b-10">
                    <div className="task-group-row">
                        <i className={isCollapsed ? "fas fa-chevron-down" : "fas fa-chevron-right"}></i>
                        <div className="task-group-name">
                            {item?.name}
                            <span className="label label-secondary">{item?.tasks?.length} tasks</span>
                        </div>
                        <button type="button" className="btn expand-theme btn-round" onClick={(e) => toggle(e, index)} data-tip="Add Task" data-for="generalTooltip">
                            <i className="fas fa-plus"></i>
                        </button>
                    </div>
                </CardBody>
            </Card>
            <button type="button" className="btn expand-red btn-round ml-3" onClick={() => remove(index)} data-tip="Remove Group" data-for="generalTooltip">
                <i className="fas fa-times"></i>
            </button>
        </div>

        <Collapse className="task-list-container" isOpen={isCollapsed}>
            {
                item?.tasks?.map((task, taskIndex) => (
                    <Task key={task.uid} groupIndex={index} taskIndex={taskIndex} task={task} toggle={toggle} removeTask={removeTask} />
                ))
            }
        </Collapse>

        <ReactTooltip id='collapseTooltip' effect='float' type="dark" getContent={() => {
            return isCollapsed ? "Collapse Group" : "Expand Group";
        }} />
        <ReactTooltip id='generalTooltip' effect='float' type="dark" />
    </>
}
