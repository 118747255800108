import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { HostKey } from "util/Constant";
import { FaChartBar, FaBox, FaToolbox, FaTruckLoading } from "react-icons/fa";

import NecsImg from "assets/img/customer-bg.jpg";
import NepsImg from "assets/img/planning-bg.png";
import NesmImg from "assets/img/elevator-bg.jpg";
import NewiImg from "assets/img/warehouse-bg.jpg";

/// <summary>
/// Author: Christopher Chan
/// </summary>
export default () => {
  return (
    <>
      <h1 className="page-header">DASHBOARD</h1>
      <Row xs="auto">
        <Col style={{ maxWidth: "200px" }}>
          <Card
            className="factsheet-card"
            tag="a"
            onClick={() => (window.location = HostKey.getNecsHost())}
            style={{ cursor: "pointer" }}
          >
            <CardBody>
              <div className="dashboard-item">
                <div className="item-title pink">
                  NE<span>Cs</span>
                </div>
                <div className="item-date">Last login: 21/02/22 14:21</div>
                <img src={NecsImg} />
                <div className="item-tag-container">
                  <div className="item-tag pink"></div>
                  <div className="item-tag-title">
                    <FaChartBar
                      color="white"
                      size={18}
                      style={{ marginRight: 10 }}
                    />
                    Sales
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col style={{ maxWidth: "200px" }}>
          <Card className="factsheet-card" tag="a" onClick={() => {}}>
            <CardBody>
              <div className="dashboard-item">
                <div className="item-title blue">
                  NE<span>Ps</span>
                </div>
                <div className="item-date">Last login: 21/02/22 14:21</div>
                <img src={NepsImg} />
                <div className="item-tag-container">
                  <div className="item-tag blue"></div>
                  <div className="item-tag-title">
                    <FaBox
                      color="white"
                      size={18}
                      style={{ marginRight: 10 }}
                    />
                    Project
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col style={{ maxWidth: "200px" }}>
          <Card className="factsheet-card" tag="a" onClick={() => {}}>
            <CardBody>
              <div className="dashboard-item">
                <div className="item-title yellow">
                  NE<span>Sm</span>
                </div>
                <div className="item-date">Last login: 21/02/22 14:21</div>
                <img src={NesmImg} />
                <div className="item-tag-container">
                  <div className="item-tag yellow"></div>
                  <div className="item-tag-title">
                    <FaToolbox
                      color="white"
                      size={18}
                      style={{ marginRight: 10 }}
                    />
                    Service
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col style={{ maxWidth: "200px" }}>
          <Card className="factsheet-card" tag="a" onClick={() => {}}>
            <CardBody>
              <div className="dashboard-item">
                <div className="item-title purple">
                  NE<span>Wi</span>
                </div>
                <div className="item-date">Last login: 21/02/22 14:21</div>
                <img src={NewiImg} />
                <div className="item-tag-container">
                  <div className="item-tag purple"></div>
                  <div className="item-tag-title">
                    <FaTruckLoading
                      color="white"
                      size={18}
                      style={{ marginRight: 10 }}
                    />
                    Inventory
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};
