import React, { useState, useEffect } from "react";
import { Card, CardBody, Collapse } from 'reactstrap';
import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';

/// <summary>
/// Author: Christopher Chan
/// </summary>
export default ({ groupIndex, taskIndex, task, removeTask, toggle }) => {
    const PRIORITY = {
        0: 'Low',
        1: 'Medium',
        2: 'High',
        3: 'Urgent',
    };

    let priorityClasses = classnames(
        'label-priority',
        {
            'label-green': task?.priority === 0,
            'label-yellow': task?.priority === 1,
            'label-orange': task?.priority === 2,
            'label-red': task?.priority === 3,
        },
    );

    return <>
        <div className="task-row">
            <i className="fas fa-level-up-alt"></i>
            <Card className="flex-fill task-card">
                <CardBody className="task-details-row p-t-10 p-b-10">
                    <div className="task-name">{task?.name}</div>
                    <div className="task-desc">{task?.description}</div>
                    <div className="task-duration">{task?.duration} {task?.type}</div>
                    <div className="task-duration">{task?.users?.length} default assignees</div>
                    <div className={priorityClasses}>{PRIORITY[task?.priority]}</div>
                    <button type="button" className="btn expand-theme btn-round ml-3" onClick={(e) => toggle(e, groupIndex, taskIndex)} data-tip="Edit Task" data-for="generalTooltip">
                        <i className="fas fa-edit"></i>
                    </button>
                </CardBody>
            </Card>
            <button type="button" className="btn expand-red btn-round ml-3" onClick={() => removeTask(groupIndex, taskIndex)} data-tip="Remove Task" data-for="generalTooltip">
                <i className="fas fa-times"></i>
            </button>
            <ReactTooltip id='generalTooltip' effect='float' type="dark" />
        </div>
    </>
}
