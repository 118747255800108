import { ApiUrl } from "util/Constant";
import { isEmpty } from "lodash";
import { stringIsNullOrEmpty } from "util/Utility";
import DataAccessObject from "./DataAccessObject";

/**
 * Author : Nicholas
 * DataAccessObject's fetch will return the promise.
 */
class PolicyDao extends DataAccessObject {
  async getFeatureList(token) {
    return this.get(ApiUrl._API_GET_FEATURES, token);
  }

  async getRoleList(token) {
    return this.get(ApiUrl._API_GET_ROLE_LIST, token);
  }

  async getRoleListPaged(token, queryParams) {
    let url = ApiUrl._API_GET_ROLE_LIST_PAGED;
    if (!isEmpty(queryParams)) {
      let queryStr = new URLSearchParams(queryParams).toString();
      if (!stringIsNullOrEmpty(queryStr)) {
        url += `?${queryStr}`;
      }
    }
    return this.get(url, token);
  }

  async getRolePermission(roleId, token) {
    return this.get(`${ApiUrl._API_GET_ROLE_PERMISSION}/${roleId}`, token);
  }

  async create(data, token) {
    return this.post(ApiUrl._API_CREATE_ROLE, data, token);
  }

  async update(data, token) {
    return this.post(ApiUrl._API_UPDATE_ROLE, data, token);
  }

  async deleteRole(data, token) {
    return this.delete(ApiUrl._API_DELETE_ROLE + "/" + data, token);
  }

  async updateUserPolicy(data, token) {
    return this.post(ApiUrl._API_UPDATE_USER_POLICY, data, token);
  }

  async getModuleList(token, queryParams) {
    let url = ApiUrl._API_GET_MODULE_LIST;
    if (!isEmpty(queryParams)) {
      let queryStr = new URLSearchParams(queryParams).toString();
      if (!stringIsNullOrEmpty(queryStr)) {
        url += `?${queryStr}`;
      }
    }
    return this.get(url, token);
  }

  async createModule(data, token) {
    return this.post(ApiUrl._API_CREATE_MODULE, data, token);
  }

  async updateModule(data, token) {
    return this.post(ApiUrl._API_UPDATE_MODULE, data, token);
  }

  async deleteModule(id, token) {
    return this.post(`${ApiUrl._API_DELETE_MODULE}/${id}`, null, token);
  }
}

export default PolicyDao;
