import { ApiUrl } from 'util/Constant';
import DataAccessObject from './DataAccessObject';

/// <summary>
/// Author: Christopher Chan
/// </summary>
class TaskDao extends DataAccessObject {

    async getTasks(token) {
        return this.get(ApiUrl._API_TASKS, token);
    }

    async createOrUpdateTasks(data, token) {
        return this.post(ApiUrl._API_TASKS, data, token);
    }
}

export default TaskDao;
