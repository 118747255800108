import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaPowerOff } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';

import { PageSettings } from './../../config/page-settings.js';
import useAuthController from 'recoil/useAuthController';

/// <summary>
/// Author: KurisuCodes
/// </summary>
export default () => {
        const _history = useHistory();
        const { logout } = useAuthController({
                logoutCallback: {
                        success: () => {
                                _history.push('/');
                        },
                        fail: () => { return },
                }
        });

        return (
                <PageSettings.Consumer>
                        {({
                                toggleMobileSidebar,
                                pageSidebar
                        }) => (
                                <div id="header" className="header navbar-default">
                                        <div className="navbar-header">
                                                <Link to="/dashboard" className="navbar-brand">
                                                        <span className="navbar-logo"></span>
                                                        <b>NE<span className="navbar-title">Hr</span></b>
                                                        <span className="navbar-subtext">USER PROFILE</span>
                                                </Link>
                                                {pageSidebar && (
                                                        <button type="button" className="navbar-toggle" onClick={toggleMobileSidebar}>
                                                                <span className="icon-bar"></span>
                                                                <span className="icon-bar"></span>
                                                                <span className="icon-bar"></span>
                                                        </button>
                                                )}
                                        </div>
                                        <ul className="navbar-nav navbar-right align-items-center">
                                                <div className="login-btn-div" style={{ width: '100px', marginRight: 20 }}>
                                                        <div className="d-flex justify-content-center w-100">
                                                                <button type="button" className="btn btn-themed w-100" onClick={logout}>
                                                                        <FaPowerOff style={{ marginRight: 5 }} /> Logout
                                                                </button>
                                                        </div>
                                                </div>
                                        </ul>
                                </div>
                        )}
                </PageSettings.Consumer>
        )
}
