import moment from 'moment';

const SystemLog = [
    [
        {
            date: moment().subtract(1, 'days'),
            title: 'NEPs',
            description: 'You logged in.',
            theme: {
                title: 'blue',
                main: 'orange',
            }
        },
        {
            date: moment().subtract(1, 'days').subtract(1, 'hours'),
            title: 'NECs',
            description: 'You logged in.',
            theme: {
                title: 'pink',
                main: 'orange',
            }
        },
        {
            date: moment().subtract(1, 'days').subtract(2, 'hours'),
            title: 'NESm',
            description: 'You logged in.',
            theme: {
                title: 'yellow',
                main: 'orange',
            }
        },
    ],
    [
        {
            date: moment().subtract(2, 'days').subtract(1, 'hours'),
            title: 'NESm',
            description: 'You logged in.',
            theme: {
                title: 'yellow',
                main: 'orange',
            }
        },
        {
            date: moment().subtract(2, 'days').subtract(2, 'hours'),
            title: 'NESm',
            description: 'You logged in.',
            theme: {
                title: 'yellow',
                main: 'orange',
            }
        },
        {
            date: moment().subtract(2, 'days').subtract(3, 'hours'),
            title: 'NESm',
            description: 'You logged in.',
            theme: {
                title: 'yellow',
                main: 'orange',
            }
        },
    ]
];

const TaskEvent = [
    [
        {
            date: moment().subtract(3, 'days'),
            title: 'NEPs',
            description: 'John updated Project [sne6534].',
            theme: {
                title: 'blue',
                main: 'purple',
            }
        },
        {
            date: moment().subtract(3, 'days').subtract(1, 'hours'),
            title: 'NEPs',
            description: 'John updated Project [sne6534].',
            theme: {
                title: 'blue',
                main: 'purple',
            }
        },
        {
            date: moment().subtract(3, 'days').subtract(2, 'hours'),
            title: 'NEPs',
            description: 'John updated Project [sne6534].',
            theme: {
                title: 'blue',
                main: 'purple',
            }
        },
    ],
    [
        {
            date: moment().subtract(4, 'days').subtract(1, 'hours'),
            title: 'NECs',
            description: 'Amanda updated Quotation [Datin Chloe].',
            theme: {
                title: 'red',
                main: 'purple',
            }
        },
        {
            date: moment().subtract(4, 'days').subtract(2, 'hours'),
            title: 'NECs',
            description: 'Amanda updated Quotation [Datin Chloe].',
            theme: {
                title: 'red',
                main: 'purple',
            }
        },
        {
            date: moment().subtract(4, 'days').subtract(3, 'hours'),
            title: 'NECs',
            description: 'Amanda updated Enquiry [Dato Lai].',
            theme: {
                title: 'red',
                main: 'purple',
            }
        },
        {
            date: moment().subtract(4, 'days').subtract(4, 'hours'),
            title: 'NECs',
            description: 'Amanda updated Enquiry [Dato Lai].',
            theme: {
                title: 'red',
                main: 'purple',
            }
        },
        {
            date: moment().subtract(4, 'days').subtract(3, 'hours'),
            title: 'NECs',
            description: 'Amanda updated Enquiry [Dato Lai].',
            theme: {
                title: 'red',
                main: 'purple',
            }
        },
    ]
];

export {
    SystemLog,
    TaskEvent
}