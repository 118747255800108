import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Notiflix from "notiflix";
import { Config, ConfigEnum } from "util/Config";
import { stringIsNullOrEmpty } from "util/Utility.js";
import CommonDao from "data/CommonDao.js";
import DepartmentDao from "data/DepartmentDao";
import UserDao from "data/UserDao.js";
import moment from "moment";
import { useForm } from "react-hook-form";

import { FaUserTie, FaUserAlt, FaKey } from "react-icons/fa";

import CustomInput from "components/form/CustomInput";
import { ApiKey, AppFeatureKeys, Permission, HostKey } from "util/Constant";

const ChangePasswordModal = ({
  toggle,
  user,
  onClosed,
  isOpen,
  onOpened,
  successCallback,
  isNewUser,
}) => {
  const { register, handleSubmit, control, errors, reset, ref } = useForm({});
  let configInstance = Config.getInstance();
  var token = configInstance.getValue(ConfigEnum._TOKEN);

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  const onSubmit = async (data) => {
    const formData = new FormData();

    formData.append("id", user.id);
    formData.append("password", data.password);
    formData.append("confirmPassword", data.password);

    let action = "";
    let dao = new UserDao();

    action = dao.changePassword(formData, token);

    await action.then((responseJson) => {
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        var message = "Change password successfully";
        Notiflix.Report.success("Success", message, "Okay", successCallback);
      } else {
        Notiflix.Report.failure(
          "Error",
          "Registration Failed. " + responseJson[ApiKey._API_MESSAGE_KEY]
        );
      }
    });
  };

  return (
    <Modal size="xs" {...{ isOpen, toggle, onClosed, onOpened }} centered>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader className="modal-themed" toggle={toggle}>
          <h2 style={{ marginBottom: 0 }}>Management / Change User Password</h2>
        </ModalHeader>

        <ModalBody>
          <Row>
            <Col xs={8}>
              <Row className="m-b-10">
                <Col xs={12}>
                  <CustomInput
                    {...register("password", true)}
                    name="password"
                    label="Password *"
                    icon={<FaKey />}
                    type="password"
                    error={errors?.password}
                  />
                </Col>
              </Row>
              <Row className="m-b-10"></Row>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-red mr-auto btn-min-width"
            onClick={toggle}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-themed btn-min-width pull-right mt-2"
          >
            Change Password
          </button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default ChangePasswordModal;
