import React, {
  useMemo,
  useState,
  useRef,
  useCallback,
  useEffect,
} from "react";
import ReactTable from "components/react-table/ReactTable";
import { Row, Col } from "reactstrap";
import { handleSetPageOptions } from "util/Utility.js";
import PolicyDao from "data/PolicyDao";
import { ApiKey, AppFeatureKeys, Permission } from "util/Constant";
import { Config, ConfigEnum } from "util/Config";
import Notiflix from "notiflix";
import { Can } from "config/user-access";
import ModuleModal from "./ModuleModal";

///<summary>
///Author: Ong Sze Hua
///</summary>
const Module = (props) => {
  const [showModuleModal, setShowModuleModal] = useState(false);
  const [role, setRole] = useState();
  const [module, setModule] = useState();
  const [moduleList, setModuleList] = useState([]);
  const _tableRef = useRef();
  const [listQueryParams, setListQueryParams] = useState();

  let policyDao = useMemo(() => new PolicyDao(), []);
  let configInstance = Config.getInstance();
  var token = configInstance.getValue(ConfigEnum._TOKEN);
  var user = configInstance.getValue(ConfigEnum._USER);

  const _GROUP_COLUMN = useMemo(() => [
    {
      Header: "Module Name",
      accessor: "moduleName",
    },
    {
      Header: "Module Description",
      accessor: "moduleDescription",
    },
    {
      Header: " ",
      Cell: ({ row }) => (
        <>
          <Can I={Permission.EDIT} this={AppFeatureKeys._MODULES}>
            <div
              className="btn-table last-btn"
              onClick={() => {
                toggleModuleModal();
                setModule(row.original);
              }}
            >
              <i className="fa fa-edit"></i>
            </div>
            <div
              className="btn-table last-btn"
              onClick={() => {
                deleteModule(row.original)
              }}
            >
              <i className="fa fa-trash"></i>
            </div>
          </Can>
        </>
      ),
      disableSortBy: true,
      disableFilters: true,
      style: { overflow: "visible", textAlign: "right" },
      sticky: "right",
    },
  ]);

  const deleteModule = (data) => {

    Notiflix.Confirm.init({
      plainText: false,
      titleColor: "#FF0000",
      okButtonBackground: "#FF0000",
    });

    Notiflix.Confirm.show(
      'Delete Module ('+data.moduleName+')?',
      `Are you sure you would like to delete</b>`,
      'Delete',
      'No',
      () => deleteModuleHandler(data.id)
    )
  } 

    /// <summary>
  /// Author: Christopher Chan
  /// </summary>
  const deleteModuleHandler = (id) => {
      let dao = new PolicyDao();

       dao.deleteModule(id, token).then((response) => {
        if (response[ApiKey._API_SUCCESS_KEY]) {
          Notiflix.Report.success(
            "Success",
            `Successfully deleted module.`,
            "Okay",
            getModuleList(listQueryParams)
          );
        } else {
          Notiflix.Report.failure(
            "Error",
            `Failed to delete module. Please try again later`
          );
        }
      });
  };

  /// <summary>
  /// Author: Nicholas
  /// </summary>
  const getModuleList = useCallback(
    async (params) => {
      await policyDao
        .getModuleList(token, params)
        .then((responseJson) => {
          if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
            throw new Error("Failed to retrieve features");
          }

          setModuleList(responseJson[ApiKey._API_DATA_KEY]);
          handleSetPageOptions(
            _tableRef,
            responseJson[ApiKey._API_TOTAL_KEY] ?? 0
          );
        })
        .catch((error) => {
          Notiflix.Report.failure("Error", "Failed to load features");
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [policyDao, token]
  );

  useEffect(() => {
    listQueryParams && getModuleList(listQueryParams);
  }, [getModuleList, listQueryParams]);

  /// <summary>
  /// Author: Nicholas
  /// </summary>
  const toggleModuleModal = useCallback(() => {
    setModule(null);
    setShowModuleModal((prev) => !prev);
  }, []);

  /// Author: Nicholas
  /// </summary>
  useEffect(() => {
    getModuleList();
  }, []);

  return (
    <>
      <div className="float-xl-right">
        <Can I={Permission.CREATE} this={AppFeatureKeys._MODULES}>
          <button
            type="type"
            className="btn btn-sm btn-themed mb-2"
            onClick={() => {
              toggleModuleModal();
            }}
          >
            <i className="fa fa-plus mr-1"></i>Add New Module
          </button>
        </Can>
      </div>
      <h1 className="page-header">MANAGEMENT / Modules</h1>
      <Row>
        <Col xs="12">
          <ReactTable
            columns={_GROUP_COLUMN}
            data={moduleList}
            getRowProps={(row) => {
              return {};
            }}
            filterable
            canPreviousPage={true}
            canNextPage={true}
            ref={_tableRef}
            setQueryParams={setListQueryParams}
            disabledApiPagination={false}
          />
        </Col>
      </Row>
      <ModuleModal
        isOpen={showModuleModal}
        toggle={toggleModuleModal}
        module={module}
        successCallback={() => {
          getModuleList();
          toggleModuleModal();
        }}
      />
    </>
  );
};

export default Module;
