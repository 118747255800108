import React, { Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { InputTypes } from 'util/Constant';
import InputHoc from 'components/form/InputHoc';
import classname from 'classnames';

/// <summary>
/// Author: Christopher Chan
/// - Dynamic form builder based on an array of inputs.
/// - REQUIRED to pass in an onSubmit function, formRef, and fields.
/// - Fields should be an array in this format:-
/*
    [
        - each curly brace represents a form <Row></Row>.
        { 
            - any kind of row options available (reactstrap) can be passed here.
            rowOptions: { options },

            - Columns should be an array in this format:-
            columns: [
                - each curly brace represents a <Col></Col>.
                - all required fields for typical input should be passed here as well as any extra options.
                - input options is required to determine what kind of input to render, but defaults to <Input /> if not defined.
                - if you need to show/hide inputs based on certain conditions, you may pass in a toDisplay flag
                { label, name, input, defaultValue, toDisplay, ...options }
            ],

            - this is optional if you wish to render a <hr/> after each row.
            seperator: true 
        }
    ]
*/
/// </summary>
const FormBuilder = props => {
    const {
        fields,
        watchFields,
        onSubmit,
        formRef,
        readOnly,
        defaultValues,
        formClasses,
        children
    } = props;
    const { register, control, handleSubmit, errors, watch, setValue } = useForm();
    const watchedFields = watch(watchFields);

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    const registerWithRules = (rules) => {
        return rules ? register(rules) : register;
    }

    /// <summary>
    /// Author: Christopher Chan
    /// - Determines the react hook form control for input
    /// </summary>
    const getFormType = (type, rules) => {
        switch (type) {
            case InputTypes.SELECT:
            case InputTypes.PHONE:
            case InputTypes.ATTACHMENT:
            case InputTypes.DATEPICKER:
            case InputTypes.FILEUPLOAD:
                return { control };
            default:
                return { ref: registerWithRules(rules) }
        }
    }

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    const evaluateWatchValue = (type, field, value) => {
        if (type === InputTypes.CHECKBOX && typeof field === 'array' && field !== undefined) {
            return field.includes(value);
        }

        return field === value;
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} ref={formRef} className={classname({ [formClasses]: formClasses })} autoComplete="off">
            {
                fields.map((row, rowKey) => {
                    return (
                        <Fragment key={rowKey}>
                            <Row className={classname(row.rowClasses ? row.rowClasses : '')} {...row.rowOptions} style={row.style}>
                                {row.rowTitle}
                                {
                                    row.columns.map((column, columnKey) => {
                                        const { columnOptions, input, name, toDisplay, watchInput, defaultValue, ...rest } = column;
                                        let inputType = input ?? InputTypes.INPUT;
                                        let hasDynamicInput = false;

                                        if (readOnly) {
                                            inputType = InputTypes.INPUT;
                                        }

                                        if (typeof toDisplay === 'boolean' && toDisplay === false) {
                                            return null;
                                        }

                                        if (watchInput) {
                                            hasDynamicInput = evaluateWatchValue(inputType, watchedFields[name], watchInput.equalsTo);
                                        }

                                        return (
                                            <Fragment key={columnKey}>
                                                <Col {...column.columnOptions}>
                                                    <InputHoc
                                                        inputType={inputType}
                                                        name={name}
                                                        error={errors?.[name]?.message}
                                                        {...getFormType(inputType, column?.rules)}
                                                        readOnly={readOnly}
                                                        defaultValue={defaultValues?.[name] ?? column.defaultValue ?? null}
                                                        {...rest}

                                                    />
                                                    {
                                                        hasDynamicInput && typeof watchInput?.inputType === 'string' &&
                                                        <InputHoc
                                                            inputType={watchInput.inputType}
                                                            name={watchInput.name}
                                                            error={errors?.[watchInput.name]?.message}
                                                            {...getFormType(watchInput.inputType, column?.rules)}
                                                            {...watchInput}
                                                        />
                                                    }
                                                </Col>
                                                {
                                                    hasDynamicInput && typeof watchInput?.inputType !== 'string' &&
                                                    React.createElement(watchInput.customComponent, { register, control, handleSubmit, errors, watch, setValue })
                                                }
                                            </Fragment>
                                        );
                                    })
                                }
                            </Row>
                            {row.seperator && <hr />}
                        </Fragment>
                    );
                })
            }
            {children}
        </form>
    );
}

export const submitForm = (ref) => {
    ref.current.dispatchEvent(new Event('submit', { cancelable: true }));
}

FormBuilder.propTypes = {
    fields: PropTypes.array.isRequired,
    onSubmit: PropTypes.func.isRequired,
    formRef: PropTypes.any.isRequired,
}

export default FormBuilder;