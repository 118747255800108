import { ApiUrl } from "util/Constant";
import DataAccessObject from "./DataAccessObject";

/**
 * Author : Sim
 * DataAccessObject's fetch will return the promise.
 */
class PositionDao extends DataAccessObject {
  async getPositionList(token) {
    return this.get(ApiUrl._API_GET_POSITION, token);
  }

  async getPositionListJson(token) {
    return this.get(ApiUrl._API_GET_POSITION_JSON, token);
  }

  async getPositionListJsonAgent(token) {
    return this.get(ApiUrl._API_GET_POSITION_JSON_AGENT, token);
  }

  async register(data, token) {
    return this.post(ApiUrl._API_POSITION_CREATE, data, token);
  }

  async registerJson(data, token) {
    return this.post(ApiUrl._API_POSITION_CREATE_JSON, data, token);
  }

  async registerJsonAgent(data, token) {
    return this.post(ApiUrl._API_POSITION_CREATE_JSON_AGENT, data, token);
  }

  async updatePosition(data, token) {
    return this.post(ApiUrl._API_POSITION_UPDATE, data, token);
  }

  async delete(id, token) {
    return this.post(`${ApiUrl._API_POSITION_DELETE}/${id}`, null, token);
  }
}

export default PositionDao;
