import React, { useMemo, useState, useRef, useEffect } from "react";
import ReactTable from 'components/react-table/ReactTable';
import { Card, CardHeader, CardBody, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Notiflix from "notiflix";
import { useHistory } from 'react-router-dom';
import { InputTypes, SelectField, WebUrl, ApiKey } from "util/Constant";
import { Config, ConfigEnum } from 'util/Config';
import ElevatorModelDao from "data/ElevatorModelDao";
import { Block } from 'notiflix';
import { tryCatch } from 'util/Utility';

/// <summary>
/// Author: Christopher Chan
/// </summary>
export default () => {
    const history = useHistory();
    const [agent, setAgent] = useState(null);
    const [elevatorModels, setElevatorModels] = useState([]);

    let configInstance = Config.getInstance();
    var token = configInstance.getValue(ConfigEnum._TOKEN);

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    const getElevatorModels = async () => {
        tryCatch(async () => {
            Block.circle('#elevatorsTable');

            let dao = new ElevatorModelDao();

            await dao.getElevatorModels(token).then((response) => {
                if (response[ApiKey._API_SUCCESS_KEY]) {
                    var data = response[ApiKey._API_DATA_KEY];
                    console.log(data);
                    setElevatorModels(data);
                } else {
                    Notiflix.Report.Failure('Error', 'Failed to retrieve elevator models. Please try again later');
                }
            });

            Block.remove('#elevatorsTable');
        });
    }

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    const deleteAgentHandler = (id) => {
        // tryCatch(async () => {
        //     let dao = new ServiceAgentDao();

        //     await dao.deleteServiceAgent(id, token).then((response) => {
        //         if (response[ApiKey._API_SUCCESS_KEY]) {
        //             Notiflix.Report.Success('Success', `Successfully deleted service agent.`, 'Okay', getServiceAgentsList);
        //         } else {
        //             Notiflix.Report.Failure('Error', `Failed to delete service agent ${agent.agentPic}. Please try again later`);
        //         }
        //     })
        // });
    }

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    const deleteAgentPrompt = (agent) => {
        Notiflix.Confirm.Init({ plainText: false, titleColor: '#3292F1', okButtonBackground: '#3292F1' });
        Notiflix.Confirm.Show(
            'Delete service agent?',
            `Are you sure you would like to delete:- <b>${agent.agentPic}<br/>(${agent.agentCompany})</b>`,
            'Delete',
            'No',
            () => deleteAgentHandler(agent.id)
        )
    }

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    useEffect(() => {
        getElevatorModels();
    }, []);

    const _COLUMN = useMemo(() => [
        {
            Header: "Serial Number",
            Cell: ({ row }) => (
                <b>{row.original.serialNumber}</b>
            )
        },
        {
            Header: "Model",
            accessor: "name"
        },
        {
            Header: "Description",
            accessor: "description"
        },
        {
            Header: "Price (RM)",
            accessor: "price"
        },
        {
            Header: "Action",
            Cell: ({ row }) => (<>
                <div
                    className="btn-round expand-theme btn-sm"
                    onClick={() => history.push(WebUrl._ELEVATOR_MODELS_CREATE_OR_UPDATE.replace(':id', row.original.serialNumber), { details: row.original })}
                >
                    <i className="fa fa-edit"></i>
                </div>
                {/* <div
                    className="btn-round expand-theme btn-sm m-l-5"
                    onClick={() => deleteAgentPrompt(row.original)}
                >
                    <i className="fa fa-trash"></i>
                </div> */}
            </>
            ),
            disableSortBy: true,
            disableFilters: true,
            style: { overflow: "visible" }
        }
    ])

    return (<>
        <div className="float-xl-right">
            <button type="type" className="btn btn-sm btn-themed" onClick={() => history.push(WebUrl._ELEVATOR_MODELS_CREATE_OR_UPDATE.replace(':id', 'create'))} >
                <i className="fa fa-plus mr-1"></i>Add Elevator Model
            </button>
        </div>
        <h1 className="page-header">Elevator Model Management</h1>

        <div id="elevatorsTable">
            <ReactTable columns={_COLUMN} data={elevatorModels} />
        </div>
    </>)
}
